import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import { Row, Drawer ,Alert} from "antd";

import AppLayout from "../components/AppLayout";
import CustomersTable from "../components/CustomersTable";
import { Customer } from "../models/Customer.js";
import CustomerFormLogic from "../components/CustomerFormLogic.jsx";
import { CustomerDetailCard,CheckStatusCard } from "../components/CustomerCardViews.jsx";
import CustomBreadcrumb from "../components/CustomBreadcrumb.jsx";

import { useNavigate } from "react-router-dom";

import ColumnContainer from "../components/ColumnContainer";
import Notes from "../components/Notes";
import { useCustomerContext } from '../contexts/CustomerContext'; 


import {
  HelpTitleCustomers,
  HelpContentCustomers,
} from "../components/HelpText";

import { showNotification } from "../components/Utils.jsx";


const useColumnDisplay = (selectedItem, isMonoColumn, mode) => {
  const isItemSelected = !!selectedItem && !!selectedItem.id;
  const showFirstColumn = !isMonoColumn || (!isItemSelected && mode !== "add");
  const showSecondColumn =
    isItemSelected ||
    mode === "add" ||
    mode === "edit" ||
    (isMonoColumn && mode === "add");
  return { showFirstColumn, showSecondColumn };
};

const CustomersPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { customerList, fetchCustomers,deleteCustomer,loading } = useCustomerContext();


  const ObjectName = "Customer";
  const ObjectNameL = "customer";

  const [selectedItem, setSelectedItem] = useState(null || new Customer({}));

  const [formDrawerVisible, setFormDrawerVisible] = useState(false);

  const [mode, setMode] = useState("view"); // Modes: view, add, edit
  const isCard = useMediaQuery({ maxWidth: 1000 });
  const isMonoColumn = useMediaQuery({ maxWidth: 800 });
  const isMonoColDetails=useMediaQuery({ maxWidth: 1600 });

  const [showHelp, setShowHelp] = useState(false);
  const [helpTitle, setHelpTitle] = useState("");
  const [helpContent, setHelpContent] = useState("");



  

  const toggleEditPanelCollapsed = () => {
    setFormDrawerVisible(!formDrawerVisible); // Toggle menu drawer for mobile
  };

  const handleShowHelpToggleAddEditUser = () => {
    setHelpTitle(HelpTitleCustomers);
    setHelpContent(HelpContentCustomers);
    handleShowHelpToggle();
  };

  const handleShowHelpToggle = () => {
    setShowHelp((prevShowHelp) => !prevShowHelp);
  };

  const handleAddItem = () => {
    setMode("add");
    setFormDrawerVisible(true);
  };
  const handleItemDelete = async (item) => {
    try {
      
      const response=await deleteCustomer(item.id)
      if (response.success) {
        showNotification(
          "success",
          "Done!",
          `${ObjectName} deleted successfully`
        );
      }
      
      onSelectedItemChange(null);
    } catch (error) {
      console.error("Failed to delete:", error);
    }
  };

  const handleOnSubmit = (customer) => {
    switch (mode) {
      case "add":
        handleItemSelect(customer?.id)
        break;

      case "edit":
        if (customer) {
          onSelectedItemChange(customer);
          
        }
        break;
      default:
        break;
    }
    if (customer.amountUnClosedCases > 0) {
      showNotification(
        "warning",
        "Warning!",
       `New results found in sanctions list. A case was opened for ${customer.name}`,
        5
      );
    }

    setFormDrawerVisible(false);
    setMode("view");
  };

  const handleCancelForm = () => {
    setMode("view");

    setFormDrawerVisible(false);
  };
  const handleEdit = () => {
    setMode("edit");
    setFormDrawerVisible(true);
  };

  const { showFirstColumn, showSecondColumn } = useColumnDisplay(
    selectedItem,
    isMonoColumn,
    mode
  );

  const handleItemSelect = async (id) => {
    let existingCustomer;
    setMode("view");
   
      existingCustomer = customerList.find((customer) => customer?.id === id);
    
    if (existingCustomer === undefined) {
      onSelectedItemChange(null);
      return;
    }
    onSelectedItemChange(existingCustomer);
  };

  

  const onCustomerViewDetails = async () => {
    const existingCustomer = customerList.find(
      (customer) => customer?.id === selectedItem.id
    );
    setSelectedItem(existingCustomer);

    navigate(`/customers/${existingCustomer.id}/cases`);
  };

  const onSelectedItemChange = (item) => {
    setSelectedItem(item);
    if (item === null) {
      const newUrl = `${host}/customers`;
      window.history.replaceState(null, "", newUrl);
      return;
    }
    const newUrl = `${host}/customers/${item.id}`;
    window.history.replaceState(null, "", newUrl);
  };

  useEffect(() => {
    if (customerList && customerList.length > 0) {
      const pathParts = location.pathname.split("/");
      const customerId = pathParts[pathParts.length - 1];
      handleItemSelect(customerId);
    }
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      if (customerList.length === 0) {
        const custList= await fetchCustomers({ initial: true });
        const cus=custList[0]
        if (cus){

          handleItemSelect(cus.id)}
        
      }
    };
    fetchData();
  }, []);

 

  

  const host = window.location.origin;
  let breadcrumbItems = [
    { key: "1", title: "Customers ", href: `${host}/customers` },
    ...(selectedItem
      ? [
          {
            key: "2",
            title: `${selectedItem.name}`,
            href: `${host}/customers/${selectedItem.id}`,
          },
        ]
      : []),
  ];

  return (
    <AppLayout
      handleShowHelpToggle={handleShowHelpToggle}
      showHelp={showHelp}
      helpTitle={helpTitle}
      helpContent={helpContent}
    >
      {false && (<Alert
                message="Beta functionality. Do not use in production."  // Message displayed in the alert
                type="info"  // Type of alert styling
                showIcon  // Show an icon next to the alert message
                style={{ marginBottom: "8px"}}
                
              />)}
      <Row gutter={0} style={{ display: "flex", flex: "1 1 auto" }}>
        {showFirstColumn && (
          <ColumnContainer
            colsize={16}
            title={<CustomBreadcrumb items={breadcrumbItems} />}
            helpIcon={true}
            handleShowHelpToggleAddEditUser={handleShowHelpToggleAddEditUser}
            buttonLabel={`${ObjectNameL}`}
            onButtonClick={handleAddItem}
            showBackButton={false}
          >
            <CustomersTable
              selectedItemId={selectedItem?.id}
              data={customerList}
             
             
              loadingCustomers={loading}
              onSelectItem={handleItemSelect}
              isCard={isCard}
            />
          </ColumnContainer>
        )}
        {showSecondColumn && (
          <ColumnContainer
            colsize={isMonoColumn ? 24 : 8}
            itemNameDelete={`${ObjectNameL}'s`}
            title={
              selectedItem
                ? mode === "view"
                  ? `${selectedItem.name}`
                  : ""
                : ""
            }
            showBackButton={
              mode === "add" ? false : mode === "edit" ? false : isMonoColumn
            }
            onBackClick={() => setSelectedItem(null)}
            showDelete={mode !== "add" && mode !== "edit"} // Set showDelete based on mode
            onDelete={() => handleItemDelete(selectedItem)}
          >
            <CustomerDetailsAndAudit
              customer={selectedItem}
              onEdit={handleEdit}
              onCustomerViewDetails={onCustomerViewDetails}
             
              loadingChecks={loading}
              isMonoColDetails={isMonoColDetails}
            ></CustomerDetailsAndAudit>
          </ColumnContainer>
        )}
      </Row>

      <Drawer
        className="drawer-add-edit"
        placement="right"
        closable={true}
        maskClosable={false}
        mask={true}
        title={
          <>
            {mode === "add"
              ? `Create ${ObjectNameL}`
              : mode === "edit"
              ? `Edit ${ObjectNameL}`
              : ""}
          </>
        }
        onClose={toggleEditPanelCollapsed}
        open={formDrawerVisible}
        width={500}
      >
        <CustomerFormLogic
          customer={selectedItem}
          mode={mode}
          onSubmit={handleOnSubmit} //user clicked submit edit changes
          onSubmitLabel="Save"
          onCancelLabel="Cancel"
          onCancelForm={handleCancelForm}
        />
      </Drawer>
    </AppLayout>
  );
};

export default CustomersPage;

export const CustomerDetailsAndAudit = ({
  customer,
  onEdit,
  onCustomerViewDetails,
  isMonoColDetails,
  loading,
  onAdd,
}) => {
  console.log("inCustomerDetailsAndAudit customer",customer)
  return (

    <div className= "expand-height expand-width">
    {!isMonoColDetails ? (
      // Two columns layout for large screens
     
      <div className="dir-row expand-width">
          <div className="column-container-overflow">
            <div style={{ height: "0px", width: "100%" }}>
          <CustomerDetailCard
            customer={customer}
            onEdit={onEdit}
           
          />
           <CheckStatusCard
            customerId={customer?.id}
            onEdit={onEdit}
            onCustomerViewDetails={onCustomerViewDetails}
          />
       </div>
          </div>
          <div style={{ width: "8px" }} />
          <div className="notes-container-width expand-width">
            <Notes  customerId={customer?.id} overflow={true} loading={loading}/>
          </div>
        </div>
    
    ) : (
      <div className="dir-col expand-width">
        <div className="expand-width  column-container-overflow ">
        <div style={{ height:'0px', width:"100%" }}>
          <CustomerDetailCard
            customer={customer}
            onEdit={onEdit}
           
          />
          <CheckStatusCard
            customerId={customer?.id}
            onEdit={onEdit}
            onCustomerViewDetails={onCustomerViewDetails}
          />
        <Notes  customerId={customer?.id} overflow={false} loading={loading} />

        </div>
        </div>
      </div>
    )}
    </div>

  
  );
};

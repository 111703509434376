import React, { useState } from "react";
import { Table, Pagination } from 'antd';
import { UserCardView } from './CardViews';

const CustomersTable = ({ data, onSelectItem, isCard, pagination, Fetch }) => {
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const handleSelectItem = (item) => {
        setSelectedItemId(item.id);
        onSelectItem(item);
    };

    const columns = [
        { key: 'id', title: 'User id', dataIndex: 'id', responsive: ['lg'], width: 120, ellipsis: true },
        { key: 'Name', title: 'Name', dataIndex: 'name', responsive: ['md'], ellipsis: true },
        { key: 'Surname', title: 'Surname', dataIndex: 'surname', responsive: ['md'], ellipsis: true },
        { key: 'Email', title: 'Email', dataIndex: 'email', responsive: ['md'], ellipsis: true },
        {
            key: 'updatedAt',
            title: 'Updated',
            dataIndex: 'updatedAt',
            responsive: ['md'],
            ellipsis: true,
            render: (date) => {
                if (!date) return ''; // Handle cases where the date might be null or undefined
                const validDate = new Date(date);
                if (isNaN(validDate)) return ''; // Check if it's a valid date
                return validDate.toLocaleDateString(); // Format the date as desired
            }
          
               }
    ];

    const rowSelectionStyle = (record) => {
        return {
            onClick: () => handleSelectItem(record),
            style: {
                cursor: 'pointer',
            },
            className: record.id === selectedItemId ? 'selected-row' : '',
        };
    };

    const onPageChange = async (page, pageSize) => {
        console.log("page", page);
        setCurrentPage(page);
        setLoading(true); // Set loading to true before starting the fetch
    
        try {
            if (pagination.cursor !== null) {
                await Fetch(); // Wait for the fetch to complete
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setLoading(false); // Set loading to false after the fetch completes
            console.log("loading", loading);
        }
    };

    const validTotal = pagination.cursor !== null ? data.length + 1 : data.length;

    // Calculate the start and end index for slicing the data
    const startIdx = (currentPage - 1) * pagination.limit;
  
    const endIdx = startIdx + pagination.limit;
    
    const paginatedData = data.slice(startIdx, endIdx);

    return isCard ? (
        <div className="table-pagination-container" >
            <UserCardView
                data={paginatedData} // Pass the sliced data to CustomerCardView
                selectedItemId={selectedItemId}
                handleSelectItem={handleSelectItem}
                loading={loading}
            />
            <PaginationComponent
                currentPage={currentPage}
                pagination={pagination}
                validTotal={validTotal}
                onPageChange={onPageChange}
            />
        </div>
    ) : (
        <div className="table-pagination-container" >
            <Table
                size="big"
                columns={columns}
                
                dataSource={paginatedData} // Pass the sliced data to Table
                onRow={rowSelectionStyle}
                scroll={{ y: 'calc(100vh - 280px)' }}
                loading={loading}
                pagination={{
                    current: currentPage,
                    pageSize: pagination.limit,
                    total: validTotal,
                    position: ["bottomCenter"],
                    onChange: (page, pageSize) => {
                        onPageChange(page, pageSize);
                    }
                }}
            />
        </div>
    );
};

export default CustomersTable;

export const PaginationComponent = ({ currentPage, pagination, validTotal, onPageChange }) => {
    if (validTotal===0){return null}
    return (
        <Pagination
            current={currentPage}
            pageSize={pagination.limit}
            total={validTotal}
            onChange={(page, pageSize) => onPageChange(page, pageSize)}
            showSizeChanger={false}
            disabled={false}
        />
    );
};

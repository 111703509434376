

const API_internal_URL=process.env.REACT_APP_BASE_URL;


let currentIdToken = null;

export const setIdToken = (idToken) => {
  currentIdToken = idToken;
};

const getCommonHeaders = () => ({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${currentIdToken}`,
});

const isTokenPresent = () => {
  if (!currentIdToken) {
    console.error('API call attempted without an idToken. Please authenticate.');
    return false;
  }
  return true;
};



const sendRequest = async ({ path, method, data = {} }) => {
  if (!isTokenPresent()) return null;

  // Prepare the fetch options excluding the body initially
  let fetchOptions = {
    method,
    headers: getCommonHeaders(),
  };

  // Conditionally add the body to the fetch options for methods that support it
  if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
    fetchOptions.body = JSON.stringify(data);
  }
  try {
    //console.log("in api.js", currentIdToken);
    console.log("sendRequest ", `${API_internal_URL}${path}`);
    const response = await fetch(`${API_internal_URL}${path}`, fetchOptions);
    // Attempt to parse the response body regardless of the response status
    const responseBody = await response.json().catch(() => ({}));
    console.log("in API.js responseBody", responseBody);
    if (response.ok) {
      return { success: true, body: responseBody };
    } else {
      // Handle non-2xx responses
      return { success: false, body: responseBody, errorCode: response.status };
    }
  } catch (error) {
    console.error(`Error in ${method} request to ${path}:`, error);
    // Handle network errors or other unexpected errors
    return { success: false, body: {}, errorCode: 'NetworkError' };
  }
};

export const api = {
  createUser: (data) => sendRequest({ path: '/users', method: 'POST', data }), //const response = await api.createUser({ client_name: values.name});


  createTenant: (data) => sendRequest({ path: '/tenants', method: 'POST',data }),
  createTenantSubscription: (data) => sendRequest({ path: '/tenants/subscription', method: 'POST',data }),
  manageTenantSubscription: () => sendRequest({ path: '/tenants/subscription', method: 'PUT' }),//return url in stripe/aws to manage subscription

  createApikey: (data) => sendRequest({ path: '/api_keys', method: 'POST', data }), //const response = await api.createClient({ name: values.name});
  deleteApikey: (api_key_id) => sendRequest({ path: `/api_keys/${api_key_id}`, method: 'DELETE' }),//const response = await api.deleteClient({ client_id: values.clientId });


  getApikeys: () => sendRequest({ path: '/api_keys', method: 'GET' }),


  getTenant: () => sendRequest({ path: '/tenants', method: 'GET' }),

  updateTenant: (data) => sendRequest({ path: '/tenants', method: 'PATCH', data }),



  getUsers: (query) => {
    // Initialize an array to hold the query parameters
    let queryParams = [];
   
    if (query["limit"] !== null && query["limit"] !== "") {
      queryParams.push(`limit=${encodeURIComponent(query["limit"])}`);
    }
    if (query["cursor"] !== null && query["cursor"] !== "") {
      queryParams.push(`cursor=${encodeURIComponent(query["cursor"])}`);
    }
    let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    return sendRequest({ path: `/users${queryString}`, method: 'GET' });
  }
  ,



  deleteUser: (userId) => sendRequest({ path: `/users/${userId}`, method: 'DELETE' }),
  updateUser: (userId, data) => {
    //console.log('Updating user with ID:', userId);
    //console.log('New user data:', data);
    return sendRequest({ path: `/users/${userId}`, method: 'PATCH', data });
  },

  getCustomers: (query) => {
    // Initialize an array to hold the query parameters
    let queryParams = [];
    if (query["onlyUnClosedCases"] !== null && query["onlyUnClosedCases"] !== "") {
            queryParams.push(`case_status_unclosed=${encodeURIComponent(query["onlyUnClosedCases"])}`);
          }
   if (query["onlyClosedCases"] !== null && query["onlyClosedCases"] !== "") {
            queryParams.push(`case_status_closed=${encodeURIComponent(query["onlyClosedCases"])}`);
          }
    
    if (query["onlyNoCases"] !== null && query["onlyNoCases"] !== "") {
            queryParams.push(`case_status_no_case=${encodeURIComponent(query["onlyNoCases"])}`);
          }

    if (query["riskMin"] !== null && query["riskMin"] !== "") {
            queryParams.push(`risk_min=${encodeURIComponent(query["riskMin"])}`);
          }
    if (query["riskMax"] !== null && query["riskMax"] !== "") {
            queryParams.push(`risk_max=${encodeURIComponent(query["riskMax"])}`);
          }

   
    if (query["resultsUnallowed"] !== null && query["resultsUnallowed"] !== "") {
      queryParams.push(`has_results_unallowed=${encodeURIComponent(query["resultsUnallowed"])}`);
    }
    if (query["resultsAllowed"] !== null && query["resultsAllowed"] !== "") {
      queryParams.push(`has_results_allowed=${encodeURIComponent(query["resultsAllowed"])}`);
    }

    if (query["limit"] !== null && query["limit"] !== "") {
      queryParams.push(`limit=${encodeURIComponent(query["limit"])}`);
    }
    if (query["cursor"] !== null && query["cursor"] !== "") {
      queryParams.push(`cursor=${encodeURIComponent(query["cursor"])}`);
    }
    let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    return sendRequest({ path: `/customers${queryString}`, method: 'GET' });
  },

  createCustomer: (data) => sendRequest({ path: '/customers', method: 'POST', data }), //const response = await api.createUser({ client_name: values.name});
  getCustomer: (customerId) => sendRequest({ path: `/customers/${customerId}`, method: 'get' }),

  deleteCustomer: (customerId) => sendRequest({ path: `/customers/${customerId}`, method: 'DELETE' }),
  updateCustomer: (customerId,data) => sendRequest({ path: `/customers/${customerId}`, method: 'PATCH',data }),
  updateCustomerAllowList: (customerId,caseId,data) => sendRequest({ path: `/customers/${customerId}/cases/${caseId}/allowlist`, method: 'POST',data }),
  deleteCustomerAllowList: (customerId,caseId,data) => sendRequest({ path: `/customers/${customerId}/cases/${caseId}/allowlist`, method: 'DELETE',data }),

  queryCustomerCases: (customerId) => sendRequest({ path: `/customers/${customerId}/cases`, method: 'GET' }),

  updateCustomerCase: (customerId,caseId,data) => sendRequest({ path: `/customers/${customerId}/cases/${caseId}`, method: 'PATCH',data }),
  createCustomerNote: (customerId, data) => sendRequest({ path: `/customers/${customerId}/notes`, method: 'POST', data }),
  
  queryCases: (query) => {
    // Initialize an array to hold the query parameters
    let queryParams = [];
    if (query["openCases"] !== null && query["openCases"] !== "") {
            queryParams.push(`case_status_open=${encodeURIComponent(query["openCases"])}`);
          }
   if (query["closedCases"] !== null && query["closedCases"] !== "") {
            queryParams.push(`case_status_closed=${encodeURIComponent(query["closedCases"])}`);
          }

    if (query["assignee"] !== null && query["assignee"] !== "") {
            queryParams.push(`assignee=${encodeURIComponent(query["assignee"])}`);
          }
    
   
    if (query["limit"] !== null && query["limit"] !== "") {
      queryParams.push(`limit=${encodeURIComponent(query["limit"])}`);
    }
    if (query["cursor"] !== null && query["cursor"] !== "") {
      queryParams.push(`cursor=${encodeURIComponent(query["cursor"])}`);
    }
    let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    return sendRequest({ path: `/cases${queryString}`, method: 'GET' });
  },



  //const userData = {"user_id": "125","email": "alex_admin5@gmail.com", "role": "admin", "name": "from postman1", "surname": "asdf" };
  search: ({ searchTerm,searchIdentity,searchLogic,excludeSanctionSourceId,fuzziness, groupType, sourceType,gender,birthYear,nationality }) => {
    
    let data = {
      "search_term": searchTerm,
      "search_identity": searchTerm,
      "fuzziness":fuzziness,
      "search_logic": "OR", 
      
      "filters": {
        "group_type": groupType,
        "source_type": sourceType,
        "exclude_sanction_source_id":excludeSanctionSourceId,
        "gender": gender,
        "birth_year": birthYear,
        "nationality": nationality
      }
    };
    return sendRequest({ path: `/search`, method: 'POST',data });
  },

  ai_search_result_assessment: ({ searchTerm,groupType, sourceType,gender,birthYear,nationality,search_results }) => {
    
    let data = {
      "search_results": search_results,
     
      
      "customer_properties": {
        "search_term":searchTerm,
        "group_type": groupType,
        "source_type": sourceType,
        "gender": gender,
        "birth_year": birthYear,
        "nationality": nationality
      }
    };
    return sendRequest({ path: `/ai_agent/search_result_assessment`, method: 'POST',data });
  }

};



import React, { createContext, useState, useContext } from 'react';
import { Customer } from '../models/Customer'; // Import the Customer model
import { Check } from '../models/CheckResult'; // Import the Customer model

import { api } from "../API/API"; // Assuming api has functions like createCustomer, getCustomer, etc.

const CustomerContext = createContext();

export const useCustomerContext = () => useContext(CustomerContext);

export const CustomerProvider = ({ children }) => {
  const [customerList, setCustomerList] = useState([]);
  const [caseList, setCaseList] = useState([]);

  const [pagination, setPagination] = useState({ limit: 10, cursor: null });
  const [paginationCases, setPaginationCases] = useState({ limit: 10, cursor: null });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch customers with pagination


  const fetchCases = async ({ initial = false,openCases = true, closedCases = true,
    assignee = null,
  } = {}) => {
    setLoading(true);
    try {
      const query = {
        openCases,
        closedCases,
        assignee,
        limit: paginationCases.limit,
        cursor: paginationCases.cursor,
      };
  
      const response = await api.queryCases(query);
      if (response.success) {
        const transformedData = response.body.cases.map((data) => {
          return new Check(data);
        });
  
        setPaginationCases((prev) => ({
          ...prev,
          cursor: response.body.cursor,
        }));
  
        if (initial) {
          setCaseList(transformedData);
        } else {
          const newCaseList = [...caseList, ...transformedData];
          setCaseList(removeDuplicates(newCaseList));
        }
      } else {
        console.error('Server error when fetching cases');
      }
    } catch (error) {
      console.error('Error fetching cases:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCustomers = async ({ initial = false,onlyUnClosedCases = true, onlyClosedCases = true, onlyNoCases = true, riskMin = 0, riskMax = 4, allowedResults = true,
    unAllowedResults = true,
  } = {}) => {
    setLoading(true);
    try {
      const query = {
        onlyUnClosedCases,
        onlyClosedCases,
        onlyNoCases,
        resultsUnallowed: unAllowedResults,
        resultsAllowed: allowedResults,
        riskMin,
        riskMax,
        limit: pagination.limit,
        cursor: pagination.cursor,
      };
  
      const response = await api.getCustomers(query);
      if (response.success) {
        const transformedData = response.body.customers.map((customerData) => {
          return new Customer(customerData);
        });
  
        setPagination((prev) => ({
          ...prev,
          cursor: response.body.cursor,
        }));
  
        if (initial) {
          setCustomerList(transformedData);
        } else {
          const newCustomerList = [...customerList, ...transformedData];
          setCustomerList(removeDuplicates(newCustomerList));
        }
        return transformedData
      } else {
        console.error('Server error when fetching customers');
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
    } finally {
      setLoading(false);
    }
  };




  // Helper function to remove duplicates
const removeDuplicates = (items) => {
  const itemMap = new Map();
  items.forEach((item) => {
    itemMap.set(item.id, item);
  });
  return Array.from(itemMap.values());
};
  
const getCustomer = async (customerId) => {
  try {
    setLoading(true);

    // First, attempt to find the customer in the existing list
    const customerFromList = customerList.find(
      (customer) => customer.id === customerId
    );

    if (customerFromList && customerFromList.fetchedCasesAndNotes === true) {
      // If found and already has fetched cases and notes, return it
      return customerFromList;
    }

    const response = await api.getCustomer(customerId);
    if (response.success) {
      const customerData = response.body;
      const newCustomer = new Customer(customerData);
      newCustomer.fetchedCasesAndNotes = true;

      setCustomerList((prevList) => {
        const filteredList = prevList.filter(
          (customer) => customer.id !== customerId
        );
        return [...filteredList, newCustomer];
      });

      return newCustomer;
    } else {
      console.error('Failed to fetch customer:', response.error);
      return new Customer({});
    }
  } catch (error) {
    console.error('Error fetching customer:', error);
    return new Customer({});
  } finally {
    setLoading(false);
  }
};


    

  // Get customers with memoization
  const getCustomers = async () => {
    if (customerList.length !== 0) {
      return customerList;
    } else {
      await fetchCustomers(true);
      return customerList;
    }
  };

  // Create a new customer
  const createCustomer = async (customerData) => {
    try {
      setLoading(true);
      const data = Customer.toApiFormat(customerData);
      const responseCreate = await api.createCustomer(data);
      if (responseCreate.success) {
        setCustomerList((prevList) => [new Customer(responseCreate.body), ...prevList]);
        const customerData = new Customer(responseCreate.body);
        return {
            success: true,
            customer: customerData,
        };
    } else {
        return responseCreate;
    }
    } catch (error) {
      setError(error.message);
      console.error('Failed to add customer:', error);
    }
  
  finally {
    setLoading(false);
  }
  };

  const updateCustomer = async (customerId, customerData) => {
    try {
      setLoading(true);
      const data = Customer.toApiFormat(customerData);
      const response = await api.updateCustomer(customerId, data);
      if (response.success) {
        setCustomerList((prevList) =>
          prevList.map((customer) => (customer.id === customerId ? new Customer(response.body) : customer))
        );
        const customerData = new Customer(response.body);
      
      return {
        success: true,
        customer: customerData,
      }}
      else {
        return response;
    }
    
    } catch (error) {
      console.error('Failed to update customer:', error);
    }
    finally {
      setLoading(false);
    }
  };

  const createCustomerNote= async (customerId, comment) => {
    try {
      setLoading(true);
      const customerFromList = customerList.find((customer) => customer.id === customerId);
  
      if (!customerFromList) {
        console.error(`Customer with ID ${customerId} not found`);
        return;
      }
      if(comment === "" || comment === undefined){return}
      const noteData = { note: comment };

      
      const response = await api.createCustomerNote(customerId, noteData);
      if (response.success) {
        console.log(response)
  
        setCustomerList((prevList) =>
          prevList.map((customer) => (customer.id === customerId ? customerFromList : customer))
        );
  
        customerFromList.notes = customerFromList.convertNotes(response.body || []);
        return customerFromList;
      } else {
        console.error(`Failed to update customer check for customer ${customerId}`);
        return null;
      }
    } catch (error) {
      console.error('Error updating customer check:', error);
      return null;
    }
    finally {
      setLoading(false);
    }
  };

  // Delete a customer
  const deleteCustomer = async (customerId) => {
    try {
      // Before deleting the customer, find the customer in customerList
      const customerToDelete = customerList.find((customer) => customer.id === customerId);
  
      // If customer is found, extract their case IDs
      const customerCaseIds = customerToDelete
        ? customerToDelete.cases.map((check) => check.id)
        : [];
  
      // API call to delete the customer
      const response = await api.deleteCustomer(customerId);
      if (response.success) {
        // Remove the customer from customerList
        setCustomerList((prevList) =>
          prevList.filter((customer) => customer.id !== customerId)
        );
  
        // Remove the customer's cases from caseList
        setCaseList((prevCaseList) =>
          prevCaseList.filter((caseItem) => !customerCaseIds.includes(caseItem.id))
        );
      }
      return response;
    } catch (error) {
      console.error('Failed to delete customer:', error);
    }
  };


  const updateCustomerCheck = async ({ customerId, checkId, matchStatus, riskLevel, progress, caseStatus, assignee, note }) => {
    try {
      setLoading(true);
      const customerFromList = customerList.find((customer) => customer.id === customerId);
  
      if (!customerFromList) {
        console.error(`Customer with ID ${customerId} not found`);
        return null;
      }
  
      // Build the data object for the API call
      const data = {};
      if (riskLevel !== null && riskLevel !== undefined) data.risk_level = String(riskLevel);
      if (matchStatus !== null && matchStatus !== undefined) data.match_status = String(matchStatus);
      if (progress !== null && progress !== undefined) data.progress = String(progress);
      if (caseStatus !== null && caseStatus !== undefined) data.case_status = String(caseStatus);
      if (assignee !== null && assignee !== undefined) data.assignee = String(assignee);
      if (note !== null && note !== undefined) data.note = String(note);
  
      // API call to update the customer check
      const response = await api.updateCustomerCase(customerId, checkId, data);
      if (response.success) {
        const responseCheck=new Check(response.body.case);
        customerFromList.updateCustomerCheck({checkId, responseCheck });
        setCustomerList((prevList) =>
          prevList.map((customer) => (customer.id === customerId ? customerFromList : customer))
        );

      const caseFromList = caseList.find((caseItem) => caseItem.id === checkId);

      if (caseFromList) {
        console.log("case before",caseFromList.riskLevel)
        caseFromList.updateProperties( responseCheck       );
        console.log("case after",caseFromList.riskLevel)
        // Update the caseList in state
        setCaseList((prevList) =>
          prevList.map((check) =>
            check.id === checkId ? caseFromList : check));
      }

  
        // Optionally update notes if the API response contains updated notes
        customerFromList.notes = customerFromList.convertNotes(response.body.notes || []);
        return customerFromList;
      } else {
        console.error(`Failed to update customer check for customer ${customerId}`);
        return null;
      }
    } catch (error) {
      console.error('Error updating customer check:', error);
      return null;
    } finally {
      setLoading(false);
    }
  };
  
  
  
  const updateCustomerAllowList = async ({ customerId, caseId, allowlist, checked }) => {
    try {
      setLoading(true);
      const data = { allowlist };
  
      let response;
      if (checked) {
        response = await api.updateCustomerAllowList(customerId, caseId, data);
      } else {
        response = await api.deleteCustomerAllowList(customerId, caseId, data);
      }
  
      if (response.success) {
        const customerFromList = customerList.find((customer) => customer.id === customerId);
  
        if (!customerFromList) {
          console.error(`Customer with ID ${customerId} not found`);
          return null;
        }
  
        customerFromList.allowlist = response.body.allow_list;
        customerFromList.notes = customerFromList.convertNotes(response.body.notes || []);
  
        setCustomerList((prevList) =>
          prevList.map((customer) => (customer.id === customerId ? customerFromList : customer))
        );
  
        return customerFromList; // Return the updated customer object
      } else {
        console.error('Failed to update customer allow list:', response.error);
        return null;
      }
    } catch (error) {
      console.error('Error updating customer allow list:', error);
      return null;
    }
    finally {
      setLoading(false);
    }
  };
  



  return (
    <CustomerContext.Provider
      value={{
        customerList,
        caseList,
        getCustomers,
        fetchCustomers,
        createCustomer,
        updateCustomer,
        deleteCustomer,
        getCustomer,
        updateCustomerCheck,
        updateCustomerAllowList,
        createCustomerNote,
        fetchCases,
        loading,
        pagination,
        paginationCases,
        error,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Row, Drawer } from "antd";
import AppLayout from "../components/AppLayout.jsx";
import KeyTable from "../components/KeyTable.jsx";
import { mapKeyData } from "../API/mapper.js";
import KeyFormLogic from "../components/KeyFormLogic.jsx";
import { KeyGeneratedCard } from "../components/CardViews.jsx";

import ColumnContainer from "../components/ColumnContainer.jsx";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  HelpTitleAddAPIKey,
  HelpContentAddAPIKey,
} from "../components/HelpText.js";

import { useListContext } from "../contexts/ListProviderContext.js";
import { showNotification } from "../components/Utils.jsx";

import { api } from "../API/API.js";

const useColumnDisplay = (selectedItem, isMonoColumn, mode) => {
  const isItemSelected = !!selectedItem; // Explicitly convert to boolean
  const showFirstColumn = !isMonoColumn || (!isItemSelected && mode !== "add");
  const showSecondColumn =
    isItemSelected ||
    mode === "add" ||
    mode === "edit" ||
    (isMonoColumn && mode === "add");
  return { showFirstColumn, showSecondColumn };
};

const KeysPage = () => {
  const ObjectName = "Key";
  const ObjectNameL = "key";
  const ListName = "keyList";
  const [lastGenerated, setLastGenerated] = useState(null);


  const [selectedItem, setSelectedItem] = useState(null);
  const [formDrawerVisible, setFormDrawerVisible] = useState(false);

  const [mode, setMode] = useState("view"); // Modes: view, add, edit
  const isCard = useMediaQuery({ maxWidth: 1000 });
  const isMonoColumn = useMediaQuery({ maxWidth: 800 });

  const [showHelp, setShowHelp] = useState(false);
  const [helpTitle, setHelpTitle] = useState("");
  const [helpContent, setHelpContent] = useState("");
  const [pagination, setPagination] = useState({
    cursor: null,
    limit: 20,
    
});
  const { keyList,setList, extendList, addItemList, deleteItemList, editItemList } =
    useListContext();

  const toggleEditPanelCollapsed = () => {
    setFormDrawerVisible(!formDrawerVisible); // Toggle menu drawer for mobile
  };

  const handleShowHelpToggleAddEditUser = () => {
    setHelpTitle(HelpTitleAddAPIKey);
    setHelpContent(HelpContentAddAPIKey);
    handleShowHelpToggle();
  };

  const handleShowHelpToggle = () => {
    setShowHelp((prevShowHelp) => !prevShowHelp);
  };

  const handleItemSelect = (Item) => {
    setSelectedItem(Item);
    setMode("view");
  };
  const handleAddItem = () => {
    setMode("add");
    setFormDrawerVisible(true);
  };
  const handleItemDelete = async (item) => {
    try {
      const response_delete = await api.deleteApikey(item.id);
      console.log("response_delete", response_delete);
      if (response_delete.success) {
        showNotification(
          "success",
          "Done!",
          `${ObjectName} deleted successfully`
        );
      }
      deleteItemList(`${ListName}`, item.id);
      setSelectedItem(null);
    } catch (error) {
      console.error("Failed to delete:", error);
    }
  };

  const handleOnSubmit = (item) => {
    switch (mode) {
      case "add":
        addItemList(`${ListName}`, item);
        break;

     
      default:
        break;
    }
   
  };

  const handleCancelForm = () => {
    setLastGenerated(null);
    setMode("view");
    setFormDrawerVisible(false);
  };
  const handleEdit = () => {
    console.log("mode", mode);
    setMode("edit");
    setFormDrawerVisible(true);
  };

  const { showFirstColumn, showSecondColumn } = useColumnDisplay(
    selectedItem,
    isMonoColumn,
    mode
  );

  const transformItemList = (items) => {
    return items.map(mapKeyData);
  };

 
  const Fetch = async (initial = false) => {
      try {
       
          const response = await api.getApikeys()
          if (response.success) {
            console.log("fetch",response)
            const transformedData = transformItemList(
              response.body.keys
            );
           
                 
            if (initial){
             
              setList(`${ListName}`, transformedData)
            }
            else{
              extendList(`${ListName}`, transformedData);
            }
          } else {
            console.error("Server error when fetching items");
          }
        
      } catch (error) {
        console.error("Error fetching item:", error);
      }
  };

  useEffect(() => {
    if (keyList.length === 0) {
     
      Fetch(true);
    }
  }, []); // Em

 

  return (
    <AppLayout
      handleShowHelpToggle={handleShowHelpToggle}
      showHelp={showHelp}
      helpTitle={helpTitle}
      helpContent={helpContent}
    >
      <Row gutter={10} style={{ display: "flex", flex: "1 1 auto" }}>
        {showFirstColumn && (
          <ColumnContainer
            colsize={14}
            title="API keys"
            helpIcon={true}
            handleShowHelpToggleAddEditUser={handleShowHelpToggleAddEditUser}
            buttonLabel={`${ObjectNameL}`}
            onButtonClick={handleAddItem}
            showBackButton={false}
          >
            <KeyTable
              data={keyList}
              pagination={pagination}
              Fetch={Fetch}
              
              onSelectItem={handleItemSelect}
              isCard={isCard}
            />
          </ColumnContainer>
        )}
        {showSecondColumn && (
          <ColumnContainer
            colsize={isMonoColumn ? 24 : 10}
            itemNameDelete={`${ObjectNameL}'s`}
            title={mode === "view" ? `${selectedItem.name}` : ""}
            showBackButton={
              mode === "add" ? false : mode === "edit" ? false : isMonoColumn
            }
            onBackClick={() => setSelectedItem(null)}
            showDelete={mode !== "add" && mode !== "edit"} // Set showDelete based on mode
            onDelete={() => handleItemDelete(selectedItem)}
          > <div className="dir-col">
            <KeyGeneratedCard item={selectedItem} onEdit={handleEdit} />
            </div>
          </ColumnContainer>
        )}
      </Row>

      <Drawer
        className="drawer-add-edit"
        placement="right"
        closable={true}
        maskClosable={false}
        mask={true}
        title={
          <>
            {mode === "add"
              ? `Generate API key`
              : mode === "edit"
              ? `Edit ${ObjectNameL}`
              : ""}
          </>
        }
        onClose={handleCancelForm}
        open={formDrawerVisible}
        width={500}
      >
        <KeyFormLogic
          data={selectedItem}
          mode={mode}
          onSubmit={handleOnSubmit} //user clicked submit edit changes
          onSubmitLabel="Generate"
          onCancelLabel="Cancel"
          onCancelForm={handleCancelForm}
          setLastGenerated={setLastGenerated}
          lastGenerated={lastGenerated}
          
        />
      </Drawer>
    </AppLayout>
  );
};

export default KeysPage;

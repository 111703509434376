import { useState } from "react";
import { Button, Input } from "antd";



const { TextArea } = Input;

export const AddCommentTo = ({
    buttonLabel = "Save",
    textAreaPlaceHolder = "Add a note...",
    onSubmitComment,
    showTextAreaButton,
    loading,
  }) => {
   
    const [textAreaContent, setTextAreaContent] = useState("");
  
    const handleTextAreaChange = (e) => {
      setTextAreaContent(e.target.value);
    };
  
   const onSubmit = () => {
    onSubmitComment(textAreaContent);
    setTextAreaContent(""); // Clear the textarea after submission
  };

    return (
      <div className="">
        <div className="align-right dir-row ">
        </div>
        {showTextAreaButton && (
          <>
            <div className="dir-col">
              
              <h5 display-property-label>Add a note to the Customer</h5>
   
              
                <TextArea
                  placeholder={textAreaPlaceHolder}
                  disabled={loading}
                  className="side-padding1"
                  value={textAreaContent} // Bind the state to the textarea content
                  onChange={handleTextAreaChange}
                  autoSize={true}
                 
                />
               
             
            </div>
  
            <div style={{ height: "1rem" }} />
            <div className="align-right">
              <Button
                type="primary"
                className="my-button"
                onClick={onSubmit}
                loading={loading}
              >
                {buttonLabel}
              </Button>
              
              <div style={{ height: "0.5rem" }} /> {/* Spacer */}
            </div>
          </>
        )}
      </div>
    );
  };
import { Check } from './CheckResult.js'; // Adjust path as necessary
import { Capitalize} from "../components/Utils";



class Note {
    constructor(noteData, id=null) {
        this.id = id || noteData.id || null;
        this.autoLog = noteData.autoLog || noteData.auto_log || false;
        this.note=noteData.note || null
        this.entityType =  noteData.entityType || noteData.entity_type || null;
        this.userId = noteData.userId || noteData.user_id || null;
        this.customerId =  noteData.customerId || noteData.customer_id || null;
        this.caseId =  noteData.case_id || noteData.caseId || null;
        this.resultId =  noteData.result_id || noteData.resultId || null;
        this.actionType = noteData.actionType || noteData.action_type || null;
        this.createdAt = noteData.createdAt || new Date(parseInt(id)).toISOString() ||  null;  // Convert timestamp to ISO string
        this.changedFields=noteData.changedFields || noteData.changed_fields || [];
    }
    

}



export class Customer {
    constructor(customerData = {}) {
        this.id = customerData.id || null;
        this.name = customerData.name || "";
        
        this.nationality = customerData.nationality || [];
        
        this.riskLevel=Number(customerData.riskLevel) || Number(customerData.risk_level) || 0;
        this.birthYear = customerData.birthYear || customerData.birth_year || null;
        this.birthDate = customerData.birthDate || customerData.birth_date || null;
        this.age=Customer.calculateAge(this.birthYear,this.birthDate);
        this.gender = Capitalize(customerData.gender) || null;
        this.groupType = Capitalize(customerData.groupType) || Capitalize(customerData.group_type) || null;
        this.jurisdiction = this.groupType && this.groupType.toLowerCase() === "entity" ? customerData.nationality : [];


        this.comments = customerData.comments || "";
        this.allowlist = customerData.allowlist || [];
        this.createdAt = customerData.createdAt ? new Date(customerData.createdAt) : (customerData.created_at ? new Date(customerData.created_at) : "");
        this.updatedAt = customerData.updatedAt ? new Date(customerData.updatedAt) : (customerData.updated_at ? new Date(customerData.updated_at) : "");

        this.cases = customerData.cases ? customerData.cases.map(check => new Check(check, this)) : null;
        this.amountCases=this.cases?this.cases.length:0;
        this.notes = customerData.notes ? this.convertNotes(customerData.notes) : [];
        
        this.resultsList = customerData.results_list || customerData.resultsList || [];
        this.hasResults=this.resultsList.length>0;
        this.unClosedCases=customerData.unClosedCases|| customerData.unclosed_cases|| [];
        this.amountUnClosedCases=this.unClosedCases.length;
        this.allowedResults = customerData.allowlist || [];
        this.amountAllowedResults = this.allowedResults.length; // Use .length property, not method
        this.amountUnAllowedResults = this.resultsList.length - this.allowedResults.length;
        

    }

    convertNotes(notesData) {
        if (notesData) {
            return Object.entries(notesData)
                .map(([timestamp, noteData]) => new Note(noteData, timestamp)) // Use timestamp as id
                
        }
        return []; // Return an empty array if notesData is undefined or null
    }
   
    
 
    updateCustomerCheck({ checkId, responseCheck }) {
        const check = this.cases.find(c => c.id === checkId);
    
        if (check) {
          check.updateProperties(responseCheck);
    
          // Handle special logic for caseStatus and unClosedCases
          if (responseCheck.caseStatus !== undefined) {
            if (Number(responseCheck.caseStatus) === 1) {
              console.log("removing from unClosedCases", checkId)
              console.log("unClosedCases", this.unClosedCases)
              this.unClosedCases = this.unClosedCases.filter(id => id !== checkId);
            } else if (Number(responseCheck.caseStatus) === 0) {
                console.log("adding to from unClosedCases", checkId)
                console.log("unClosedCases", this.unClosedCases)
              if (!this.unClosedCases.includes(checkId)) {
                this.unClosedCases.push(checkId);
              }
            }
            this.amountUnClosedCases=this.unClosedCases.length;
            this.amountCases=this.cases?this.cases.length:0;
          }
        } else {
          // Optionally handle the case where the check is not found
          console.warn(`Check with ID ${checkId} not found in customer ${this.id}`);
        }
    
        return this;
      }
    
    

    

    

   

    static toApiFormat(data) {
        try {
            const apiData = {
                id: data.id? String(data.id) : null,
                name: data.name,
                nationality:data.groupType.toLowerCase()==="entity" ? data.jurisdiction :data.nationality ,
                risk_level: String(data.riskLevel),
                birth_year: data.birthYear? String(data.birthYear) :"",
                gender: data.gender ? data.gender.toLowerCase() : "",  // Check if gender is not null or undefined
                group_type: data.groupType ? data.groupType.toLowerCase() : "", 
            };
            return apiData;
        } catch (error) {
            console.error("Error in toApiFormat:", error);
            throw new Error("Failed to convert data to API format");
        }
    }
    
   
    


   
    


    casesWithMatchStatus(matchStatus) {
        if (!this.cases) return 0;
    return this.cases.filter(check => check.matchStatus === matchStatus).length;
    }

    
    casesWithUnknownRiskLevel() {
        if (!this.cases) return 0;
    return this.cases.filter(check => check.riskLevel === 0).length;
    }

    casesWithRiskLevel(riskLevel) {
        if (!this.cases) return 0;
        return this.cases.filter(check => check.riskLevel === riskLevel).length;
    }

    

    notAllowedResults() {
        let notAllowlistedResults = 0;

        if (this.cases) {
            for (const check of this.cases) {
                for (const result of check.results) {
                    if (!this.allowlist.includes(result.id)) {
                        notAllowlistedResults++;
                    }
                }
            }
        }

        return notAllowlistedResults;
    }

    static calculateAge(birthYear, birthDate) {
        const currentDate = new Date();

        if (birthDate) {
            let age = currentDate.getFullYear() - birthDate.getFullYear();
            const monthDiff = currentDate.getMonth() - birthDate.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        } else if (birthYear) {
            return currentDate.getFullYear() - birthYear;
        } else {
            return null; // Age cannot be calculated if both birthDate and birthYear are missing
        }
    }


}

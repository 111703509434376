//user changes password using the code sent per email
//paswordreset
import React, { useState,useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useLocation } from 'react-router-dom'; // Import useHistory


import PasswordField from "../components/PasswordField";

import {useIncommingUser} from "../logic/onboarding"
import AppLayoutLogin from '../components/AppLayoutLogin';


const UserResetPasswordPage = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const location = useLocation();
  const email = location.state?.email || ''; // Get email from state, with fallback


  const { confirmResetPassword } = useIncommingUser();  // Destructure the function you need


  useEffect(() => {
    
    if (email) {
      form.setFieldsValue({ email });
    }
  }, [email, form]);


 

  const onSubmit = async (values) => {
    setIsLoading(true);
    const { email, verificationCode, password } = values;
    const result = await confirmResetPassword(email, verificationCode, password )
    if (result.success) {
      message.success("Done!");
      
    } 
    else {
      
      switch (result.error) {
        case 'CodeMismatchException':
          message.error('Invalid verification code.');
          form.setFields([
            {
              name: 'verificationCode',
              errors: ['Invalid verification code.'],
            },
          ]);
          break;
      
        case 'LimitExceededException':
          message.error('Attempt limit exceeded. Please wait 15 minutes before trying again.');
          form.setFields([
            {
              name: 'verificationCode',
              errors: ['Attempt limit exceeded. Please wait before trying again.'],
            },
          ]);
          break;
      
        case 'ExpiredCodeException':
          message.error('The code has expired. Request password reset again');
          form.setFields([
            {
              name: 'verificationCode',
              errors: ['The code has expired. Request password reset again'],
            },
          ]);
          break;
      
        default:
         
          break;
      }
    }
    setIsLoading(false);
  };
  


  const onFieldsChange = (_, allFields) => {
    const allFieldsValid = allFields.every(field => {
      const errors = form.getFieldError(field.name);
      return errors.length === 0 && field.value;
    });
    setFormIsValid(allFieldsValid);
  };

  

  return (
    <AppLayoutLogin>
      <div style={{ height: "3rem", flexShrink: 1 }}></div>
      <div className="onboarding-containers">

      <h1>Change your password</h1>
        <h4>A 6 digits verification code was sent to the registered email.</h4>
        <div style={{ height: "2rem", flexShrink: 1 }}></div>
        
          <Form
            form={form}
            name="change_password"
            layout="vertical"
            onFinish={onSubmit}
            onFieldsChange={onFieldsChange}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input placeholder="Email" className="input-big" />
            </Form.Item>
            <Form.Item
                name="verificationCode"
                validateTrigger="onBlur"
                rules={[
                  {
                    required: true,
                    message: 'Please input the verification code!',
                  },
                  {
                    pattern: /^[0-9]{6}$/,
                    message: 'Verification code must be exactly 6 digits!',
                  },
                ]}

              >
                <Input
                  placeholder="Verification Code"
                  className="input-big"
                  autoComplete="one-time-code"
                />
              </Form.Item>
           
              <Form.Item>
                <PasswordField form={form} />
              </Form.Item>
          
          <Button
               disabled={!formIsValid} 
                type="primary"
                htmlType="submit"
                ghost={true}
                loading={isLoading}
                className='input-big'
                > 
                Change Password

              </Button>
              </Form>
        </div>
    
    </AppLayoutLogin>
  );
};

export default UserResetPasswordPage;



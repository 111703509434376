import React, { createContext, useContext, useState, useEffect } from 'react';
import { ConfigProvider, theme } from 'antd';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem('darkMode') === "true";
  });

  useEffect(() => {
    document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
    localStorage.setItem('darkMode', isDarkMode ? 'true' : 'false');
  }, [isDarkMode]);

  const toggleTheme = () => setIsDarkMode(!isDarkMode);

  // Determine the primary and secondary colors based on the theme mode
  const primaryColor = isDarkMode
    ? getComputedStyle(document.documentElement).getPropertyValue('--color-primary-dark').trim()
    : getComputedStyle(document.documentElement).getPropertyValue('--color-primary-light').trim();

  const secondaryColor = isDarkMode
    ? getComputedStyle(document.documentElement).getPropertyValue('--color-secondary-dark').trim()
    : getComputedStyle(document.documentElement).getPropertyValue('--color-secondary-light').trim();

  // Define the Ant Design theme configuration, including dynamic primary and secondary colors
  const antdTheme = {
    algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
      colorPrimary: primaryColor,
      colorSecondary: secondaryColor, // Add the secondary color here
    },
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme ,setIsDarkMode}}>
      <ConfigProvider theme={antdTheme}>
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

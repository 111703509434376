import React, { useState } from "react";
import { Button, Divider, Alert } from "antd";
import { KeyForm } from "./KeyForm.jsx";

import { api } from "../API/API.js";
import { showNotification } from "./Utils.jsx";
import { KeyGeneratedCard } from "./CardViews";

import { mapKeyData } from "../API/mapper.js";

const Form = ({
  data,
  mode,
  onSubmit,
  onCancelForm,
  onSubmitLabel,
  onCancelLabel,
  lastGenerated,      
  setLastGenerated, 

}) => {
  const ObjectName = "Key";
  const ObjectNameL = "keys";
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [issavedisabled, setIsSaveDisabled] = useState(true);
  const [itemData, setItemData] = useState({});
    

  const handleSubmit = async () => {
    try {
      setIsLoadingSubmit(true);
      let response;
      if (mode === "add") {
        response = await api.createApikey(itemData);
      }

      setIsLoadingSubmit(false);
      if (response.success) {
        const Item = mapKeyData(response.body);
        setLastGenerated(Item);
        setIsSaveDisabled(true)
        onSubmit(Item);
        return;
      } else {
        console.log("response", response);
        switch (response.body.code) {
          case "400-API_KEY-RATE-EXCEEDED":
            showNotification(
              "error",
              `The amount of 10 api keys cannot be exceeded`
            );
            break;

          default:
            showNotification(
              "error",
              `Failed to create ${ObjectNameL}.`,
              response.body.message || "An unexpected error occurred."
            );
        }
      }
    } catch (error) {
      showNotification(
        "error",
        `Failed to create ${ObjectNameL}.`,
        "An internal error occurred. Please try again."
      );
    }
  };

  const dataChanged = (currentData) => {
    console.log("currentdata", currentData);

    const newData = {
      name: currentData.name,
    };
    let propertiesCondition = newData.name.length > 2;

    if (propertiesCondition) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
    setItemData(newData);
  };

  return (
    <div className="column-container-flex dir-col">
      <div className="column-container-overflow ">
      {lastGenerated ? (
  <>
    <Alert
      message="Warning"
      description={
        <>
          Store the <strong>API key value</strong> in a secure location. You will not be able to retrieve it again after closing this window.
        </>
      }
       type="warning"
      showIcon
    />
    <div style={{ height: "16px", flexShrink: 1 }}></div>

    <KeyGeneratedCard item={lastGenerated} />
  </>
) : (
  <KeyForm
    ContainerClassName="createcustomercontainer"
    onDataChanged={dataChanged}
    data={null}
    mode={mode}
  />
)}

      </div>
      <Divider />
      <div className="form-layout-spacer-button-bottom">
        <Button
          type="primary"
          onClick={handleSubmit}
          className="submit-button"
          loading={isLoadingSubmit}
          disabled={issavedisabled}
        >
          {onSubmitLabel}
        </Button>
        <Button type="default" onClick={onCancelForm} className="submit-button">
        {lastGenerated ? "Close" : "Cancel"}
        </Button>
      </div>
    </div>
  );
};

export default Form;

import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import { formatDate, Capitalize } from "./Utils";
import { useNavigate } from "react-router-dom";
import { useUserContext } from '../contexts/UserContext';
import { useCustomerContext } from '../contexts/CustomerContext'; 


import { Timeline, Button, Skeleton } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  WarningOutlined,
  MessageOutlined ,
  EditOutlined ,
  CloseOutlined
} from "@ant-design/icons";
import { MapNationalityToLabels } from "./CountrySelector";
import {AddCommentTo} from "./Comment";
import {
 
  MatchStatusTag,
  RiskStatusTag,
 
  ProgressTagRender,

} from "./Tags";


const Notes = ({ customerId, onClickHeader, overflow, onAdd }) => {
  const { getUsers } = useUserContext(); 
  const { customerList,createCustomerNote,loading } = useCustomerContext();

  const [users, setUsers] = useState([]); 
  const [edit,setEdit]=useState(false)
  const customer = customerList.find((c) => c.id === customerId);

  console.log("in notes customerId",customerId)

  useEffect(() => {
    const fetchUsers = async () => {
      const usersList = await getUsers(); 
      setUsers(usersList);
    };

    fetchUsers();
  }, [getUsers]);



  const onEdit = async () => {
    console.log("edit",edit)
    setEdit(!edit)
  };
  const onSubmitComment = async (comment) => {
    
    console.log("note", comment)
    await createCustomerNote(customerId, comment)
    setEdit(false)
  };

  if (!customerId || loading) {

    return (
      <div className={`dir-col expand-width`}>
       <div className={`component-header`}>
      </div>
        <div className="column-container-overflow  card-detail-props side-padding1 top-padding1 ">
        
          <Skeleton
            avatar
            paragraph={{
              rows: 9,
            }}
          />
          <Skeleton
            avatar
            paragraph={{
              rows: 5,
            }}
          />
          <Skeleton
            avatar
            paragraph={{
              rows: 5,
            }}
          />
       
        </div>
      </div>
    );
  }
 

  return (
    <div className={`dir-col expand-width `}>
      <div className={`component-header`}>
        <div className={`card-detail-title grey-text-color`}>
          Audit log
          <Button onClick={onEdit} type="ghost" icon={edit? <CloseOutlined/>: <EditOutlined />} />
        </div>
      </div>
      

      <div className="align-left column-container-overflow  card-detail-props right-padding04 top-padding1 ">
      
        <div style={{ height: overflow ? "0px" : "100%" ,width:"100%"}}>
        <div className="left-padding1">
        <AddCommentTo showTextAreaButton={edit} onSubmitComment={onSubmitComment}></AddCommentTo>
        </div>
          <NotesTimeline customer={customer} users={users}></NotesTimeline>
        </div>
      </div>
    </div>
  );
};

export default Notes;


export const NotesTimeline = ({ customer ,users}) => {
  const groupedNotes = groupNotesByTypeCreatedAt(customer?.notes);
  const sortedGroupedNotes = sortGroupedNotesByCreatedAt(groupedNotes);

  const items = createTimelineItems(sortedGroupedNotes, customer ,users);

  return <Timeline className="timeline" items={items} />;
};

const sortGroupedNotesByCreatedAt = (notes) => {
  return notes.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort in descending order
};


const groupNotesByTypeCreatedAt = (notes) => {
  if (!Array.isArray(notes)) {
    console.error("Expected an array for notes, but got:", notes);
    return [];
  }

  const newNotes = [];
  const groupedResults = {};

  notes.forEach((note) => {
    if (note.entityType === "result"  &&  Array.isArray(note.resultId) &&  note.resultId.length > 1 ) {
      const groupKey = `${note.actionType}_${note.createdAt}`;

      if (!groupedResults[groupKey]) {
        groupedResults[groupKey] = {
          ...note,
          entityType: `${note.entityType}s`, // Change to plural form like 'results'
          resultId: [],
          caseId: [],
        };
      }
      groupedResults[groupKey].resultId.push(...note.resultId);
      groupedResults[groupKey].caseId.push(note.caseId);
    } else {
      newNotes.push(note); // Keep individual notes unchanged
    }
  });

  Object.values(groupedResults).forEach((groupNote) => {
    newNotes.push(groupNote);
  });
  

  return newNotes;
};


const createTimelineItems = (notes, customer,users) => {
  return notes.map((note) => {
    if (note.entityType === 'results') {
      return createGroupedTimelineItem(note, customer,users);
    } else {
      return createIndividualTimelineItem(note,customer,users);
    }
  });
};


const createGroupedTimelineItem = (note, customer ,users) => {
  const user = users.find(user => user.id === note.userId);
  const userDisplayName = user ? `${user.name} ${user.surname}` : note.userId;

  return {
    key: `${note.entityType}_${note.id}`, // Unique key for the group
    dot: getTimelineIcon('result', note.actionType), // Use the actionType from the first note
    children: (
      <>
        <span className="display-property-label">Results {(note.actionType)}</span>
        <span className="secondary-text">
      @{userDisplayName}, {formatDate({ date: note.createdAt, showTime: true })}
      </span>
        <div className="display-property">
          <IdLinks
            resultIds={note.resultId}
           
            customerId={customer.id}
            caseId={note.caseId}
            entityType="result"
          />
        </div>
       
      <span className="display-property">{formatChangedFields(note.changedFields,customer,users)}</span>
      <span className="display-property"> {note.note}</span>
      </>
    ),
  };
};


export const EntityLink = ({ entityType, caseId, resultId, customerId, actionType }) => {
  const generateEntityPath = (entityType, caseId, resultId, customerId) => {
    if (entityType === "result") {
      return `/customers/${customerId}/cases/${caseId}/results/${resultId}`;
    } else if (entityType === "case") {
      return `/customers/${customerId}/cases/${caseId}`;
    } else if (entityType === "customer") {
      return `/customers/${customerId}`;
    }
    return null; // Return null if no valid path
  };

  const path = generateEntityPath(entityType, caseId, resultId, customerId);

  if (!path) {
    // Handle invalid paths if necessary
    return null;
  }

  return (
    <div className="display-property-label">
      <Link
        to={path}
        className="display-property-label"
        style={{ cursor: 'pointer' }}
      >
        {Capitalize(entityType) + " " + actionType}
      </Link>
    </div>
  );
};




const createIndividualTimelineItem = (note, customer, users) => {
  const user = users.find(user => user.id === note.userId);
  const userDisplayName = user ? `${user.name} ${user.surname}` : note.userId;

  return {
    key: note.id,
    dot: getTimelineIcon(note.entityType, note.actionType),
    children: (
      <>
     
        <EntityLink
          entityType={note.entityType}
          caseId={note.caseId}
          customerId={customer.id}
          actionType={note.actionType}
          resultId={note.resultId}
        />

        <span className="secondary-text">
          @{userDisplayName}, {formatDate({ date: note.createdAt, showTime: true })}
        </span>

        <span>{formatChangedFields(note.changedFields, customer, users)}</span>

        {note.note !== null && (
          <span className="display-property-value-note">
            <MessageOutlined /> {note.note}
          </span>
        )}
      </>
    ),
  };
};





const IdLinks = ({ resultIds, customerId, caseId, entityType }) => {
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();
  const host = window.location.origin; // Define host as the current origin
  const truncatedResultIds = resultIds.slice(0, 1);
  const remainingResultIds = resultIds.length > 2 ? resultIds.slice(2) : [];

  const handleNavigation = (e, id) => {
    e.preventDefault();
    if (entityType === "case") {
      navigate(`/customers/${customerId}/cases/${id}`);
    } else {
      navigate(`/customers/${customerId}/cases/${caseId}/results/${id}`);
    }
  };

  const generateLink = (id) => {
    if (entityType === "case") {
      return `${host}/customers/${customerId}/cases/${id}`;
    } else {
      return `${host}/customers/${customerId}/cases/${caseId}/results/${id}`;
    }
  };

  return (
    <span>
      {!showAll ? (
        <>
          {truncatedResultIds.map((id) => (
            <a
              key={id}
              href={generateLink(id)}
              onClick={(e) => handleNavigation(e, id)}
              className="result-link clickable"
              style={{ marginRight: '5px' }}
            >
              {id}
            </a>
          ))}
          {remainingResultIds.length > 0 && (
            <Button
              size="small"
              ghost
              onClick={() => setShowAll(true)}
              style={{ cursor: "pointer" }}
            >
              <h5>... more</h5>
            </Button>
          )}
        </>
      ) : (
        <>
          {resultIds.map((id) => (
            <a
              key={id}
              href={generateLink(id)}
              onClick={(e) => handleNavigation(e, id)}
              className="result-link"
              style={{ marginRight: '5px' }}
            >
              {id},
            </a>
          ))}
          <Button
            size="small"
            ghost
            onClick={() => setShowAll(false)}
            style={{ cursor: "pointer" }}
          >
            <h5>...less</h5>
          </Button>
        </>
      )}
    </span>
  );
};



const formatChangedFields = (changedFields,customer,users) => {
  if (!changedFields) return null;

  return Object.entries(changedFields).map(([field, values], index) => (
    <span key={index} >
      {formatFieldProperties(field, values[0], values[1],customer,users)}
    </span>
  ));
};

const formatFieldProperties = (field, valueBefore, valueAfter, customer, users) => {
  switch (field) {
    case "risk_level":
      return (
        <FieldChangeDisplay
          label="Risk"
          beforeComponent={<RiskStatusTag risklevel={valueBefore} />}
          afterComponent={<RiskStatusTag risklevel={valueAfter} />}
        />
      );

    case "match_status":
      return (
        <FieldChangeDisplay
          label="Match"
          beforeComponent={<MatchStatusTag matchStatus={valueBefore} />}
          afterComponent={<MatchStatusTag matchStatus={valueAfter} />}
        />
      );

    case "progress":
      return (
        <FieldChangeDisplay
          label="Progress"
          beforeComponent={<ProgressTagRender value={valueBefore} />}
          afterComponent={<ProgressTagRender value={valueAfter} />}
        />
      );

    case "birth_year":
      return (
        <FieldChangeDisplay
          label="Birth year"
          beforeComponent={<p className="display-property-value-timeline">{valueBefore}</p>}
          afterComponent={<p className="display-property-value-timeline">{valueAfter}</p>}
        />
      );

    case "nationality":
      const isEntity = customer.groupType.toLowerCase() === "entity";
      const nationalityLabel = isEntity ? "Jurisdiction" : "Nationality";
      const MapNationality = (value) => (value ? MapNationalityToLabels(value) : "Unknown");

      return (
        <FieldChangeDisplay
          label={nationalityLabel}
          beforeComponent={<p className="display-property-value-timeline">{MapNationality(valueBefore)}</p>}
          afterComponent={<p className="display-property-value-timeline">{MapNationality(valueAfter)}</p>}
        />
      );

    case "assignee":
      const getUserDisplayName = (userId) => {
        const user = users.find((user) => user.id === userId);
        return user ? `${user.name} ${user.surname}` : "unassigned";
      };

      return (
        <FieldChangeDisplay
          label="Assignee"
          beforeComponent={<p className="display-property-value-timeline">{getUserDisplayName(valueBefore)}</p>}
          afterComponent={<p className="display-property-value-timeline">{getUserDisplayName(valueAfter)}</p>}
        />
      );

    default:
      return (
        <FieldChangeDisplay
          label={Capitalize(field)}
          beforeComponent={<p className="display-property-value-timeline">{valueBefore}</p>}
          afterComponent={<p className="display-property-value-timeline">{valueAfter}</p>}
        />
      );
  }
};




const FieldChangeDisplay = ({ label, beforeComponent, afterComponent }) => (
  <div className="display-property-timeline">
    <p className="display-property-label-timeline">{label}: </p>
    {beforeComponent}
    <p className="display-property-value-timeline">→</p>
    {afterComponent}
  </div>
);




const getTimelineIcon = (entityType, actionType) => {
  if (entityType === "customer") {
    switch (actionType) {
      case "created":
        return (
          <CheckCircleOutlined style={{ fontSize: "16px", color: "green" }} />
        );
      default:
        return (
          <ClockCircleOutlined style={{ fontSize: "16px", color: "gray" }} />
        );
    }
  } else if (entityType === "case") {
    switch (actionType) {
      case "reopened":
      case "created":
        return <WarningOutlined style={{ fontSize: "16px", color: "red" }} />;

      case "closed":
        return (
          <CheckCircleOutlined style={{ fontSize: "16px", color: "green" }} />
        );
      default:
        return (
          <ClockCircleOutlined style={{ fontSize: "16px", color: "gray" }} />
        );
    }
  }
  // Default icon for any other entity types
  return <ClockCircleOutlined style={{ fontSize: "16px", color: "gray" }} />;
};
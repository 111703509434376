// CombinedCardViews.js
//to replace tale when screen is small

import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import CheckResultsTable from "./CustomerCheckResultsTable.jsx";
import { useUserContext } from '../contexts/UserContext';
import { useCustomerContext } from '../contexts/CustomerContext'; 



import {
  ResultStatusTag,
  CheckTag,
  MatchStatusTag,
  RiskStatusTag,
  riskOptions,
  CustomerRiskLevelTag,
  RiskTagRender,
  MatchStatusTagRender,
  ProgressTagRender,
  matchStatusOptions,
  progressStatusOptions,
  caseStatusOptions,
  CaseStatusTagRender,
} from "./Tags";

import { Select, Switch, Button, Input, Divider,Dropdown } from "antd";
import { MapNationalityToLabels } from "./CountrySelector";

import {
  ArrowRightOutlined,
  CloseOutlined,
  DownOutlined,
  PushpinOutlined,
  PushpinFilled,
  ProfileOutlined
} from "@ant-design/icons";

import {
  DetailCard,
  DisplayProperty,
  Subproperty,
  TargetInfo,
  MatchProfileCardView
} from "./CardViews";
import { formatDate} from "./Utils";


export const ScanHolder = ({
  check,
  customer,
  onSelectResult,
  isCard,
  onClick,
  activeCheckId,
  activeResultId,
  onShowResultInActiveCheck,
  showSelectedCheckResultInView,
  onShowCheckInDetailWhenIsCard,
}) => {
  const [showResults, setShowResults] = useState(false);
  const [cardActive, setCardActive] = useState(false);
  const [toggleIconActive, setToggleIconActive] = useState(false); // New state for toggle icon

  const handleToggleIconClick = (event) => {
    event.stopPropagation(); // Prevent event from bubbling up
    setToggleIconActive(!toggleIconActive);
    //setShowResults(!toggleIconActive); // Toggle results visibility based on icon state
  };
  const hasMoreCases=customer?.cases.length>0
  


  const handleShowCheckInDetailWhenIsCard = (item, event) => {
    event.stopPropagation(); // Stop event propagation
    onShowCheckInDetailWhenIsCard(item);
  };

  const handleClickCheck = (item, event) => {
    // Check if the event target is not related to the table
    if (!event.target.closest('.ant-table')) {
      if(!toggleIconActive){
      setCardActive(true);
      setShowResults(!showResults);
      onShowResultInActiveCheck(!showResults);
      onClick();}
    }
  };

  useEffect(() => {
    if (activeCheckId === check.id) {
      setCardActive(true);
      if (showSelectedCheckResultInView || toggleIconActive) {
        setShowResults(true);
      }
    } else {
      setCardActive(false);
      if (!toggleIconActive) {
        setShowResults(false);
      }
    }
  }, [activeCheckId, cardActive, toggleIconActive]);

  if (!check || !check.id) {
    return null;
  }


  return (
    <DetailCard
      title={`Case created on ${formatDate({
        date: check.createdAt,
        showTime: true,
      })}`}
      classCard={cardActive ? "card-check-active" : "card-pointer"}
      classHeader={
        cardActive ? "card-pointer card-check-active-header" : "card-pointer"
      }
      classPropsAdditional="card-pointer dir-col"
      onClick={onClick ? (item, event) => handleClickCheck(item, event) : null}
      onEdit={null}
      onActionButtonHeaderIcon={
        isCard ? <ArrowRightOutlined /> : <ArrowRightOutlined />
      }
      onActionButtonHeader={handleShowCheckInDetailWhenIsCard}
      selectedItemId={null}
      item={check}
      properties={[]}
      additionalComponents={
        <>
          <div
            className="card-view-checked-parameters"
            style={{ position: "relative" }}
          >
            <CheckDetails
              check={check}
              customer={customer}
              horizontal={true}
            />
            <h5>Checked parameters</h5>
            <TargetInfo target={check} />
          </div>

          {showResults && (
            <div className="align-right">
              {hasMoreCases &&(<div
                className="side-padding1"
                onClick={handleToggleIconClick}
              >
                {toggleIconActive ? (
                  <PushpinFilled /> // Icon when active
                ) : (
                  <PushpinOutlined /> // Icon when inactive
                )}
              </div>)}

               <MatchProfileCardView
                  matchProfiles={check.matchProfiles}
                  resultsSources={check.results}
                  searchTerm={check.searchTerm}
                  handleSelectItem={onSelectResult}
                  selectedItemId={activeResultId}
                  customer={customer}
                                        />

              
              
            </div>
          )}
        </>
      }
    />
  );
};


const { TextArea } = Input;



export const NoteOptAndSave = ({
  buttonLabel = "Save",
  textAreaPlaceHolder,
  submitButtonLabel = "save",
  showRestButtonTitle = "add",
  onSubmit,
  loading,
}) => {
  const [showTextAreaButton, setShowTextAreaButton] = useState(false);
  const [textAreaContent, setTextAreaContent] = useState("");

  const commentSuggestions = [
    { key: '0', label: "Assignee: Reassigned due to workload balancing." },
    { key: '1', label: "Assignee: Assigned to senior compliance officer for further review." },
    
    { key: '14', label: "Progress: Initial review completed, awaiting further data." },
    { key: '15', label: "Progress: Escalated for further investigation." },
    { key: '16', label: "Progress: Awaiting external review/approval." },
    { key: '18', label: "Progress: Awaiting additional information from the client." },
    { key: '20', label: "Match: Different birth date" },
    { key: '21', label: "Match: Different bith place" },
    { key: '22', label: "Match: Different middle name" },
    { key: '23', label: "Match: Different country of residence" },
    { key: '50', label: "Status: Reopened due to new findings." },
    { key: '51', label: "Status: Closed with no further actions required." },
    { key: '53', label: "Status: Closed, escalated to higher authority for further actions." }
  ];

  const handleTextAreaChange = (e) => {
    setTextAreaContent(e.target.value);
  };

  // Handles adding selected comment(s) to the text area
  const handleMenuClick = (info) => {
    const selectedComment = commentSuggestions.find((comment) => comment.key === info.key)?.label;
    const newContent = textAreaContent ? `${textAreaContent}\n${selectedComment}` : selectedComment;
    setTextAreaContent(newContent); // Append the selected comment to the text area content
  };

  const onTogleShowTextAreaButton = () => {
    setShowTextAreaButton((prev) => !prev); // Toggles the visibility state
  };

  const handleOnSubmit = () => {
    onSubmit(textAreaContent); // Submit the text area content
    setShowTextAreaButton(false); // Close the text area
  };

  // Menu items for the dropdown
  const items = commentSuggestions.map(comment => ({
    key: comment.key,
    label: comment.label,
    onClick: handleMenuClick
  }));

  return (
    <div className="right-padding1 max-width7">
      <div className="align-right dir-row ">
        {!showTextAreaButton && (
          <Button
            type="primary"
            ghost={true}
            size="normal"
            onClick={onTogleShowTextAreaButton}
          >
            {showRestButtonTitle}
          </Button>
        )}

        <div style={{ width: "0.5rem" }} /> {/* Spacer */}
        {!showTextAreaButton && (
          <Button
            type="primary"
            ghost={false}
            size="normal"
            onClick={handleOnSubmit}
            loading={loading}
          >
            {submitButtonLabel}
          </Button>
        )}
        {showTextAreaButton && (
          <Button
            type="default"
            ghost={false}
            size="small"
            onClick={onTogleShowTextAreaButton}
            loading={loading}
          >
            <CloseOutlined />
          </Button>
        )}
      </div>

      {showTextAreaButton && (
        <>
          <div className="dir-col">

            <h5 className="display-property-label">Add a note to the Case</h5>
              <TextArea
                placeholder={textAreaPlaceHolder}
                disabled={loading}
                className="side-padding1"
                value={textAreaContent} // Bind the state to the textarea content
                onChange={handleTextAreaChange}
                autoSize={true}
               
              />
              <div style={{ height: "0.5rem" }} />
               <Dropdown
              menu={{ items }} 
              trigger={['click']}
              placement="bottomLeft"
            >
              <Button  className="button-default-primary">
                <div >  Frequently used <DownOutlined /></div>
              </Button>
            </Dropdown>

           
          </div>

          <div style={{ height: "1rem" }} />
          <div className="align-right">
            <Button
              type="primary"
              className="my-button"
              onClick={handleOnSubmit}
              loading={loading}
            >
              {buttonLabel}
            </Button>
            <div style={{ height: "0.5rem" }} /> {/* Spacer */}
          </div>
        </>
      )}
    </div>
  );
};



export const CheckActionsCard = ({
  checkId,
  show=true,
  onUpdateCustomerCheck,
  customer,
  
}) => {
  const [check, setCheck] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getUsers } = useUserContext(); // Function to get the list of users
  const [users, setUsers] = useState([]); 
  const { updateCustomerCheck } = useCustomerContext();



  useEffect(() => {
    const fetchUsers = async () => {
      const usersList = await getUsers(); // Fetch users
      setUsers(usersList);
    };

    fetchUsers();
  }, [getUsers]);
  
  useEffect(() => {
    if (customer) {
      const foundCheck = customer.cases.find((c) => c.id === checkId);
      setCheck(foundCheck || null);
    }
  }, [customer, checkId]);


 const handleUserChange = async (userId) => {
    try {
      setCheck((prevCheck) => ({ ...prevCheck, assignee: userId })); // Update check state
    } catch (error) {
      console.error("Failed to assign user to the task:", error);
    }
  };
 
  const handleRiskChange = async (value) => {
    try {
      
      setCheck((prevCheck) => ({ ...prevCheck, riskLevel: value }));
    } catch (error) {
      console.error("Failed to update customer check:", error);
    }
  };
  const handleMatchStatusChange = async (value) => {
    try {
     
      setCheck((prevCheck) => ({ ...prevCheck, matchStatus: value }));
    } catch (error) {
      console.error("Failed to update customer check:", error);
    }
  };
  const handleProgressStatusChange = async (value) => {
    try {
      
      setCheck((prevCheck) => ({ ...prevCheck, progress: value }));
     
    } catch (error) {
      console.error("Failed to update customer check:", error);
    }
  };
  const onChangeCaseStatus = async () => {
      let newCaseStatus;
      if (check.caseStatus === 0) {
        newCaseStatus = 1;
      } else {
        newCaseStatus = 0;
      }
      setCheck((prevCheck) => ({ ...prevCheck, caseStatus: newCaseStatus }));
  };
  const handleOnEdit=async () => {
    setEditMode(!editMode);

  }
  const onSave=async (textAreaContent) => {
    try {
      setLoading(true);
     
      //await onUpdateCustomerCheck({ checkId: checkId, assignee: check.assignee, 
       // riskLevel:check.riskLevel,matchStatus:check.matchStatus,progress:check.progress,caseStatus:check.caseStatus,note:textAreaContent});
      await updateCustomerCheck({customerId:customer.id,
        checkId: checkId,
        riskLevel: check.riskLevel,
        matchStatus: check.matchStatus,
        progress: check.progress,
        caseStatus: check.caseStatus,
        assignee:check.assignee,
        note:textAreaContent
      })
      setEditMode(false);
      setLoading(false);
    } catch (error) {
      console.error("Failed to update customer check:", error);
    }
  }

  if (!show || !check || !checkId || !customer) {
    return null;
  }
  return (
    <div className="column-container-overflow-padding-right">
      {editMode ? (
  <CaseActionsCardEdit
    check={check}
    users={users}
    onActionButtonHeader={handleOnEdit}
    loading={loading}
    handleUserChange={handleUserChange}
    handleProgressStatusChange={handleProgressStatusChange}
    handleRiskChange={handleRiskChange}
    handleMatchStatusChange={handleMatchStatusChange}
    onChangeCaseStatus={onChangeCaseStatus}
    onSave={onSave}

  />
) : (
  <CaseActionsCard
    check={check}
    users={users}
    onEdit={handleOnEdit}
    loading={loading}
    onChangeCaseStatus={onChangeCaseStatus}
  />
)}

    </div>
  );
};

export const  CaseActionsCardEdit = ({
  check,
  users,
  onActionButtonHeader,
  loading,
  handleUserChange,
  handleProgressStatusChange,
  handleRiskChange,
  handleMatchStatusChange,
  onChangeCaseStatus,
  onSave,
}) => {
  if (!check) {
    return null;
  }

  return (
    <DetailCard
      title="Case actions"
      onActionButtonHeader={onActionButtonHeader}
      onActionButtonHeaderIcon={<CloseOutlined></CloseOutlined>}
      classProps="card-result-check-props"
      properties={[
        {
          label: "Assignee",
          label_width: "90px",
          value: (
            <Select
            disabled={check.caseStatus}
              allowClear={false}
              showSearch={false}
              className="select-case-actions"
              variant={"borderless"}
              value={check.assignee}
              loading={loading}
              onChange={handleUserChange}
              options={users.map((user) => ({
                label: `${user.name} ${user.surname}`, // Display user name and surname
                value: user.id, // Use user ID as value
              }))}
              placeholder="Select a team member"
            />
          ),
        },
        {
          label: "Progress",
          label_width: "90px",
          value: (
            <Select
              disabled={check.caseStatus}
              allowClear={false}
              showSearch={false}
              className="select-case-actions"
              variant={"borderless"}
              value={check.progress}
              loading={loading}
              onChange={handleProgressStatusChange}
              options={progressStatusOptions}
              labelRender={({ value }) => <ProgressTagRender value={value} />}
            />
          ),
        },
        {
          label: "Risk",
          label_width: "90px",
          value: (
            <Select
            disabled={check.caseStatus}
              allowClear={false}
              showSearch={false}
              className="select-case-actions"
              variant={"borderless"}
              value={check.riskLevel}
              loading={loading}
              onChange={handleRiskChange}
              options={riskOptions}
              labelRender={({ value }) => <RiskTagRender value={value} />}
            />
          ),
        },
        {
          label: "Match",
          label_width: "90px",
          value: (
            <Select
            disabled={check.caseStatus}
              allowClear={false}
              showSearch={false}
              variant={"borderless"}
              className="select-case-actions"
              value={check.matchStatus}
              loading={loading}
              onChange={handleMatchStatusChange}
              options={matchStatusOptions}
              labelRender={({ value }) => (
                <MatchStatusTagRender value={value} />
              )}
            />
          ),
        },
        {
          label: "Case Status",
          label_width: "90px",
          value: (
            <Select
             
              allowClear={false}
              showSearch={false}
              variant={"borderless"}
              className="select-case-actions"
              value={check.caseStatus}
              loading={loading}
              onChange={onChangeCaseStatus}
              options={caseStatusOptions}
              labelRender={({ value }) => (
                <CaseStatusTagRender value={value} />
              )}
            />
          ),
        },
      ]}
      selectedItemId={null}
      additionalComponents={
        <>
         <Divider />
        <NoteOptAndSave
            buttonLabel={ "Save" }
            textAreaPlaceHolder={ "Add a note" }

            showRestButtonTitle={ "Add note"             }
            onSubmit={onSave}
            loading={loading}
          />
         
          
        </>
      }
    />
  );
};
export const  CaseActionsCard = ({
  check,
  onEdit,
  loading,
  users,
  
}) => {
  if (!check) {
    return null;
  }
  const assignedUser= users.find((user) => user.id === check.assignee);
  const assignedUserName = assignedUser
    ? `${assignedUser.name} ${assignedUser.surname}`
    : "not assigned"; // Display "Not assigned" if no user is found
  return (
    <DetailCard
      title="Case actions"
      onEdit={onEdit}
      classProps="card-result-check-props"
      properties={[
        {
          label: "Assignee",
          label_width: "110px",
          value: (assignedUserName)
          
        },
        {
          label: "Progress",
          label_width: "110px",
          value: (
            <ProgressTagRender value={check.progress} loading={loading} />
          ),
        },
        {
          label: "Risk",
          label_width: "110px",
          value: (
            <RiskTagRender value={check.riskLevel} loading={loading}/>
          ),
        },
        {
          label: "Match",
          label_width: "110px",
          value: (
            <MatchStatusTagRender value={check.matchStatus} loading={loading}/>
          ),
        },
        {
          label: "Status",
          label_width: "110px",
          value: (
            <CaseStatusTagRender value={check.caseStatus} loading={loading}/>
          ),
        },
      ]}
      selectedItemId={null}
      
    />
  );
};


export const CheckResultActionsCard = ({
  show,
  onAllowlistChange,
  check,
  customer
}) => {
  const [loadingStatus, setLoading] = useState(false);
  

  const tagValues = [
    {
      label: "All allowlisted", // if allAllowed is true
      tagtype: "resultAllowed",
    },
    {
      label: "All not allowlisted", // if allNotAllowed is true
      tagtype: "resultNotAllowed",
    },
    {
      label: "Mixed values", // if allNotAllowed is false and allAllowed is false
      tagtype: "",
    },
  ];
  // Derive values directly from check object
  let allAllowed = false;
  let allNotAllowed = false;
 console.log("check in checkresultsactionscard",check)
  if (check && check.results) {
    allAllowed = check.results.every(
      (result) => result.calculateIsAllowListedcustomer(customer) === true
    );
    allNotAllowed = check.results.every(
      (result) => result.calculateIsAllowListedcustomer(customer)  === false
    );
  }

  // Determine the correct tag to display based on the conditions
  let selectedTag;
  if (allAllowed) {
    selectedTag = tagValues[0];
  } else if (allNotAllowed) {
    selectedTag = tagValues[1];
  } else {
    selectedTag = tagValues[2];
  }
  

  const handleSwitchChange = async (checked) => {
    try {
      setLoading(true);
      const resultsIdList = check.results.map((result) => result.id);

      await onAllowlistChange({ checked: checked, resultId: resultsIdList });

      setLoading(false);
    } catch (error) {
      console.error("Failed to update customer check:", error);
    }
  };

  if (!show || !check  ) {
    return null;
  }

  return (
    <div className="column-container-overflow-padding-right">
      <DetailCard
        title="Results bulk actions"
        classProps="card-result-check-props"
        properties={[
          {
            label: "Allow all",
            label_width: "70px",
            value: (
              <div className="rate-tag">
                <Switch
                  size="small"
                  loading={loadingStatus}
                  onChange={handleSwitchChange}
                  value={allAllowed}
                  className="icon-text"
                />
                <CheckTag
                  value={selectedTag.label}
                  tagType={selectedTag.tagtype}
                />
              </div>
            ),
          },
        ]}
        selectedItemId={null}
        additionalComponents={null}
      />
    </div>
  );
};

export const CheckDetailCard = ({ item, show=true }) => {
  if (!show || !item || !item.id) {
    return null;
  }

  return (
    <div className="column-container-overflow-padding-right">
      <DetailCard
        title="Case info"
        
        selectedItemId={null}
        properties={[
          { label: "Case id.", value: item.id, copyable: true, compact: true },

          {
            label: "Created",
            value: formatDate({ date: item.createdAt, showTime: true }),
            compact: true,
          },
          {
            label: "Updated",
            value: formatDate({ date: item.updatedAt, showTime: true }),
            compact: true,
          },
        ]}
        additionalComponents={
          
           
              <Subproperty
                label="Checked parameters"
                label_width="190px"
                
                key="identification"
                value=" "
                compact={true}
                element={item.name}
                children={
                  <div>
                   
                    <DisplayProperty
                      label="Name"
                      value={item.name}
                      compact={true}
                    />
                    <DisplayProperty
                      label="Type"
                      value={item.groupType}
                      compact={true}
                    />
                    <DisplayProperty
                      label="Nat./Juri."
                      value={MapNationalityToLabels(item.nationality)}
                      compact={true}
                    />
                    <DisplayProperty
                      label="Birth year"
                      value={item.birthYear}
                      compact={true}
                    />
                    <DisplayProperty
                      label="Gender"
                      value={item.gender}
                      compact={true}
                    />
                  </div>
                }
              ></Subproperty>
            
          
        }
      />
    </div>
  );
};

export const CustomerBasicDetailCard = ({ item, onEdit }) => {
  if (!item || !item.id) {
    return null;
  }
  return (
    <DetailCard
      title="Customer details"
      onEdit={onEdit}
      selectedItemId={null}
      properties={[
        {
          label: "Customer id.",
          value: item.id,
          copyable: true,
          compact: true,
        },
        { label: "Name", value: item.name, compact: true },
        { label: "Type", value: item.groupType, compact: true },

        {
          label: "Risk level",
          compact: true,
          value: <CustomerRiskLevelTag riskLevel={`${item.riskLevel}`} />,
        },

        {
          label: "Nat./Juri.",
          compact: true,
          value: MapNationalityToLabels(item.nationality),
        },
        { label: "Birth year", value: item.birthYear, compact: true },
        { label: "Gender", value: item.gender, compact: true },
        {
          label: "Created",
          compact: true,
          value: formatDate({ date: item.createdAt, showTime: true }),
        },
        {
          label: "Updated",
          compact: true,
          value: formatDate({ date: item.updatedAt, showTime: true }),
        },
      ]}
    />
  );
};

export const CustomerDetailCard = ({
  customer,
  onEdit,
 
}) => {
  if (!customer || !customer.id) {
    return null;
  }
  return (
    <div>
      <CustomerBasicDetailCard item={customer} onEdit={onEdit} />
      
    </div>
  );
};



export const CheckStatusCard = ({
  customerId,
  onCustomerViewDetails,
 
}) => {
  const { getCustomer, customerList } = useCustomerContext();
  const [localLoading, setLocalLoading] = useState(true);

  // Find the customer in the customerList directly from the context
  const customer = customerList.find((c) => c.id === customerId);
  useEffect(() => {
    const fetchCustomer = async () => {
      if (customerId ) {
        setLocalLoading(true);
        await getCustomer(customerId); // Fetch customer if not available or not fully loaded
        setLocalLoading(false);
      }
    };

    fetchCustomer();
  }, [customerId, customerList]);

  const hasOpen = customer?.amountUnClosedCases > 0;
  if(!customerId) return

  return (
    <DetailCard
      title={hasOpen ? "Cases open" : "Cases"}
      classTitle={hasOpen ? "alert-text-color" : ""}
      selectedItemId={null}
      loading={localLoading}
      onActionButtonHeader={onCustomerViewDetails}
      onActionButtonHeaderTitle={customer.hasResults ? "details" : ""}
      onActionButtonHeaderIcon={customer.hasResults ? <ArrowRightOutlined /> : ""}
      properties={[
        {
          label: "Cases total",
          label_width: "220px",
          compact: true,
          value: customer.amountCases,
        },
        {
          label: "Cases open",
          label_width: "220px",
          compact: true,
          value: customer.amountUnClosedCases,
        },
        
      ]}
      additionalComponents={
        <AlertCardItems
          customer={customer}
          onCustomerViewDetails={onCustomerViewDetails}
        ></AlertCardItems>
      }
    />
  );
};

export const AlertCardItems = ({ customer, onCustomerViewDetails }) => {
  if (!customer || !customer.cases || !Array.isArray(customer.cases)) {
    return null;
  }

  const cases = customer.cases;
  const totalChecks = cases.length;
  const showMoreChecks = totalChecks > 2;
  const moreChecksCount = totalChecks - 2;
  const moreChecksText = moreChecksCount === 1 ? "more case" : "more cases";

  return (
    <div className="container-cards-customer ">
      {cases.slice(0, 2).map((check, index) => (
        <div key={index} className="card-detail-subproperty">
          
          
            <DisplayProperty
              label="Case created"
              value={formatDate({
                date: check.createdAt,
                showTime: true,
              })}
            />
   
            <CheckDetails check={check} customer={customer} />
          
        </div>
      ))}
      {showMoreChecks && (
        <div
          className="card-sanction-regions-found card-sanction-source-found"
          onClick={onCustomerViewDetails}
        >
          <div className="side-padding1">
            <h7
              style={{
                color: "grey",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              ... {moreChecksCount} {moreChecksText} available in details
            </h7>
          </div>
        </div>
      )}
    </div>
  );
};

export const CheckDetails = ({ check, customer, horizontal = false }) => {
  const containerClass = horizontal ? "dir-row flex-wrap " : "";

  return (
    <div className={containerClass}>
      <DisplayProperty
        label="Status"
        label_width={horizontal ? "auto" : ""}
        classDisplayPropertyValue="flex-wrap"
        compact={true}
        value={
          <>
            <CaseStatusTagRender value={check.caseStatus} />
          </>
        }
      />
      <DisplayProperty
        label="Progress"
        label_width={horizontal ? "auto" : ""}
        classDisplayPropertyValue="flex-wrap"
        compact={true}
        value={
          <>
            <ProgressTagRender value={check.progress} />
          </>
        }
      />
      <div style={{ width: "1rem" }} /> {/* Spacer */}
      <div style={{ width: "1rem" }} /> {/* Spacer */}
      <DisplayProperty
        label="Match"
        label_width={horizontal ? "auto" : ""}
        classDisplayPropertyValue="flex-wrap"
        compact={true}
        value={<MatchStatusTag matchStatus={check.matchStatus} />}
      />
      <DisplayProperty
        label="Risk"
        label_width={horizontal ? "auto" : ""}
        classDisplayPropertyValue="flex-wrap"
        compact={true}
        value={<RiskStatusTag risklevel={check.riskLevel} />}
      />
      <div style={{ width: "1rem" }} /> {/* Spacer */}
      <DisplayProperty
        label="Results"
        label_width={horizontal ? "auto" : ""}
        classDisplayPropertyValue="flex-wrap"
        compact={true}
        value={
          <>
            <CheckTag
              value={`${check.allowlistedAmount(customer.allowlist)} allowed`}
              tagType="resultAllowed"
            />
            <CheckTag
              value={`${check.notAllowlistedAmount(
                customer.allowlist
              )} not allowed`}
              tagType="resultNotAllowed"
            />
          </>
        }
      />
    </div>
  );
};

export const CustomerCardView = ({
  data,
  selectedItemId,
  handleSelectItem,
  loading,
}) => {
  const { userList } = useUserContext(); 
  if (!data) {
    return null;
  }

  
  const getAssigneeName = (userId) => {
    const user = userList.find((user) => user.id === userId);
    return user ? `${user.name} ${user.surname}` : "Not assigned";
  };
  return (
    <div className="container-cards-customer">
      {data.map((item) => (
        <DetailCard
          classCard="card-clickcable"
          key={item.id}
          item={item}
          onClick={handleSelectItem}
          title={`${item.name}`}
          onEdit={null}
          loading={loading}
          selectedItemId={selectedItemId}
          properties={[

            { label: "Case id.", value: item.id, compact: true },
            { label: "Asignee", value: getAssigneeName(item.assignee)
            , compact: true 
          },
            {
              label: "Results",
              value:
              item.amountUnAllowedResults != null ? (
                <div className="rate-tag">
                  <CheckTag
                    value={`${item.amountUnAllowedResults} not allowed`}
                    tagType="resultNotAllowed"
                  />
                </div>
              ) : null,
              compact: true,
            },
            {
              label: "Case status",
              value:
              item.caseStatus != null ? (
                <div >
                  <CaseStatusTagRender
                    value={item.caseStatus}
                    
                  />
                </div>
              ) : null,
              compact: true,
            },
            
            { label: "Type", value: item.groupType, compact: true },

            {
              label: "Risk level",
              compact: true,
              value: <CustomerRiskLevelTag riskLevel={`${item.riskLevel}`} />,
            },

            {
              label: "Nat./Juri.",
              value: MapNationalityToLabels(item.nationality),
              compact: true,
            },
            { label: "Birth year", value: item.birthYear, compact: true },
            {
              label: "Results",
              value: item.customerId ? (
               
                  <Link to={`/customers/${item.customerId}/cases/${item.id}`}>
                    <ProfileOutlined style={{ marginRight: '6px' }} />
                    View
                  </Link>
               
              ) : null,
              compact: true,
            },
            




          ]}
        />
      ))}
    </div>
  );
};

export const ResultActionsCard = ({
  item,
  loading,
  onAllowlistChange,
  customer,
  show,
}) => {
  if (!item || !item.id || !show) {
    return null; // Renders nothing if there is no data
  }
  const handleSwitchChange = async (checked) => {
    // Invoke the callback provided by the parent, passing the new allowlist status
    await onAllowlistChange({ checked: checked, resultId: [item.id] });
  };

  const isAllowed = customer?.allowlist?.includes(item.id);

  return (
    <div className="">
      <DetailCard
        title="Result actions"
        classProps="card-result-actions-props"
        properties={[
          {
            label: "Allow result",
            label_width: "100px",
            value: (
              <div className="rate-tag">
                <Switch
                  size="small"
                  loading={loading}
                  onChange={handleSwitchChange}
                  value={isAllowed}
                  className="icon-text"
                />
                <ResultStatusTag allowlisted={isAllowed}></ResultStatusTag>
              </div>
            ),
          },
        ]}
        selectedItemId={null}
        additionalComponents={null}
      />
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Input, Tooltip } from "antd";


export const UserForm = ({
  mode,
  onDataChanged,
  data,
  
  ContainerClassName,
}) => {
  // State object to hold all form data
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    id: ""
  });

  // Handle form data changes
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Effect to update state when mode or data changes
  useEffect(() => {
    console.log("Data in userform useEffect",data)
    if (mode === "edit" && data) {
      setFormData({
        name: data.name || "",
        surname: data.surname || "",
        email: data.email || "",
        id: data.id || "",
        
      });
    } else if (mode === "add") {
      resetValues();
    }
  }, [data, mode]);

  // Reset form values for create mode
  const resetValues = () => {
    setFormData({
      name: "",
      surname: "",
      email: "",
      id:"",
    });
  };



  // Effect to pass updated form data to parent component
  useEffect(() => {
    onDataChanged({
      
        name: formData.name,
        surname: formData.surname,
        email: formData.email,
        id: formData.id,
     
    });
  }, [formData]);

 

  return (
    <div className={ContainerClassName}>
      

      <><div>
          <Tooltip title={mode === "edit" ? "" : "Links the user id. in your system to Checklynx. Leave blank to auto-generate."}>


            <h5>User id.</h5>
            <Input
              placeholder="User id. in your system or leave blank"
              value={formData.id}
              onChange={(e) => handleInputChange("id", e.target.value)}
              disabled={mode === "edit"}  // Disable field in edit mode
            />
          </Tooltip>
        </div>
     
        <div>
          <h5>
          
            Name
          </h5>
          <Input
            placeholder="name"
            value={formData.name}
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
        </div>

        <div>
          <h5>
          
            Surname
          </h5>
          <Input
            placeholder="surname"
            value={formData.surname}
            onChange={(e) => handleInputChange("surname", e.target.value)}
          />
        </div>

        <div>
          <h5>
            <span style={{ color: "red", marginRight: "4px" }}>*</span>
            Email
          </h5>
          <Input
            placeholder="user@company.com"
            value={formData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            disabled={mode === "edit"} 
          />
        </div>
      
     
        
      
      
    
      
      
    </>
     

    
    </div>
  );
};

/**
 * @param {Result} item - The customer object to update with checks.
 * @returns {Promise<void>}
 */
import React, { useState, useEffect } from "react";
import AppLayout from "../components/AppLayout";
import { useMediaQuery } from "react-responsive";
import {
  ResultCardView,
  MatchProfileCardView,
  ResultCard,
  AiAgentSearchResultAssessment,
} from "../components/CardViews";
import ResultsTable from "../components/ResultsTable";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { Input, Button, Row, Col } from "antd";
import { api } from "../API/API";
import useWindowSize from "../components/useWindowSize";
import PopoverFilter, { FilterTags } from "../components/PopoverFilter";
import { useListContext } from "../contexts/ListProviderContext";
import ColumnContainer from "../components/ColumnContainer";
import OverFlowLayout from "../components/OverFlowLayout";

import { InfoCircleOutlined } from "@ant-design/icons";
import { HelpTitleSearch, HelpContentSearch } from "../components/HelpText";
import { truncateString } from "../components/Utils";

import { Result, mapResultsAndProfiles } from "../models/CheckResult";
import { useTenantContext } from "../contexts/TenantContext";

const useColumnDisplay = (selectedItem, isMonoColumn, mode) => {
  const isItemSelected = !!selectedItem; // Explicitly convert to boolean
  const showFirstColumn = !isMonoColumn || (!isItemSelected && mode !== "add");
  const showSecondColumn =
    isItemSelected ||
    mode === "add" ||
    mode === "edit" ||
    (isMonoColumn && mode === "add");
  return { showFirstColumn, showSecondColumn };
};

const SearchPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [noYieldResults, setnoYieldResults] = useState(false); // State to store API data
  const isMonoColumn = useMediaQuery({ maxWidth: 800 });

  const [mode, setMode] = useState("view"); // Modes: view, add, edit
  const { tenant, fetchTenant } = useTenantContext();

  // const [currentFilters, setCurrentFilters] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [aiProcessing, setAiProcessing] = useState(true);
  const [rawSearchResults, setRawSearchResults] = useState([]);

  const { isMobile } = useWindowSize();
  const { searchResults, updateSearchResults } = useListContext();
  const { filterSearchParams, updateFilterSearchParams } = useListContext();

  const [showHelp, setShowHelp] = useState(false);
  const [helpTitle, setHelpTitle] = useState("");
  const [helpContent, setHelpContent] = useState("");
  const location = useLocation();

  const handleShowHelpToggleAddEditUser = () => {
    setHelpTitle(HelpTitleSearch);
    setHelpContent(HelpContentSearch);
    handleShowHelpToggle();
  };

  const handleShowHelpToggle = () => {
    setShowHelp((prevShowHelp) => !prevShowHelp);
  };

  const { showFirstColumn, showSecondColumn } = useColumnDisplay(
    selectedItem,
    isMonoColumn,
    mode
  );

  const handlesearchTermChange = (e) => {
    const searchTerm = e.target.value;
    const updatedFilters = {
      ...filterSearchParams,
      searchTerm: searchTerm,
    };
    updateFilterSearchParams(updatedFilters);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams();
    if (
      filterSearchParams.searchTerm !== undefined &&
      filterSearchParams.searchTerm !== ""
    ) {
      searchParams.set("q", filterSearchParams.searchTerm);
    }

    if (filterSearchParams.groupType) {
      searchParams.set("groupType", filterSearchParams.groupType);
    }

    if (filterSearchParams.onlyEntity) {
      if (filterSearchParams.jurisdiction) {
        searchParams.set(
          "jurisdiction",
          filterSearchParams.jurisdiction.join(",")
        );
      }
    }

    if (filterSearchParams.onlyIndividual) {
      if (filterSearchParams.nationality) {
        searchParams.set(
          "nationality",
          filterSearchParams.nationality.join(",")
        );
      }
      if (filterSearchParams.birthYear) {
        searchParams.set("birthYear", filterSearchParams.birthYear);
      }
      if (filterSearchParams.gender) {
        searchParams.set("gender", filterSearchParams.gender.join(","));
      }
      if (filterSearchParams.sourceType) {
        searchParams.set("sourceType", filterSearchParams.sourceType.join(","));
      }
    }

    if (filterSearchParams.fuzziness !== undefined) {
      searchParams.set("fuzziness", filterSearchParams.fuzziness);
    }

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, "", newUrl);
  }, [filterSearchParams]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newFilterSearchParams = {};

    if (searchParams.has("q")) {
      newFilterSearchParams.searchTerm = searchParams.get("q");
    }
    if (searchParams.has("groupType")) {
      const groupType = searchParams.get("groupType");
      newFilterSearchParams.groupType = groupType;

      newFilterSearchParams.onlyEntity = groupType === "entity";
      newFilterSearchParams.onlyIndividual = groupType === "individual";
    }

    if (newFilterSearchParams.onlyEntity) {
      if (searchParams.has("jurisdiction")) {
        newFilterSearchParams.jurisdiction = searchParams
          .get("jurisdiction")
          .split(",");
      }
    }

    if (newFilterSearchParams.onlyIndividual) {
      if (searchParams.has("nationality")) {
        newFilterSearchParams.nationality = searchParams
          .get("nationality")
          .split(",");
      }
      if (searchParams.has("birthYear")) {
        newFilterSearchParams.birthYear = searchParams.get("birthYear");
      }
      if (searchParams.has("gender")) {
        newFilterSearchParams.gender = searchParams.get("gender").split(",");
      }
      if (searchParams.has("fuzziness")) {
        newFilterSearchParams.fuzziness = searchParams.get("fuzziness");
      }
      if (searchParams.has("sourceType")) {
        newFilterSearchParams.sourceType = searchParams
          .get("sourceType")
          .split(",");
      }
    }

    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      ...newFilterSearchParams,
    }));
    if (newFilterSearchParams.term !== "") {
      handleSearch(newFilterSearchParams);
    }
  }, []);

  useEffect(() => {
    if (!tenant) fetchTenant();
    if (filterSearchParams.fuzziness === undefined && tenant) {
      updateFilterSearchParams((prevFilters) => ({
        ...prevFilters,
        fuzziness: tenant.settings.search.fuzziness,
      }));
    }
  }, [tenant, filterSearchParams.fuzziness, updateFilterSearchParams]);

  // Helper to extract shared filter variables
  const getSearchParams = (filters, tenant) => {
    const searchTerm = filters.searchTerm;
    const sourceType = filters.sourceType || [];
    const groupType =
      filters.groupType === "individual"
        ? ["individual"]
        : filters.groupType
        ? [filters.groupType.toLowerCase()]
        : [];
    const nationality =
      filters.groupType === "individual"
        ? filters.nationality || []
        : filters.jurisdiction || [];
    const birthYear = filters.birthYear ? [String(filters.birthYear)] : [];
    const gender = filters.gender?.length === 1 ? filters.gender : [];
    const fuzziness =
      filters.fuzziness !== undefined
        ? String(filters.fuzziness)
        : String(tenant?.settings.search?.fuzziness);
    const excludeSanctionSourceId =
      tenant?.settings.search?.excludeSanctionSourceId;

    return {
      searchTerm,
      sourceType,
      groupType,
      nationality,
      birthYear,
      gender,
      fuzziness,
      excludeSanctionSourceId,
    };
  };

  const handleSearch = async (currentFilters) => {
    const filters = currentFilters ?? filterSearchParams;
    const {
      searchTerm,
      sourceType,
      groupType,
      nationality,
      birthYear,
      gender,
      fuzziness,
      excludeSanctionSourceId,
    } = getSearchParams(filters, tenant);

    if (!searchTerm?.trim()) return;

    setIsLoading(true);
    setAiProcessing(true);

    try {
      // 1. Get search results.
      const response = await api.search({
        fuzziness,
        excludeSanctionSourceId,
        searchTerm,
        groupType,
        sourceType,
        gender,
        birthYear,
        nationality,
      });

      if (response.success) {
        setRawSearchResults(response.body);
        const { results } = mapResultsAndProfiles(response.body);
        const noResults = results.resultsSources.length === 0;
        setnoYieldResults(noResults);
        setSelectedItem(noResults ? null : results.resultsSources[0]);
        if (isMonoColumn) setSelectedItem(null);

        // 2. Update UI with search results immediately.
        updateSearchResults(results);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const runAIAssessment = async () => {
      if (rawSearchResults.length === 0) {
        setAiProcessing(false);
        setIsLoading(false);
        return;
      }

      // Use the helper with current filterSearchParams to obtain variables.
      const {
        searchTerm,
        sourceType,
        groupType,
        nationality,
        birthYear,
        gender,
      } = getSearchParams(filterSearchParams, tenant);

      
      try {
        const aiAgentResponse = await api.ai_search_result_assessment({
          searchTerm,
          groupType,
          sourceType,
          gender,
          birthYear,
          nationality,
          search_results: rawSearchResults,
        });
        if (aiAgentResponse.success) {
        updateSearchResults((prevResults) => ({
          ...prevResults,
          ai_agent_response: aiAgentResponse.body,
        }));
      }
      } catch (error) {
        console.error("AI Assessment Error:", error);
      } finally {
        setAiProcessing(false);
      }
    };
    runAIAssessment();
  }, [rawSearchResults]);

  const handleSelectItem = (item, event) => {
    setSelectedItem(item);
  };

  return (
    <AppLayout
      handleShowHelpToggle={handleShowHelpToggle}
      showHelp={showHelp}
      helpTitle={helpTitle}
      helpContent={helpContent}
    >
      <Row
        gutter={0}
        style={{ display: "flex", flex: "1 1 auto", width: "100%" }}
      >
        {showFirstColumn && (
          <Col xs={24} sm={24} md={16}>
            <div className="column-container-flex-main">
              <div className="column-container-header max-width8">
                <SearchArea
                  filterSearchParams={filterSearchParams}
                  isMobile={isMobile}
                  isLoading={aiProcessing}
                  handleSearch={handleSearch}
                  handlesearchTermChange={handlesearchTermChange}
                  handleShowHelpToggleAddEditUser={
                    handleShowHelpToggleAddEditUser
                  }
                />
              </div>

              <OverFlowLayout
                children={
                  <>
                    {( searchResults?.resultsSources?.length>0) && (
                      <AiAgentSearchResultAssessment
                        aiProcessing={aiProcessing}
                        assessment={searchResults?.ai_agent_response}
                      />
                    )}

                    {noYieldResults ? (
                      <div style={{ textAlign: "center", marginTop: "20px" }}>
                        <h4>No results found</h4>
                      </div>
                    ) : (
                      <div>
                        <MatchProfileCardView
                          resultsSources={searchResults.resultsSources}
                          matchProfiles={searchResults.matchProfiles}
                          searchTerm={filterSearchParams.searchTerm}
                          handleSelectItem={handleSelectItem}
                          selectedItemId={selectedItem ? selectedItem.id : null}
                        />
                      </div>
                    )}
                  </>
                }
              />
            </div>
          </Col>
        )}{" "}
        {showSecondColumn && (
          <ColumnContainer
            colsize={isMonoColumn ? 24 : 8}
            title={truncateString(selectedItem.target.name, 50)}
            showBackButton={
              mode === "add" ? false : mode === "edit" ? false : isMonoColumn
            }
            onBackClick={() => setSelectedItem(null)}
            showDelete={false}
          >
            <OverFlowLayout
              children={<ResultCard item={selectedItem}></ResultCard>}
            ></OverFlowLayout>
          </ColumnContainer>
        )}
      </Row>
    </AppLayout>
  );
};

export default SearchPage;

export const SearchArea = ({
  filterSearchParams,
  isMobile,
  isLoading,
  handleSearch,
  handlesearchTermChange,
  handleShowHelpToggleAddEditUser,
}) => {
  return (
    <div className="search-area">
      <div
        style={{
          display: "flex",
          width: "85%",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InfoCircleOutlined
            onClick={handleShowHelpToggleAddEditUser}
            className="info-icon"
          />
          <SearchInput
            filterSearchParams={filterSearchParams}
            handlesearchTermChange={handlesearchTermChange}
            handleSearch={handleSearch}
            isMobile={isMobile}
            isLoading={isLoading}
          />
        </div>

        <div
          style={{
            marginTop: "3px",
            ...(isMobile
              ? { marginRight: "-60px", marginLeft: "-3px" }
              : { marginRight: "36px", marginLeft: "30px" }),
          }}
        >
          <FilterTags filters={filterSearchParams} />
        </div>
      </div>

      <Button
        type="primary"
        ghost
        size="large"
        icon={<SearchOutlined className="header-icon" />}
        onClick={() => handleSearch()}
        loading={isLoading}
      >
        {isMobile ? "" : "Search"}
      </Button>
    </div>
  );
};

export const SearchInput = ({
  filterSearchParams,
  handlesearchTermChange,
  handleSearch,
  isMobile,
  isLoading,
}) => {
  const triggerSearch = () => {
    handleSearch();
  };
  return (
    <Input
      placeholder="Search for a name or identification such passport, tax id..."
      size="large"
      style={{
        marginRight: "8px",
        marginLeft: "8px",
        width: "100%",
      }}
      value={filterSearchParams.searchTerm}
      onChange={handlesearchTermChange}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSearch();
        }
      }}
      addonAfter={
        <PopoverFilter
          isMobile={isMobile}
          handleSearch={triggerSearch}
          loading={isLoading}
        ></PopoverFilter>
      }
    />
  );
};

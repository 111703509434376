// CombinedCardViews.js
//to replace tale when screen is small

import React from "react";
import { Card, Typography, Button, Skeleton } from "antd";
import { MapNationalityToLabels } from "./CountrySelector";
import {
  formatLongList,
  Capitalize,
  formatSourceType,
  formatDate,
} from "./Utils";
import { CheckTag, ResultStatusTag } from "./Tags";
import { Result } from "../models/CheckResult.js";

import { EditOutlined } from "@ant-design/icons";
import SparkAI from "../images/SparkAI.svg";
const { Text } = Typography;

export const formatYears = (years) => {
  if (!Array.isArray(years) || years.length === 0) {
    return "";
  }

  // Sort the years array numerically
  const sortedYears = years.sort((a, b) => a - b);

  if (sortedYears.length === 1) {
    return sortedYears[0];
  }

  return `${sortedYears[0]} - ${sortedYears[sortedYears.length - 1]}`;
};

export function DisplayProperty({
  label,
  label_width = "120px",
  compact = false,
  value,
  convert_array_to_string = true,
  copyable = false,
  highlight = "",
  classDisplayPropertyValue = "",
}) {
  if (value === "" || value === null || value === undefined) {
    return null;
  }

  // Determine the style for the label
  let labelStyle = {};
  if (label_width !== "auto") {
    const comp_label_width = label_width === "" ? "120px" : label_width;
    labelStyle = {
      width: comp_label_width,
    };
  }

  const highlightText = (text, wordsToHighlight) => {
    if (!wordsToHighlight || typeof text !== "string") return text;

    const wordsArray = wordsToHighlight
      .split(" ")
      .filter((word) => word.trim() !== "");
    if (wordsArray.length === 0) return text;

    const regex = new RegExp(`(${wordsArray.join("|")})`, "gi");

    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  let formattedValue = value;

  // Determine how to format the value based on its type
  if (typeof value === "string") {
    // Single string value
    formattedValue = highlightText(value, highlight);
  } else if (Array.isArray(value)) {
    if (convert_array_to_string) {
      // Convert array of strings to a single string
      formattedValue = value.join(", ");
      formattedValue = highlightText(formattedValue, highlight);
    } else {
      // Render array as a list with optional highlighting
      formattedValue = (
        <ul
          style={{
            padding: 0,
            marginTop: 0,
            marginLeft: 0,
            listStyleType: "none",
          }}
        >
          {value.map((item, index) => (
            <li key={index}>
              {typeof item === "string" ? highlightText(item, highlight) : item}
            </li>
          ))}
        </ul>
      );
    }
  } else {
    // For non-string types (e.g., React elements), render as-is
    formattedValue = value;
  }

  // Conditionally render copyable value
  const displayValue = copyable ? (
    <Text copyable={{ text: typeof value === "string" ? value : "" }}>
      {formattedValue}
    </Text>
  ) : (
    formattedValue
  );

  return (
    <div
      className={
        compact
          ? "display-property-container compact"
          : "display-property-container"
      }
    >
      <div className="display-property-label" style={labelStyle}>
        {label}:{" "}
      </div>
      <div className={`display-property-value ${classDisplayPropertyValue}`}>
        {displayValue}
      </div>
    </div>
  );
}

export const DetailCard = ({
  title,
  onEdit,
  onActionButtonHeaderTitle,
  onActionButtonHeaderIcon,
  onActionButtonHeader,
  onClick,
  onClickHeader,
  properties,
  selectedItemId,
  item,
  loading,
  additionalComponents,
  classCard,
  classHeader,
  classTitle,
  classProps,
  classPropsAdditional,
}) => {
  if (loading) {
    return (
      <div key={item?.id} className={`dir-col  component-main expand-width`}>
        <div className={`component-header`}></div>
        <div className="  card-detail-props side-padding1 top-padding1 ">
          <Skeleton
            avatar
            paragraph={{
              rows: 4,
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div
      key={item?.id}
      onClick={onClick ? (event) => onClick(item, event) : null}
      className={`component-main dir-col ${classCard} ${
        item?.id === selectedItemId ? " card-active" : ""
      }`}
    >
      <div
        className={`component-header ${classHeader}`}
        onClick={onClickHeader ? (event) => onClickHeader(item, event) : null}
      >
        <div className={`card-detail-title grey-text-color ${classTitle}`}>
          {title}

          {onEdit && (
            <Button onClick={onEdit} type="ghost" icon={<EditOutlined />} />
          )}

          {(onActionButtonHeaderTitle || onActionButtonHeaderIcon) && (
            <Button
              onClick={
                onActionButtonHeader
                  ? (event) => onActionButtonHeader(item, event)
                  : null
              }
              ghost={true}
              icon={onActionButtonHeaderIcon}
              type={onActionButtonHeaderTitle ? "primary" : "text"}
            >
              {onActionButtonHeaderTitle}
            </Button>
          )}
        </div>
      </div>
      <div>
        <div className={`card-detail-props card-paddings ${classProps}`}>
          {properties.map(
            (prop, index) =>
              prop.value !== undefined &&
              prop.value !== null && (
                <DisplayProperty
                  key={index}
                  label={prop.label}
                  value={prop.value}
                  copyable={prop.copyable}
                  label_width={prop.label_width}
                  compact={prop.compact}
                  convert_array_to_string={prop.convert_array_to_string}
                  classDisplayPropertyValue={prop.classDisplayPropertyValue}
                />
              )
          )}

          {additionalComponents && (
            <div className={` ${classPropsAdditional}  `}>
              {additionalComponents}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const UserDetailCard = ({ item, onEdit }) => {
  if (!item) {
    return null;
  }
  return (
    <DetailCard
      title="User details"
      onEdit={onEdit}
      selectedItemId={null}
      properties={[
        { label: "User id.", value: item.id, copyable: true },
        { label: "Name", value: item.name },
        { label: "Surname", value: item.surname },
        { label: "Email", value: item.email },
        {
          label: "Created",
          value: formatDate({ date: item.createdAt, showTime: true }),
        },
        {
          label: "Updated",
          value: formatDate({ date: item.updatedAt, showTime: true }),
        },
      ]}
    />
  );
};

// ClientCardView component
export const KeyGeneratedCard = ({ item }) => {
  if (!item) {
    return null;
  }
  return (
    <DetailCard
      title="API key details"
      onEdit={null}
      item={item}
      selectedItemId={null}
      properties={[
        { label: "API key value", value: item.value, copyable: true },
        { label: "API key id.", value: item.id },
        { label: "Name", value: item.name },
        {
          label: "Created",
          value: formatDate({ date: item.created_at, showTime: true }),
        },
      ]}
    />
  );
};

export const KeyCardView = ({ data, selectedItemId, handleSelectItem }) => {
  return (
    <div className="container-cards-customer">
      {data.map((item) => (
        <DetailCard
          title={item.name}
          key={item.id}
          item={item}
          onClick={handleSelectItem}
          selectedItemId={selectedItemId}
          properties={[
            { label: "API key id.", value: item.id, compact: true },
            {
              label: "Created",
              value: formatDate({ date: item.createdAt, showTime: true }),
              compact: true,
            },
          ]}
        />
      ))}
    </div>
  );
};

export const CustomerCardView = ({
  data,
  selectedItemId,
  handleSelectItem,
  loading,
}) => {
  return (
    <div className="container-cards-customer">
      {data.map((item) => (
        <DetailCard
          classCard="card-pointer"
          key={item.id}
          item={item}
          onClick={handleSelectItem}
          title={`${item.name}`}
          onEdit={null}
          loading={loading}
          selectedItemId={selectedItemId}
          properties={[
            { label: "Customer id.", value: item.id, compact: true },
            { label: "Type", value: item.groupType, compact: true },
            { label: "Birth year", value: item.birthYear, compact: true },
            { label: "Gender", value: item.gender, compact: true },
            {
              label: "Nat./Juri.",
              value: MapNationalityToLabels(item.nationality),
              compact: true,
            },
          ]}
        />
      ))}
    </div>
  );
};

// UserCardView component shown instead of table when small
export const UserCardView = ({
  data,
  selectedItemId,
  handleSelectItem,
  loading,
}) => {
  return (
    <div className="container-cards-customer">
      {data.map((item) => (
        <DetailCard
          classCard="card-pointer"
          key={item.id}
          item={item}
          title={`${item.name} ${item.surname}`}
          onClick={handleSelectItem}
          loading={loading}
          selectedItemId={selectedItemId}
          properties={[
            { label: "Email", value: item.email, compact: true },
            {
              label: "Updated",
              value: formatDate({ date: item.updatedAt, showTime: true }),
              compact: true,
            },
          ]}
        />
      ))}
    </div>
  );
};

function DisplayContactInfo({ contactInfo }) {
  return (
    <>
      {contactInfo.website && contactInfo.website.length > 0 && (
        <div className=" red-div-dict">
          <DisplayProperty
            label="Website"
            value={contactInfo.website.join("; ")}
            compact={true}
          />
        </div>
      )}

      {contactInfo.email && contactInfo.email.length > 0 && (
        <div className=" red-div-dict">
          <DisplayProperty
            label="Email"
            value={contactInfo.email.join("; ")}
            compact={true}
          />
        </div>
      )}
      {contactInfo.telephone && contactInfo.telephone.length > 0 && (
        <div className=" red-div-dict">
          <DisplayProperty
            label="Phone"
            value={contactInfo.telephone.join("; ")}
            compact={true}
          />
        </div>
      )}
    </>
  );
}
function DisplayAddress({ Address }) {
  return (
    <>
      {Address?.map((place, index) => (
        <div className="red-div-dict" key={index}>
          {place.country && (
            <DisplayProperty
              label="Country"
              value={MapNationalityToLabels([place.country])}
              compact={true}
            />
          )}
          {place.city && (
            <DisplayProperty label="City" value={place.city} compact={true} />
          )}
          {place.address && (
            <DisplayProperty
              label="Address"
              value={place.address}
              compact={true}
            />
          )}
          {place.province && (
            <DisplayProperty
              label="Province"
              value={place.province}
              compact={true}
            />
          )}
          {place.zip_code && (
            <DisplayProperty
              label="Zip Code"
              value={place.zip_code}
              compact={true}
            />
          )}
          {place.postal_box && (
            <DisplayProperty
              label="Postal Box"
              value={place.postal_box}
              compact={true}
            />
          )}
        </div>
      ))}
    </>
  );
}
function DisplayIdentification({ identification, highlightText }) {
  if (!identification) return null;
  return (
    <>
      {identification?.map((id, index) => (
        <div className="red-div-dict" key={index}>
          {
            <DisplayProperty
              label={Capitalize(id.type ? id.type : "Id")}
              value={id.id}
              compact={true}
              highlight={highlightText}
            />
          }

          <DisplayProperty label="Details" value={id.details} compact={true} />

          <DisplayProperty label="Issuer" value={id.issuer} compact={true} />
          <DisplayProperty
            label="Issue date"
            value={id.issueDate}
            compact={true}
          />
        </div>
      ))}
    </>
  );
}

function DisplayAssociates({ associates }) {
  const groupedAssociates = associates?.reduce((acc, associate) => {
    // Initialize the group if it doesn't exist
    acc[associate.type] = acc[associate.type] || [];
    acc[associate.type].push(associate);
    return acc;
  }, {});

  // Define a function to capitalize the first letter of each word

  return (
    <div>
      {Object.keys(groupedAssociates).map((type) => (
        <div key={type} className="red-div-dict">
          <h6>{Capitalize(type)}</h6>
          <ul>
            {groupedAssociates[type].map((associate, index) => (
              <p key={index}>
                {associate.value}
                {associate.details ? ` ( ${associate.details})` : ""}
              </p>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export const ResultCardView = ({
  data = [],
  selectedItemId,
  handleSelectItem,
  customer,
}) => {
  const results = Array.isArray(data)
    ? data.map((item) => new Result(item, customer))
    : [];

  // Sort the results based on score
  const sortedResults = results.sort((a, b) => b.score - a.score);

  return (
    <div>
      {Array.isArray(data) ? (
        sortedResults.map((result) => (
          <Card
            key={result.id}
            onClick={
              handleSelectItem
                ? (event) => handleSelectItem(result, event)
                : null
            }
            className={`card-detail   card-pointer ${
              result.id === selectedItemId ? "card-active" : ""
            }`}
          >
            <Card.Meta
              description={
                <div className="results-flex-container">
                  <div className="card-results-view-header-container">
                    <div className="card-header-spacing">
                      <div className="card-header-spacing">
                        <CheckTag
                          value={formatSourceType(result.source.sourceType)}
                          tagType="source"
                        />
                        <CheckTag
                          value={formatSourceType(result.source.region)}
                          tagType="source"
                        />
                      </div>
                      <ResultStatusTag
                        allowlisted={result.allowlisted}
                      ></ResultStatusTag>
                    </div>

                    <div className="card-header-spacing">
                      <h4> {result.source.name}</h4>
                    </div>
                  </div>
                  <TargetInfo target={result.target} useFormatYears={true} />
                </div>
              }
            />
          </Card>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h4>No results found.</h4>
        </div>
      )}
    </div>
  );
};

export const MatchProfileCardView = ({
  resultsSources,
  matchProfiles,
  selectedItemId,
  handleSelectItem,
  searchTerm,
  customer = null,
}) => {
  return (
    <div>
      {Array.isArray(matchProfiles) ? (
        matchProfiles.map((matchProfile) => (
          <Card
            key={`matchProfile-${matchProfile.id}`}
            className="profiles-flex-container"
          >
            <Card.Meta
              description={
                <div className="results-flex-container">
                  <MatchProfileCardDetail
                    target={matchProfile}
                    resultsSources={resultsSources}
                    searchTerm={searchTerm}
                    selectedItemId={selectedItemId}
                    handleSelectItem={handleSelectItem}
                    compact={true}
                    customer={customer}
                  />
                </div>
              }
            />
          </Card>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        ></div>
      )}
    </div>
  );
};

export const MatchProfileSources = ({
  resultsSources,
  matchProfileId,
  customer,
  selectedItemId,
  handleSelectItem,
}) => {
  // Filter results matching the input matchProfileId
  let filteredResults = resultsSources;

  if (customer) {
    filteredResults = Array.isArray(resultsSources)
      ? resultsSources.map((item) => new Result(item, customer))
      : [];
  }

  filteredResults = filteredResults.filter(
    (result) => result.matchProfileId === matchProfileId
  );

  return (
    <div>
      <Subproperty
        label="Sources"
        key="sourcesList"
        value=" "
        element={"exist"}
        children={filteredResults.map((result, index) => (
          <div
            key={result.id}
            className={`container-clickable-tags ${
              selectedItemId === result.id ? "color-selected-bg" : ""
            }`}
            style={{ cursor: "pointer" }}
            onClick={
              handleSelectItem
                ? (event) => handleSelectItem(result, event)
                : null
            }
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns:
                  result.allowlisted != null ? "2fr 2fr 7fr" : "1fr 5fr",
                height: "100%",
                alignItems: "center",
              }}
            >
              <ResultStatusTag
                allowlisted={result.allowlisted}
              ></ResultStatusTag>

              <CheckTag
                key={`${result.id} ${result.source.region}`}
                value={`${result.source.region}`}
                tagType="region"
              />

              <CheckTag
                key={`${result.id} ${result.source.name}`}
                value={`${result.source.name}`}
                tagType="source"
              />
            </div>
          </div>
        ))}
      />
    </div>
  );
};

export const TargetInfo = ({ target, useFormatYears = false }) => {
  return (
    <div className="card-result-view-target-info">
      <div className="flex">
        {target?.name && <div className="card-title">{`${target.name} `}</div>}
        {target.searchTerm && (
          <div className="card-title">{`${target.searchTerm} `}</div>
        )}
      </div>

      <div className="card-result-view-target-tags">
        {useFormatYears ? (
          <CheckTag value={formatYears(target.birthYear)} tagType="customer" />
        ) : (
          <CheckTag value={target.birthYear} tagType="customer" />
        )}

        {Array.isArray(target.nationality) &&
          target.nationality.map((nation, index) => (
            <CheckTag
              key={index}
              value={MapNationalityToLabels([nation])}
              tagType="customer"
            />
          ))}

        <CheckTag value={formatSourceType(target.gender)} tagType="customer" />

        {!target.gender && (
          <CheckTag
            value={formatSourceType(target.groupType)}
            tagType="customer"
          />
        )}
      </div>
    </div>
  );
};

export const ResultCard = ({ item, show = true }) => {
  if (!item || !item.id || !show) {
    return null; // Renders nothing if there is no data
  }
  return (
    <>
      <EntitySource item={item.source} id={item.id} />

      <EntityCase data={item.case} id={item.id} />
      <TargetCardDetail target={item.target} id={item.id} />
      <EntityVessel data={item.vessel} id={item.id} />

      <EntityTargetId item={item} id={item.id} />
    </>
  );
};

export const EntityVessel = ({ data }) => {
  if (!data || data.length === 0) {
    return null; // Renders nothing if there is no data
  }
  const properties = [
    { label: "Type", value: data.type },
    { label: "Flag", value: data.flag },
    { label: "IMO", value: data.IMO },
    { label: "MMSI", value: data.MMSI },
    { label: "Call Sign", value: data.callSign },
    { label: "Gross Registered Tonnage", value: data.grossRegisteredTonnage },
    { label: "Gross Tonnage", value: data.grossTonnage },
    { label: "Lenght", value: data.lenght },
    { label: "Former flag", value: data.formerFlag },

    { label: "Year built", value: data.yearBuilt },

    { label: "Owner", value: data.owner },
  ];

  return (
    <DetailCard title="Vessel" properties={properties} selectedItemId={null} />
  );
};

export const EntityCase = ({ data, id }) => {
  if (!data || data.length === 0) {
    return null; // Renders nothing if there is no data
  }

  const properties = data
    .map((item) => [
      { label: "Program", value: item.program },
      { label: "Sanction type", value: item.sanctionType },
      { label: "Basis", value: item.basis },
      { label: "Designation act", value: item.designationAct },
      { label: "Url", value: item.url },
      { label: "Updated on", value: item.updatedOn },
      { label: "Since", value: item.since },
      { label: "Designation date", value: item.designationDate },
      { label: "To", value: item.to },
      { label: "Other ids", value: item.otherIds },
    ])
    .flat(); // Flatten the array of arrays

  return (
    <DetailCard title="Case" properties={properties} selectedItemId={null} />
  );
};

export const Subproperty = ({
  label,
  children,
  element,
  value,
  label_width,
  compact = true,
}) => {
  if (element === null || (Array.isArray(element) && element.length === 0)) {
    return null;
  }

  return (
    <div className="card-detail-subproperty">
      <DisplayProperty
        label={label}
        value={value}
        label_width={label_width}
        compact={compact}
      />
      {children}
    </div>
  );
};

export const MatchProfileCardDetail = ({
  target,
  resultsSources,
  handleSelectItem,
  selectedItemId,
  compact = false,
  searchTerm = "",
  customer = null,
}) => {
  if (!target) {
    return null; // Renders nothing if there is no target data
  }

  // Prepare properties array
  const properties = [
    {
      label: "Name",
      value: target.name,
      compact: compact,
      highlight: searchTerm,
    },
    { label: "First Name", value: target.firstName, compact: compact },
    { label: "Second Name", value: target.secondName, compact: compact },
    { label: "Third Name", value: target.thirdName, compact: compact },
    { label: "Title", value: formatLongList(target.title), compact: compact },
    {
      label: "Nat./Juri.",
      value: MapNationalityToLabels(target.nationality),
      compact: compact,
    },
    { label: "Birth Year", value: target.birthYear, compact: compact },
    { label: "Birth Date", value: target.birthDate, compact: compact },
    { label: "Gender", value: Capitalize(target.gender), compact: compact },
    {
      label: "Alias",
      value: formatLongList(target.otherNames, 100, 256, 2000, false),
      compact: compact,
      highlight: searchTerm,
    },
    {
      label: "Function",
      value: formatLongList(target.function, 100, 256, 2000, false),
      convert_array_to_string: false,
      compact: compact,
    },
    {
      label: "Group Type",
      value: Capitalize(target.groupType),
      compact: compact,
    },
    {
      label: "Deceased",
      value: target.isDeceased ? "Yes" : null,
      compact: compact,
    },
  ];

  return (
    <div className="container-match-profile">
      <Subproperty
        label={`Profile-${target.id}`}
        key={`Profile-${target.id}`}
        value=" "
        children={properties.map(
          (prop, index) =>
            prop.value !== undefined &&
            prop.value !== null && (
              <div
                className="red-div-dict-side-paddings"
                key={`property-${index}-${target.id}`}
              >
                <DisplayProperty
                  label={prop.label}
                  value={prop.value}
                  copyable={prop.copyable}
                  label_width={prop.label_width}
                  compact={prop.compact}
                  convert_array_to_string={prop.convert_array_to_string}
                  highlight={prop.highlight}
                />
              </div>
            )
        )}
      ></Subproperty>
      <MatchProfileSources
        matchProfileId={target.id}
        handleSelectItem={handleSelectItem}
        selectedItemId={selectedItemId}
        resultsSources={resultsSources}
        customer={customer}
      />

      <Subproperty
        label="Identification"
        key={`identification-${target.id}`}
        value=" "
        element={target.identification}
        children={
          <DisplayIdentification
            identification={target.identification}
            highlightText={searchTerm}
          />
        }
      ></Subproperty>

      <Subproperty
        label="Birth place"
        key={`birthplace-${target.id}`}
        value=" "
        element={target.birthPlace}
        children={<DisplayAddress Address={target.birthPlace} />}
      ></Subproperty>

      <Subproperty
        label="Address"
        key={`address-${target.id}`}
        value=" "
        element={target.address}
        children={<DisplayAddress Address={target.address} />}
      ></Subproperty>

      <Subproperty
        label="Associates"
        key={`associates-${target.id}`}
        value=" "
        element={target.associates}
        children={<DisplayAssociates associates={target.associates} />}
      ></Subproperty>

      <Subproperty
        label="Contact information"
        key={`contactInformation-${target.id}`}
        value=" "
        element={target.contactInformation}
        children={
          <DisplayContactInfo contactInfo={target.contactInformation} />
        }
      ></Subproperty>

      <Subproperty
        label="Remark"
        key={`remark-${target.id}`}
        value={formatLongList(target.remarks, 100, 700, 3000, false)}
        compact={false}
        element={target.remarks}
        children={""}
      ></Subproperty>
    </div>
  );
};

export const TargetCardDetail = ({
  target,
  id,
  onClick,
  selectedItemId,
  compact = false,
}) => {
  if (!target) {
    return null; // Renders nothing if there is no target data
  }

  // Prepare properties array
  const properties = [
    { label: "Name", value: target.name, compact: compact },
    { label: "First Name", value: target.firstName, compact: compact },
    { label: "Second Name", value: target.secondName, compact: compact },
    { label: "Third Name", value: target.thirdName, compact: compact },
    { label: "Title", value: formatLongList(target.title), compact: compact },
    {
      label: "Nat./Juri.",
      value: MapNationalityToLabels(target.nationality),
      compact: compact,
    },
    { label: "Birth Year", value: target.birthYear, compact: compact },
    { label: "Birth Date", value: target.birthDate, compact: compact },
    { label: "Gender", value: Capitalize(target.gender), compact: compact },
    {
      label: "Alias",
      value: formatLongList(target.otherNames, 100, 256, 2000, false),
      compact: compact,
    },
    {
      label: "Function",
      value: formatLongList(target.function, 100, 256, 2000, false),
      convert_array_to_string: false,
      compact: compact,
    },
    {
      label: "Group Type",
      value: Capitalize(target.groupType),
      compact: compact,
    },
    {
      label: "Deceased",
      value: target.isDeceased ? "Yes" : null,
      compact: compact,
    },
  ];

  const additionalComponents = [
    <div>
      <Subproperty
        label="Identification"
        key={`identification-${target.id}`}
        value=" "
        element={target.identification}
        children={
          <DisplayIdentification identification={target.identification} />
        }
      ></Subproperty>

      <Subproperty
        label="Birth place"
        key={`birthplace-${target.id}`}
        value=" "
        element={target.birthPlace}
        children={<DisplayAddress Address={target.birthPlace} />}
      ></Subproperty>

      <Subproperty
        label="Address"
        key={`address-${target.id}`}
        value=" "
        element={target.address}
        children={<DisplayAddress Address={target.address} />}
      ></Subproperty>

      <Subproperty
        label="Associates"
        key={`associates-${target.id}`}
        value=" "
        element={target.associates}
        children={<DisplayAssociates associates={target.associates} />}
      ></Subproperty>

      <Subproperty
        label="Contact information"
        key={`contactInformation-${target.id}`}
        value=" "
        element={target.contactInformation}
        children={
          <DisplayContactInfo contactInfo={target.contactInformation} />
        }
      ></Subproperty>

      <Subproperty
        label="Remark"
        key={`remark-${target.id}`}
        value={formatLongList(target.remarks, 100, 700, 3000, false)}
        compact={false}
        element={target.remarks}
        children={""}
      ></Subproperty>
    </div>,
  ].filter(Boolean); // Filter out null values

  return (
    <DetailCard
      title="Target"
      properties={properties}
      additionalComponents={additionalComponents}
      item={{ id }}
      onClick={onClick}
      selectedItemId={selectedItemId}
    />
  );
};

export const EntitySource = ({ item, id }) => {
  return (
    <DetailCard
      title="Source"
      properties={[
        {
          label: "Name",
          value: item.name,
          classDisplayPropertyValue: "boldText",
        },
        {
          label: "Region",
          value: item.region,
          classDisplayPropertyValue: "boldText",
        },
        {
          label: "Type",
          value: item.sourceType,
          classDisplayPropertyValue: "boldText",
        },

        { label: "Listing id.", value: item.listingId },
      ]}
      item={{ id }}
      selectedItemId={null}
    />
  );
};
export const EntityTargetId = ({ item, id }) => {
  return (
    <DetailCard
      title="Checklynx info"
      properties={[
        { label: "Checklynx id.", value: item.id },
        { label: "Score", value: item.score },
      ]}
      item={{ id }}
      selectedItemId={null}
    />
  );
};

export const AiAgentSearchResultAssessment = ({ assessment, aiProcessing }) => {
  return (
    <div className="center-container">
      {aiProcessing ? (
        <div className="card-detail-agent card-detail-agent-processing">
          <img src={SparkAI} alt="spark AI" className="card-delivery-icon" />
        </div>
      ) : (
        <div className="card-detail-agent card-detail-agent-vigilant">
          <h4>{assessment}</h4>
        </div>
      )}
    </div>
  );
};

//.component/AppMenu.jsx

import React from "react";
import { Menu, Switch } from "antd";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun,faMoon } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";  


import {
  SearchOutlined,
  TeamOutlined,
  CodeOutlined,
  AuditOutlined,
  ControlOutlined 
 
} from "@ant-design/icons";

import { useTheme } from '../contexts/ThemeContext';



const menuStyle = { height: "100%", backgroundColor: "var(--menu-backround)"};

const AppMenu = () => {
  const location = useLocation();
  const { isDarkMode, toggleTheme } = useTheme();


  // Function to determine the key based on the current location
  const getMenuKeyFromPath = (pathname) => {
    if (pathname.startsWith("/search")) {
      return "1"; // Highlight "Customers" for both /customers and /customers/:customerId
    }
    if (pathname.startsWith("/customers")) {
      return "2"; // Highlight "Customers" for both /customers and /customers/:customerId
    }
    if (pathname.startsWith("/cases")) {
      return "3"; // Highlight "Customers" for both /customers and /customers/:customerId
    }
    if (pathname.startsWith("/users")) {
      return "4"; // Highlight "Customers" for both /customers and /customers/:customerId
    }
    if (pathname.startsWith("/developers")) {
      return "5"; // Highlight "Customers" for both /customers and /customers/:customerId
    }
    if (pathname.startsWith("/settings")) {
      return "6"; // Highlight "Customers" for both /customers and /customers/:customerId
    }
    else{
      return 1}

  };

  const selectedKey = getMenuKeyFromPath(location.pathname);
  // Define the menu items
  const menuItems = [
    {
      key: "1",
      icon: <SearchOutlined className="app-menu-icon" />,
      label: <Link to="/search" className="app-menu-item">Search</Link>,
    },
    {
      key: "2",
      icon: <FontAwesomeIcon icon={ faAddressCard} className="app-menu-icon" />,
      label: <Link to="/customers" className="app-menu-item">Customers</Link>,
    },
    {
      key: "3",
      icon: <AuditOutlined className="app-menu-icon" />,
      label: <Link to="/cases" className="app-menu-item">Cases</Link>,
    },
    {
      key: "4",
      icon: <TeamOutlined className="app-menu-icon" />,
      label: <Link to="/users" className="app-menu-item">Users</Link>,
    },
    {
      key: "5",
      icon: <CodeOutlined className="app-menu-icon" />,
      label: <Link to="/developers" className="app-menu-item">Developers</Link>,
    },
    {
      key: "6",
      icon: <ControlOutlined className="app-menu-icon" />,
      label: <Link to="/settings" className="app-menu-item">Settings</Link>,
    },
  
  ];
  return (
    <div style={{ position: "relative", height: "85%" }}>
      <Menu
        mode="inline"
        selectedKeys={[selectedKey]}
        items={menuItems} 
        style={menuStyle}
      >
      </Menu>
      <div
        style={{
          height: "10%",
          width: "100%",
          marginTop: "20px",
          textAlign: "center",
        }}
      >
         
        {isDarkMode ? (
          <span className="span-icon">
          <Switch
          
          checked={isDarkMode}
          onChange={toggleTheme}
          checkedChildren={<FontAwesomeIcon icon={faSun} />}
          unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
        />
        </span>
          
        ) : (
          <span className="span-icon">
          <Switch
            
            checked={isDarkMode}
            onChange={toggleTheme}
            checkedChildren={<FontAwesomeIcon icon={faSun} />}
            unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
          />
          </span>
        )}
      </div>
    </div>
  );
};

export default AppMenu;

import React, { useState, useEffect } from "react";
import { Checkbox, Button, Input, Tooltip, message } from "antd";
import './RegionSourceSelector.css';
import {
  CopyOutlined
 
} from "@ant-design/icons";


const RegionSourceSelector = ({ sourceData, onSelectionChange, excludedSourceIds = [] }) => {
  const [selectedSources, setSelectedSources] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [allSelected, setAllSelected] = useState(false);

  // Initialize selection based on sourceData and excludedSourceIds
  const initializeSelection = () => {
    const selectionState = {};
    Object.keys(sourceData).forEach((region) => {
      selectionState[region] = {
        isRegionSelected: false,
        sources: Object.keys(sourceData[region]).reduce((acc, sourceId) => {
          acc[sourceId] = !excludedSourceIds.includes(sourceId);
          return acc;
        }, {}),
      };

      const sources = selectionState[region].sources;
      selectionState[region].isRegionSelected = Object.values(sources).every((selected) => selected);
      selectionState[region].isRegionIndeterminate = Object.values(sources).some((selected) => selected) && !selectionState[region].isRegionSelected;
    });
    setSelectedSources(selectionState);
  };

  // Handle region selection
  const handleRegionSelect = (region) => {
    const updatedSources = { ...selectedSources };
    const regionState = !updatedSources[region].isRegionSelected;
    updatedSources[region].isRegionSelected = regionState;

    Object.keys(updatedSources[region].sources).forEach((sourceId) => {
      updatedSources[region].sources[sourceId] = regionState;
    });

    setSelectedSources(updatedSources);
    triggerExcludedSourcesUpdate(updatedSources);
  };

  // Handle individual source selection
  const handleSourceSelect = (region, sourceId) => {
    const updatedSources = { ...selectedSources };
    updatedSources[region].sources[sourceId] = !updatedSources[region].sources[sourceId];

    const allSourcesSelected = Object.values(updatedSources[region].sources).every((val) => val === true);
    const anySourceSelected = Object.values(updatedSources[region].sources).some((val) => val === true);

    updatedSources[region].isRegionSelected = allSourcesSelected;
    updatedSources[region].isRegionIndeterminate = anySourceSelected && !allSourcesSelected;

    setSelectedSources(updatedSources);
    triggerExcludedSourcesUpdate(updatedSources);
  };

  // Update search term
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  // Filter regions and sources based on the search term
  const filteredData = Object.keys(sourceData).reduce((acc, region) => {
    const filteredSources = Object.entries(sourceData[region])
      .filter(([sourceId, sourceName]) => sourceName.toLowerCase().includes(searchTerm));

    if (filteredSources.length > 0 || region.toLowerCase().includes(searchTerm)) {
      acc[region] = Object.fromEntries(filteredSources);
    }

    return acc;
  }, {});

  // Select all sources across all regions
  const selectAll = () => {
    const updatedSources = { ...selectedSources };
    Object.keys(updatedSources).forEach((region) => {
      updatedSources[region].isRegionSelected = true;
      Object.keys(updatedSources[region].sources).forEach((sourceId) => {
        updatedSources[region].sources[sourceId] = true;
      });
    });
    setSelectedSources(updatedSources);
    setAllSelected(true);
    triggerExcludedSourcesUpdate(updatedSources);
  };

  // Deselect all sources across all regions
  const deselectAll = () => {
    const updatedSources = { ...selectedSources };
    Object.keys(updatedSources).forEach((region) => {
      updatedSources[region].isRegionSelected = false;
      Object.keys(updatedSources[region].sources).forEach((sourceId) => {
        updatedSources[region].sources[sourceId] = false;
      });
    });
    setSelectedSources(updatedSources);
    setAllSelected(false);
    triggerExcludedSourcesUpdate(updatedSources);
  };

  // Toggle select all / deselect all
  const handleToggleAll = () => {
    if (allSelected) {
      deselectAll();
    } else {
      selectAll();
    }
  };

  // Trigger onSelectionChange with excluded sources only
  const triggerExcludedSourcesUpdate = (updatedSources) => {
    const excludedSources = [];
    Object.keys(updatedSources).forEach((region) => {
      Object.keys(updatedSources[region].sources).forEach((sourceId) => {
        if (!updatedSources[region].sources[sourceId]) {
          excludedSources.push(sourceId);
        }
      });
    });
    onSelectionChange(excludedSources);
  };

  // Handle copying sourceId
  const handleCopy = (sourceId) => {
    navigator.clipboard.writeText(sourceId).then(() => {
      message.success("Source ID copied to clipboard!");
    }).catch(() => {
      message.error("Failed to copy Source ID.");
    });
  };

  // Initialize state on component mount
  useEffect(() => {
    initializeSelection();
  }, []);

  return (
    <div className="region-source-selector">
      <Input placeholder="Search regions or sources" onChange={handleSearchChange} value={searchTerm} style={{ marginBottom: '1rem' }} />

      <div style={{ marginTop: "0rem", marginBottom: '2rem' }}>
        <Button onClick={handleToggleAll} type="primary" ghost>
          {allSelected ? 'Deselect All' : 'Select All'}
        </Button>
      </div>

      <div className="region-list">
        {Object.keys(filteredData).map((region) => (
          <div key={region} className="region-section">
            <Checkbox
              indeterminate={selectedSources[region]?.isRegionIndeterminate}
              checked={selectedSources[region]?.isRegionSelected}
              onChange={() => handleRegionSelect(region)}
            >
              {region}
            </Checkbox>

            <div className="source-list">
              {Object.entries(filteredData[region]).map(([sourceId, sourceName]) => (
                <div key={sourceId} style={{ display: 'flex', alignItems:"center", marginLeft: '1.5rem' }}>
                  <Checkbox
                    checked={selectedSources[region]?.sources[sourceId]}
                    onChange={() => handleSourceSelect(region, sourceId)}
                  >
                    {sourceName}
                   
                  </Checkbox>
                  <Tooltip title="Copy Source Id.">
                  <CopyOutlined  size="small"
                      onClick={() => handleCopy(sourceId)}
                      className="link_text_blue b-margin1 "></CopyOutlined>
                   
                  </Tooltip>
                  
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RegionSourceSelector;

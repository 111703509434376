
import { useState, useEffect } from "react";
import { notification } from 'antd';
import { useCallback, useRef } from "react";



export const truncateString = (str, num) => {
    console.log('str', str);
    if (str && str.length > num) {
      return str.slice(0, num) + '...';
    } else {
      return str;
    }
  };


  export const formatLongList = (items, limitItems = 4, maxItemLength = 65, maxTotalLength = 120, mustJoin = false) => {
    if (!items || items.length === 0) return null;
  
    // First, limit the number of items based on limitItems
    const itemsToConsider = items.slice(0, limitItems);
  
    // Limit the length of each item
    const limitedLengthItems = itemsToConsider.map((item) =>
      item.length > maxItemLength ? `${item.substring(0, maxItemLength - 3)}...` : item
    );
  
    let displayItems = limitedLengthItems;
  
    if (mustJoin) {
      // Determine how many items to include based on the limitItems variable
      displayItems = limitedLengthItems.join(", ");
  
      // Append '...' if the original list was longer than limitItems
      if (items.length > limitItems) {
        displayItems += '...';
      }
  
      // Limit the total length of the string if joined
      if (displayItems.length > maxTotalLength) {
        displayItems = `${displayItems.substring(0, maxTotalLength - 3)}...`;
      }
    } else {
      // When not joining, consider limiting the total length based on individual items, if necessary
      // This part remains for further customization based on how you want to handle non-joined lists
    }
  
    return displayItems;
  };
  



  export const usePasswordValidation = ({ initialPassword = "" }) => {
    const [password, setPassword] = useState(initialPassword);
    const [passwordRequirements, setPasswordRequirements] = useState([
      { regex: /.{8,}/, label: "8 characters or more", satisfied: false },
      { regex: /[a-z]/, label: "1 lower case letter", satisfied: false },
      { regex: /[A-Z]/, label: "1 upper case letter", satisfied: false },
      { regex: /\d/, label: "1 number", satisfied: false },
    ]);
  
    useEffect(() => {
      setPasswordRequirements(passwordRequirements.map(req => ({
        ...req,
        satisfied: req.regex.test(password)
      })));
    }, [password]);
  
    return { password, setPassword, passwordRequirements };
  };
  
  export const showNotification = (type, message, description,duration=3) => {
    notification[type]({
      placement: "bottomRight",
      duration: duration,      
      message: message,
      description: description,
    });
  };
  

  export const areObjectsEqualForPatch = (originalData, newCustomerData) => {
  

    for (let key in newCustomerData) {
        if (newCustomerData[key] === null || newCustomerData[key] === "") {
            continue;
        }

        if (key in originalData) {
            const originalValue = originalData[key];

           // if (originalValue === null || originalValue === "") {
           //     continue;
            //}

            if (originalValue !== newCustomerData[key]) {
                return false;
            }
        }
    }
    return true;
};




export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const Capitalize = (str = "") => {
  if (typeof str !== "string") {
    // Optionally handle the case where str is not a string
    return ""; // or throw new Error("Input must be a string");
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatSourceType = (sourceType) => {
  if (!sourceType) {
    return ""; // Or handle this case as needed
  }
  if (sourceType === "pep") {
    return sourceType.toUpperCase();
  } else if (sourceType === "sanctions") {
    return Capitalize(sourceType);
  }

  return sourceType
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function formatDate({ date, showTime = false }) {
  if (!date) return ""; // Handle cases where the date might be null or undefined

  const validDate = new Date(date);
  if (isNaN(validDate)) return ""; // Check if it's a valid date
 

  const options = {
    
    year: "numeric", // '2024'
    month: "numeric", // 'Aug'
    day: "numeric", // '22'
  };

  if (showTime) {
    options.hour = "numeric";
    options.minute = "numeric";
   
  }

  return validDate.toLocaleString("en-UK", options);
}


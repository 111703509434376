import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const CustomBreadcrumb = ({ items }) => (
  <Breadcrumb className='breadcrumb'>
    {items.map(item => (
      <Breadcrumb.Item key={item.key}>
        {item.href ? <Link to={item.href}>{item.title}</Link> : item.title}
      </Breadcrumb.Item>
    ))}
  </Breadcrumb>
);

export default CustomBreadcrumb;
import { api } from "../API/API.js";

import { useAuth } from "../logic/useAuth";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../contexts/authContext.js"; // Adjust the import path as needed
import { useContext } from "react";

import { v4 as uuidv4 } from "uuid"; // Importing the UUID function



export const useIncommingUser = () => {
  const {
    userLogIn,
    confirmSignInEnforceNewPassword,
    handleConfirmResetPassword,
    userLogOut,
    handleSignUpConfirmation, handleAutoSignIn, handleSignUp } = useAuth();
 
  const {  user ,tenantInfo,idToken,setIdToken} =
    useContext(AuthContext);
  const navigate = useNavigate();
  const OnboardingCreateTenant = async (reg_token) => {
    try {
      // Update steps on successful tenant creation
      console.log("Creating tenant resources");
      const data={
        "x-amzn-marketplace-token":reg_token
      }
      const tenantCreationResult = await api.createTenant(data);
      console.log("Tenant creation response:", tenantCreationResult);
  
      if(reg_token){
      if (!tenantCreationResult.success) {
        console.log("Failed to create tenant");
        const error=tenantCreationResult.body.code;
       
        console.log("redirect to onboardingsuccess with error",error)
        navigate("/onboardingsuccess?error="+error);
        return
      }
      navigate("/onboardingsuccess");
      return
    }
    if(!reg_token){ //none aws customer 
      navigate("/billingmethod", { replace: true });
      return
    }
     
    } catch (error) {
      
      console.error("Error creating tenant:", error);
      return { success: false, error };
    }
  };
  const completeLoggin = async (email, password,regToken) => {
    try {
      console.log("current user is", user);
      if (user &!regToken) {
        console.log("User is already signed in");
        navigate("/search");
        return { success: true };
      }

    
      if (!idToken || !user) {
        await userLogOut()
        const result = await userLogIn(email, password);
        console.log("completeLoggin userLogIn result", result);
        if (!result.success) {
            return { success: false, error: result.error };
          }
        
       if (result.signInResponse){
        switch (result.signInResponse.nextStep.signInStep) {
          case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
            navigate("/enforcenewpassword", { state: { email } });
            return { success: true };
          case "CONFIRM_SIGN_UP":
            navigate("/verifyemail", { state: { email,password,regToken } });
            return { success: true };
          case "RESET_PASSWORD":
            navigate("/passwordreset", { state: { email } });
            return { success: true };
          default:
            //no action
        }
      } }

      console.log("tenantInfo.found", tenantInfo.found);
      console.log("regToken", regToken);
      if (!tenantInfo.found && regToken){
        OnboardingCreateTenant(regToken)
      }



      console.log("tenantInfo.subscription_active", tenantInfo.subscription_active);
      if (!tenantInfo.subscription_active && tenantInfo.found)
        {
        navigate("/billingmethod", { replace: true });
        return { success: true };
      }

      navigate("/search", { replace: false });
      return { success: true };
    } catch (error) {
      console.error("Error during login:", error);
      return { success: false, error };
    }
  };

  const completeOnboarding = async (email, verificationCode,password,regToken) => {
    try {
      // Attempt to confirm the sign-up with verify email
        
      if(!email ){
        navigate("/login", { replace: true });
        return { success: false, error: "email is required" };
      }
      const result = await handleSignUpConfirmation(
        email,
        verificationCode
      );
      console.log("handleSignUpConfirmation result:", result);
      if(!result.success){
        console.log("Failed to confirm sign-up:", result.error);
        return { success: false, error: result.error };
      }

       // Handle auto sign-in
       
        const resultLogIn = await userLogIn(email, password);
        console.log("completeOnboarding resultLogIn:", resultLogIn);
            if (!resultLogIn.success) {
                    if (resultLogIn.error === "UserAlreadyAuthenticatedException") {
                    console.log("user already logged in")
                    }
                    else{
                    console.log("Failed to log in:", resultLogIn.error);
                    return { success: false, error: resultLogIn.error };
                    }
      }
      
     
      const tenantCreationResult = await OnboardingCreateTenant(regToken);
      console.log("completeOnboarding tenantCreationResult",tenantCreationResult);
      return
     
    } catch (error) {
      console.error("Error during onboarding:", error);
      return { success: false, error:"FailedCreateTenant" };
    }

    
    
   
  };
  const signUp = async (email, password, name, surname, company_name,regToken) => {

    await userLogOut()//important to avoid conflicts if there is a user logged

    const tenant_id = uuidv4().replace(/-/g, '').slice(0, 10);
    const user_id = uuidv4().slice(0, 8);
    const res = await handleSignUp(
      email,
      password,
      name,
      surname,
      company_name,
      tenant_id,
      user_id
    );
    
      
      
    if (!res.success) {
      console.log("Failed to sign up:", res.error);
      if (res.error==="UsernameExistsException"){

            completeLoggin(email, password,regToken)
      }
        
      else{

        return { success: false, error:res.error };
      }
      }
    
    navigate("/verifyemail", { replace: false, state: { email: email, password:password ,regToken:regToken} });
    
  };

  

  const enforceNewPassword = async (email, password) => {
    const res = await confirmSignInEnforceNewPassword(password);
    if (!res.success) {
      console.error("Failed update new password:", res.error);
      return { success: false };
    } else {
    }
    completeLoggin(email, password);
  };

  const confirmResetPassword = async (email, verificationCode, newPassword) => {
    const res = await handleConfirmResetPassword(
      email,
      verificationCode,
      newPassword
    );
    if (!res.success) {
      console.error("Failed update new password:", res.error);
      return { success: false, error: res.error };
    } else {
    }
    const password = newPassword;
    await completeLoggin(email, password);
    console.log("redirect to app");

    return { success: true };
  };
  

  return {
    completeLoggin,
    enforceNewPassword,
    confirmResetPassword,
    completeOnboarding,
    signUp
  };
};


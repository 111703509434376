import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Form, Input, Button } from "antd";
import AppLayoutLogin from "../components/AppLayoutLogin";

import { useAuth } from "../logic/useAuth";
import { useIncommingUser } from "../logic/onboarding";

const LogInPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loginButtonLoading, setLoginButtonLoading] = useState(false);
  const { user, isLoading } = useAuth();
  const { completeLoggin } = useIncommingUser(); // Destructure the function you need

  //
  // useEffect(() => {
  //  if (!isLoading && user) {
  //    navigate("/search");
  // }
  // if (!isLoading && !user) {  navigate("/login"); }
  //}, [user, isLoading, navigate]);

  const handleLogin = async () => {
    try {
      setLoginButtonLoading(true);
      const values = await form.validateFields();
      const { email, password } = values;
      const result_loggin = await completeLoggin(email, password);

      if (result_loggin.success) {
        console.log("result userLogIn", result_loggin);
      } else {
        setLoginButtonLoading(false);
        if (result_loggin.error === "UserAlreadyAuthenticatedException") {
          //completeLoggin takes care
        } else if (result_loggin.error === "NotAuthorizedException") {
          form.setFields([
            {
              name: "email",
              errors: ["Incorrect email or password"],
            },
          ]);
        } else if (result_loggin.error === "UserNotFoundException") {
          form.setFields([
            {
              name: "email",
              errors: ["Incorrect user email"],
            },
          ]);
        }
      }
    } catch (err) {
      console.error("handleLogin in loginpage failed:", err);
      setLoginButtonLoading(false);
    }
  };

  const handleRequestPassword = () => {
    const email = form.getFieldValue("email");
    navigate("/forgotpassword", { state: { email } });
  };

  const gotoSignup = () => {
    navigate("/signup");
  };

  return (
    <AppLayoutLogin forceBrightMode={false}>
      <div style={{ height: "3rem", flexShrink: 1 }}></div>

      <div className="onboarding-containers">
        <h1>Log in to your account</h1>
        <h5>
          Want an account? <a href="/signup">Sign up</a>{" "}
        </h5>
        <div style={{ height: "1rem", flexShrink: 1 }}></div>

        <Form
          form={form}
          name="sign_in_form"
          autoComplete="on"
          layout="vertical"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              placeholder="Email"
              className="input-big"
              autoComplete="email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please input your password!" },
              () => ({}),
            ]}
          >
            <Input.Password
              placeholder="Password"
              className="input-big"
              autoComplete="current-password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={handleLogin}
              className="input-big"
              type="primary"
              ghost={true}
              loading={loginButtonLoading}
            >
              Log in
            </Button>
            <Button
              type="link"
              onClick={handleRequestPassword}
              style={{ padding: 0, height: "auto", lineHeight: "inherit" }}
            >
              <div style={{ height: "0.5rem", flexShrink: 1 }}></div>

              <h5 className="link_text_blue">Forgot password?</h5>
            </Button>
            

          </Form.Item>
        </Form>
      </div>
    </AppLayoutLogin>
  );
};

export default LogInPage;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Override console methods in production
//if (process.env.NODE_ENV === 'production') {
//  console.log = console.info = console.warn = console.debug = console.error = () => {};
//}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();

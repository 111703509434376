//user changes password using the code sent per email
//enforcenewpassword for new user who had temporary password
import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useLocation } from "react-router-dom"; // Import useHistory


import AppLayoutLogin from "../components/AppLayoutLogin";
import PasswordField from "../components/PasswordField";

import {useIncommingUser} from "../logic/onboarding"

const UserNewPasswordPage = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const email = location.state?.email || ""; // Get email from state, with fallback

  const { enforceNewPassword } = useIncommingUser();  // Destructure the function you need


  useEffect(() => {
    if (email) {
      form.setFieldsValue({ email });
    }
  }, [email, form]);


  const onSubmit = async (values) => {
    setIsLoading(true);
    const password = values.password;
    await enforceNewPassword(email,password)

   
    setIsLoading(false);
  };

  const [formIsValid, setFormIsValid] = useState(false);

  const onFieldsChange = (_, allFields) => {
    const allFieldsValid = allFields.every((field) => {
      const errors = form.getFieldError(field.name);
      const valueIsNotEmpty = field.validated;
      return errors.length === 0 && valueIsNotEmpty;
    });
    setFormIsValid(allFieldsValid);
  };

  return (
    <AppLayoutLogin>
      <div style={{ height: "3rem", flexShrink: 1 }}></div>
      <div className="onboarding-containers">
        <h2>Change password</h2>
        <h3>Choose your new password</h3>
        <div style={{ height: "2rem", flexShrink: 1 }}></div>

        <Form
          form={form}
          name="change_password"
          layout="vertical"
          onFinish={onSubmit}
          onFieldsChange={onFieldsChange}
        >
           <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your Email!",
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input
              placeholder="Email"
              className="input-big"
              autoComplete="email"
            />
          </Form.Item>
          
          <Form.Item>
                <PasswordField form={form} />
              </Form.Item>
          <Button
            disabled={!formIsValid}
            htmlType="submit"
            type="primary"
            ghost={true}
            loading={isLoading}
           
            className="input-big"
          >
            Change Password
          </Button>
        </Form>
      </div>
    </AppLayoutLogin>
  );
};

export default UserNewPasswordPage;

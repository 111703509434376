///billingmethod
import React, { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form,Divider } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import AppLayoutLogin from '../components/AppLayoutLogin.jsx';
import OnboardingStepper from '../components/OnboardingStepper.jsx';
import { useNavigate } from "react-router-dom";

import { useContext } from 'react';
import {AuthContext } from '../contexts/authContext.js'; // Adjust the import path as needed

import AWScloud from "../images/AWScloud.svg"
import con from "../config.json"



const MethodSelector = ({ className, selectedOption, selectOption, continueDisabled,continueClicked  }) => {

  

  return (
    <div className={className}>
      <h2>Choose your billing integration method:</h2>
      <div style={{ height: "1rem", flexShrink: 1 }}></div>

      <Form layout="vertical">
        {/* Stripe option */}
        <Form.Item>
          <div
            className={`card-delivery-method ${
              selectedOption === "stripe" ? "card-delivery-method-selected" : "card-delivery-method-unselected"
            }`}
            onClick={() => selectOption("stripe")}
          >
            <div className="card-delivery-method-header">
              <h2 className="text-dark">Direct billing</h2>
              <h1 >
                <CreditCardOutlined className="card-delivery-icon" aria-label="stripe logo" />
              </h1>
            </div>
            <div className="align-left card-delivery-method-content">
              
              <h4 className="text-dark t-margin2">Manage your subscription in our platform</h4>
              <h4 className="text-dark t-margin2">Billed by Checklynx</h4>
              <h4 className="text-dark t-margin2">30 days trial </h4>
            </div>
          </div>
          
        </Form.Item>

        <Form.Item>
          <div
            className={`card-delivery-method ${
              selectedOption === "aws" ? "card-delivery-method-selected" : "card-delivery-method-unselected"
            }`}
            onClick={() => selectOption("aws")}
          >
            <div className="card-delivery-method-header">
              <h2 className="text-dark">AWS marketplace</h2>
              <h1 >
              <img src={AWScloud} alt="AWS Cloud" className='card-delivery-icon' />
              </h1>
            </div>
            <div className="align-left card-delivery-method-content">
              <h4 className="text-dark t-margin2">Manage your subscription in AWS marketplace</h4>
              <h4 className="text-dark t-margin2">Consolidated billing by AWS</h4>
              <h4 className="text-dark t-margin2">30 days trial</h4>
            </div>
            {selectedOption === "aws" && (
            
              <div className="align-left card-delivery-method-content">
                 <Divider />
                <h4 className="t-margin1 b-margin1">Follow this steps:</h4>

                <h5 className="text-dark t-margin2">1. Sign in to your AWS account</h5>
                <h5 className="text-dark t-margin1">
                  2. Navigate to the <a href={con.urls.aws_product_page} target="_blank" rel="noopener noreferrer">Checklynx product page </a>
                  in AWS marketplace.
                </h5>
                <h5 className=" text-dark t-margin1">3. Subscribe to the product</h5>
                <h5 className=" text-dark t-margin1">4. You will be redirected to the Checklynx signup page with a token that we can use to link your AWS account. </h5>
                <h6 className=" text-dark t-margin1">This option is not available if you want to install the Checklynx app from the stripe marketplace. In that case choose direct billing.  </h6>
               
              </div>
            )}
          </div>
          
        </Form.Item>


      
        <Form.Item>
          <Button
            type="primary"
            disabled={continueDisabled}
            onClick={continueClicked}
            className="input-big"
            ghost={true}
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const BillingMethodPage = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [continueDisabled, setContinueDisabled] = useState(true);
  const { initialFetch} = useContext(AuthContext);



  const navigate = useNavigate();
  const selectOption = (option) => {
    setSelectedOption(option);
   
    if (option === "aws") {
      setContinueDisabled(true);
    } 
    else {
      setContinueDisabled(false);
    }

  };


  


 

  return (
    <AppLayoutLogin showCreateAccount={false} showLogIn={false}>

      <div className="onboarding-box">
        <h1 className="onboarding-header">Let's create an account for you</h1>
        <div className="onboarding-grid">
          <OnboardingStepper className="grid-onboarding-item" currentStep={0} />
         
            <MethodSelector
              className="onboarding-containers"
              selectedOption={selectedOption}
              selectOption={selectOption}
              continueDisabled={continueDisabled}
              continueClicked={() => navigate("/signup")}
             
            />


            
         
         
    
        </div>
      </div>
    </AppLayoutLogin>
  );
};

export default BillingMethodPage;

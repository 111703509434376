import React, { createContext, useState, useContext } from 'react';

const ListContext = createContext();

export const useListContext = () => useContext(ListContext);

// Utility function to update list based on provided updater function
const updateList = (setList, updater) => (itemOrId, type) => {
  setList((prevList) => updater(prevList, itemOrId, type));
};

export const ListProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useState({});
  const [filterSearchParams, setFilterSearchParams] = useState({});
  //const [userList, setUserList] = useState([]);
  const [keyList, setKeyList] = useState([]);
  const [customerTableFilters,setCustomerTableFilters]= useState({});
  const [caseTableFilters,setCaseTableFilters]= useState({});

  const updateSearchResults = (results) => setSearchResults(results);
  const updateFilterSearchParams = (results) => setFilterSearchParams(results);
  const updateCustomerTableFilters = (filters) => setCustomerTableFilters(filters);
  const updateCaseTableFilters = (filters) => setCaseTableFilters(filters);


  const addItemList = (listName, item, type) => {
    const updater = (prevList, item, type) => [...prevList, type ? new type(item) : item];
    const setList = getListSetter(listName);
    updateList(setList, updater)(item, type);
  };

  const extendList = (listName, items, type) => {
    const updater = (prevList, items, type) => [...prevList, ...items.map(i => type ? new type(i) : i)];
    const setList = getListSetter(listName);
    updateList(setList, updater)(items, type);
  };

  const deleteItemList = (listName, itemId) => {
    const updater = (prevList, itemId) => prevList.filter(item => item.id !== itemId);
    const setList = getListSetter(listName);
    updateList(setList, updater)(itemId);
  };

  const editItemList = (listName, item, type) => {
    const updater = (prevList, item, type) => prevList.map(existingItem =>
      existingItem.id === item.id ? (type ? new type(item) : item) : existingItem
    );
    const setList = getListSetter(listName);
    updateList(setList, updater)(item, type);
  };

  const setList = (listName, items, type) => {
    const setList = getListSetter(listName);
    setList(items.map(item => type ? new type(item) : item));
  };
  

 

  // Determines the appropriate setter based on the list name
  const getListSetter = (listName) => {
    switch (listName) {
      //case 'userList': return setUserList;
      case 'keyList': return setKeyList;
      default: {
        console.warn('Unknown list name:', listName);
        return () => {}; // Return no-op function if list name is unknown
      }
    }
  };

  return (
    <ListContext.Provider value={{
      searchResults, updateSearchResults,
      customerTableFilters,updateCustomerTableFilters,
      caseTableFilters,updateCaseTableFilters,
      //userList, updateUserList: (users) => setUserList(users),
      keyList, updateKeyList: (keys) => setKeyList(keys),

      filterSearchParams, updateFilterSearchParams,
      addItemList,
      deleteItemList,
      editItemList,
      setList,
      extendList,
    }}>
      {children}
    </ListContext.Provider>
  );
};

import React, { useState, useEffect,useContext } from "react";
import { Link } from 'react-router-dom';

import { Table, Pagination, Tooltip,Dropdown } from "antd";
import { CustomerCardView } from "./CustomerCardViews";
import { MapNationalityToLabels } from "./CountrySelector";
import { Check } from "../models/CheckResult.js";
import { useCustomerContext } from '../contexts/CustomerContext'; 
import { AuthContext } from "../contexts/authContext.js"; // Adjust the import path as needed
import { useListContext } from "../contexts/ListProviderContext";
import { useUserContext } from '../contexts/UserContext';
import { ProfileOutlined } from "@ant-design/icons";

import { Button } from "antd";


import {  CaseStatusTagRender, CustomerRiskLevelTag } from "./Tags";


const CasesTable = ({data,  onSelectItem,  isCard,selectedItemId,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {  userInfo } = useContext(AuthContext);

  const handleSelectItem = (item) => {
    onSelectItem(item.id);
  };

  const { userList,getUsers } = useUserContext(); // Get userList from context



  const { caseList,fetchCases,paginationCases,loading } = useCustomerContext();
  const { caseTableFilters, updateCaseTableFilters } = useListContext();


  const customerData = data.map((item) => new Check(item));

  const columns = [
    {
      key: "id",
      title: "Case id.",
      dataIndex: "id",
      responsive: ["md"],
      ellipsis: true,
      width: 100,
    },
    {
      key: "Type",
      width: 94,
      title: "Type",
      dataIndex: "groupType",
      responsive: ["xl"],
      ellipsis: true,
    },
    
    {
      key: "Name",
      title: "Checked name",
      dataIndex: "name",
      responsive: ["md"],
      ellipsis: true,
    },
    
    
    

    {
      key: "Nationality",
      title: "Checked Nat./Juri.",
      dataIndex: "nationality",
      responsive: ["xl"],
      width: 120,
      render: (nationalities) =>
        nationalities ? MapNationalityToLabels(nationalities) : "",
      
    },
    {
      key: "BirthYear",
      title: "Checked B. year",
      dataIndex: "birthYear",
      responsive: ["md"],
      width: 100,
     
      render: (text, record) => {
        // Format birth year and age together, e.g., "1990 (34)"
        return record.birthYear ? `${record.birthYear} (${record.age})` : ``;
      },
    },
    {
      key: "updatedAt",
      title: "Updated",
      dataIndex: "updatedAt",
      responsive: ["xxl"],
      width: 100,
      ellipsis: true,
      render: (date) => {
        if (!date) return "";
        const validDate = new Date(date);
        if (isNaN(validDate)) return "";
        return validDate.toLocaleDateString();
      },
    },
    {
        key: "RiskLevel",
        width: 110,
        title: "Risk level",
        dataIndex: "riskLevel",
        responsive: ["md"],
        render: (riskLevel) => <CustomerRiskLevelTag riskLevel={riskLevel} />,
  
        
      },
    {
        key: "caseStatus",
        width: 90,
        title: "Case status",
        dataIndex: "caseStatus",
        responsive: ["md"],
        render: (value) => <CaseStatusTagRender value={value} />,
  
        
      },
      {
        key: "Assignee",
        width: 120,
        title: "Assignee",
        dataIndex: "assignee",
        responsive: ["md"],
        ellipsis: true,
        render: (assigneeId) => {
            const user = userList.find((user) => user.id === assigneeId);
            return user ? `${user.name} ${user.surname}` : "Not assigned";
          },
      },
      {
        title: 'Results',
        dataIndex: 'details',
        key: 'details',
        width: 90,
        render: (text, record) => {
          const { customerId, id } = record;
      
          return (
            <Tooltip title="go to results">
  <Link to={`/customers/${customerId}/cases/${id}`}>
  <ProfileOutlined  style={{ marginRight: '6px' }} />
  View
  </Link>
</Tooltip>
          );
        },
      },
     
    
  ];
  

  useEffect(() => {
    
        getUsers();
    
  }, []);



  useEffect(() => {
      
      const fetchData = async () => {
        const initialQuery={
          openCases: true,
          closedCases: false,
          assignee: userInfo.id,
          initial: true,
        }
       
          await fetchCases(initialQuery);
          const queryHash = generateQueryHash(initialQuery);
          updateCaseTableFilters((prevFilters) => ({
            ...prevFilters,
           
            switchCases:true,
            assigneeSelectOption: 0,
            assignee:initialQuery.assignee,
            openCases:initialQuery.openCases,
            closedCases:false,
          
            queryHashes: new Set().add(queryHash), 
          }))
      };
      

      
      if (caseList.length === 0) {
        fetchData()
      }

    }
    
  , []);

  const rowSelectionStyle = (record) => {
    return {
      onClick: () => handleSelectItem(record),
      style: {
        cursor: "pointer",
      },
      className: record.id === selectedItemId ? "selected-row" : "",
    };
  };

  const onPageChange = async (page, pageSize) => {
    setCurrentPage(page);

    try {
      if (paginationCases.cursor !== null) {
        await fetchCases(); // 
      }
    } catch (error) {
      console.error("Failed to fetchCustomers data:", error);
    }
  };

  const generateQueryHash = (query) => {
    const openCases = query.openCases !== undefined ? query.openCases : null;
    const closedCases = query.closedCases !== undefined ? query.closedCases : null;
    const assignee = query.assignee !== undefined ? query.assignee : null;

    return `${openCases}-${closedCases}-${assignee}`;
};

const onFilterAssignee = async ({  assigneeSelectOption }) => {
   
  let query = {
    initial: false,
    openCases: caseTableFilters.openCases,
    closedCases: caseTableFilters.closedCases,
  };
  if (assigneeSelectOption !== undefined) {
    switch (assigneeSelectOption) {
      case 0://my cases
        query.assignee = String(userInfo.id);
        break;
      case 1://not assigned
        query.assignee = "unassigned";
        break;
      default://any assignee
        query.assignee = null;
        break;
  }
  updateCaseTableFilters((prevFilters) => ({
    ...prevFilters,
    assigneeSelectOption:assigneeSelectOption,

  }));
  }


  const queryHash = generateQueryHash(query);
  if (!caseTableFilters.queryHashes.has(queryHash)) {
    await fetchCases(query);
    updateCaseTableFilters(prevFilters => ({
      ...prevFilters,
      queryHashes: new Set(prevFilters.queryHashes).add(queryHash)
    }));
  }
};

  
const onopenCasesChange = async (value) => {
  let query = {
    initial: false,
    assignee: caseTableFilters.assignee,
    
  };

  // Adjust other properties based on the value
  if (value===false) {
    query.closedCases = true;
    query.openCases= true
  } else {
    query.closedCases = false;
    query.openCases= true
  }

  const queryHash = generateQueryHash(query);
  if (!caseTableFilters.queryHashes.has(queryHash)) {
    console.log("queryhashes",caseTableFilters.queryHashes)
    await fetchCases(query);
    updateCaseTableFilters(prevFilters => ({
      ...prevFilters,
      queryHashes: new Set(prevFilters.queryHashes).add(queryHash),
     
    }));
  }
  updateCaseTableFilters(prevFilters => ({
    ...prevFilters,
    switchCases:value,
    openCases: query.openCases,  
    closedCases:  query.closedCases,
  }));
};


  const filteredData = customerData.filter((check) => {
    
    const hasOpenCase = check.caseStatus===0;
    if (caseTableFilters.switchCases===true) {
      if(!hasOpenCase){
        
        return false
      }
    }
    if (caseTableFilters.assigneeSelectOption === 0 && check.assignee !== userInfo.id) {
      return false;
    } else if (caseTableFilters.assigneeSelectOption === 1 && check.assignee !== "unassigned") {
        console.log("option 1", caseTableFilters)
      return false;
    }
   
   
  
   

    return true;
  });

  const validTotal =
    paginationCases.cursor !== null ? filteredData.length + 1 : filteredData.length;

  const startIdx = (currentPage - 1) * paginationCases.limit;

  const endIdx = startIdx + paginationCases.limit;

  const paginatedData = filteredData.slice(startIdx, endIdx).map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <div className="table-pagination-container">
      <div className="column-container-header filter-customers-results">

      <FilterCasesRadioGroup
          onopenCasesChange={onopenCasesChange}
          caseTableFilters={caseTableFilters}
          show={true}
        />


        <FilterAssignee
          onFilterAssignee={onFilterAssignee}
          assigneeSelectOption={caseTableFilters?.assigneeSelectOption}
          loading={loading}
          caseTableFilters={caseTableFilters}
        />
        
      </div>

      {isCard ? (
        <>
          <CustomerCardView
          
            data={paginatedData}
            selectedItemId={selectedItemId}
            handleSelectItem={handleSelectItem}
            loading={loading}
          />
          <PaginationComponent
            currentPage={currentPage}
            pagination={paginationCases}
            validTotal={validTotal}
            onPageChange={onPageChange}
            loading={loading}
          />
        </>
      ) : (
        <Table
          size="big"
          columns={columns}
          dataSource={paginatedData}
          onRow={rowSelectionStyle}
          scroll={{ y: "calc(100vh - 280px)" }}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: paginationCases.limit,
            total: validTotal,
            position: ["bottomCenter"],
            onChange: (page, pageSize) => {
              onPageChange(page, pageSize);
            },
          }}
        />
      )}
    </div>
  );
};

export default CasesTable;

export const PaginationComponent = ({
  currentPage,
  pagination,
  validTotal,
  onPageChange,
}) => {
  if (validTotal === 0) {
    return null;
  }
  return (
    <Pagination
      current={currentPage}
      pageSize={pagination.limit}
      total={validTotal}
      onChange={(page, pageSize) => onPageChange(page, pageSize)}
      showSizeChanger={false}
      disabled={false}
    />
  );
};

export const FilterAssignee = ({
  show = true,
  onFilterAssignee,
  loading,
  caseTableFilters,
}) => {
  const onSelectOption = (key) => {
    const keyNum=Number(key.key)
    onFilterAssignee({ assigneeSelectOption:keyNum }); //
  };

 

 

  const items = [
    { key: '0', label: 'My cases', onClick: onSelectOption },
    { key: '1', label: 'Not assigned', onClick: onSelectOption },
    { key: '2', label: 'Any assignee', onClick: onSelectOption },
  ];

  if (!show) {
    return null;
  }

  return (
    <div className="dir-row filter-customers-block">
     <Dropdown
        menu={{
          items,
        }}
        trigger={['click']}
        placement="bottomLeft"
      >
        <Button
          size="small"
          className={caseTableFilters?.assigneeSelectOption!==2 ? "button-default-primary button-filter" : "button-default-grey button-filter"}
          
          >
          <div>
            {caseTableFilters?.assigneeSelectOption !== undefined
              ? items.find((item) => item.key === caseTableFilters?.assigneeSelectOption.toString())?.label // Display current selected option
              : "Select result status"}
         
          </div>
        </Button>
      </Dropdown>
    </div>
  );
};


export const FilterCasesRadioGroup = ({
  loading,
  caseTableFilters,
  onopenCasesChange,
}) => {
  return (
    <div className="dir-row ">
      <Button
        size="small"
        loading={loading}
        type={caseTableFilters?.switchCases ? "default" : "default"} // Use "primary" when toggled on
        className={caseTableFilters?.switchCases ? "button-default-primary button-filter" : "button-default-grey button-filter"}
        onClick={() => onopenCasesChange(!caseTableFilters?.switchCases)} // Toggle value on click
      >
        {caseTableFilters?.switchCases ? "Open cases" : "Any case status"} {/* Toggle button label */}
      </Button>
      
      <div style={{ width: '1rem' }} /> {/* Spacer */}
    </div>
  );
};

///onboardingsuccess
//after admin user has created an account to confirm email and create tenant resources
//only shown to aws customers
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useContext } from 'react';

import {AuthContext } from '../contexts/authContext.js'; // Adjust the import path as needed

import AppLayoutLogin from "../components/AppLayoutLogin";
import OnboardingCluster from "../components/OnboardingCluster";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { Button } from "antd";

const OnboardingSuccessPage = () => {
  const navigate = useNavigate();
  const { initialFetch} = useContext(AuthContext);

  const [completion, setCompletion] = useState(false); // Example: step 1 is not yet complete
  const [errorMessage, setErrorMessage] = useState("");
  const [nextStepMessage, setNextStepMessage] = useState("");

  const location = useLocation();


  //
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get('error');
    console.log("Received error:", error); // Debugging log
    if (error) {
      switch (error) {
        case "400-TOKEN-EXPIRED":
          setErrorMessage("Your AWS marketplace token has expired.");
          setNextStepMessage(
            "Go to the AWS marketplace portal and start the set up again. You will be redirected to the Checklynx signup page with a new token."
          );
          break;

        case "400-TOKEN-INVALID":
          setErrorMessage("Your AWS marketplace token is not valid.");
          setNextStepMessage(
            "Go to the AWS marketplace portal and start the set up again. You will be redirected to the Checklynx signup page with a new token."
          );
          break;
        case "404-TENANT-NOT-FOUND":
          setErrorMessage(
            "Your AWS customer identification could not be found in our system."
          );
          setNextStepMessage(
            "Go to the AWS marketplace portal and check that you have an active subscription to Checklynx"
          );
          break;

        case "409-TENANT_ID-ALREADY-EXISTS":
          setErrorMessage(
          "Your AWS customer identification is already linked to your company"
        );
        setNextStepMessage(
          "You can continue in checklynx/login "
        );
        break
        // Add more cases as needed
        default:
          setErrorMessage("There was a server error and the account was not linked.");
          setNextStepMessage(
            "Please contact Checklynx support."
          );
          break;
      }
    }
  }, []);


  const goToPortal = async () => {
    await initialFetch({ newCompany: true });
    navigate("/companycreated");
  };

  return (
    <AppLayoutLogin
      showCreateAccount={false}
      showLogIn={false}
      forceBrightMode={true}
    >
      <OnboardingCluster
        currentStep={2}
        completion={completion}
        awsConnect={true}
      >
        <ResultOnboarding
          errorMessage={errorMessage}
          nextStepMessage={nextStepMessage}
          goToPortal={goToPortal}
        />
      </OnboardingCluster>
    </AppLayoutLogin>
  );
};

export default OnboardingSuccessPage;






const ResultOnboarding = ({ errorMessage, nextStepMessage,goToPortal }) => {
  const navigate = useNavigate();
  return (
    <div className="onboarding-containers">
      {errorMessage ? (
        <div>
          <h2>
            <ExclamationCircleOutlined className="icon-text" />
            The integration was not successful
          </h2>
          <div style={{ height: "1rem", flexShrink: 1 }}></div>
          <h3>{errorMessage}</h3>
          <div style={{ height: "1rem", flexShrink: 1 }}></div>
          <h4>{nextStepMessage}</h4>
        </div>
      ) : (
        
            <div className="align-left ">
            <div style={{ height: "1rem", flexShrink: 1 }}></div>
            <h2 >
             
              Well done!</h2>
              <h3 className="text-dark">
             
              Your account was created succesfully</h3>
              <div style={{ height: "1rem", flexShrink: 1 }}></div>
              <div style={{ height: "1rem", flexShrink: 1 }}></div>
              <Button
                  type="primary"
                  ghost="true"
                  onClick={goToPortal}
                  size="large"
                  className="input-big"
                >
                  Proceed to Portal
                </Button>

                
            </div>
          
      )}
    </div>
  );
};

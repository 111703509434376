// useAuth.js
import {  signOut,signIn ,signUp,resetPassword,confirmResetPassword,confirmSignUp,confirmSignIn,
  resendSignUpCode,autoSignIn,fetchAuthSession} from '@aws-amplify/auth';



import { useContext } from 'react';
import {AuthContext } from '../contexts/authContext.js'; // Adjust the import path as needed

export const useAuth = () => {
  const { user, setUser,setUserInfo, isLoading, setIsLoading, setIdToken,idToken,initializeAuth,initialFetch,updateAuthStatus} = useContext(AuthContext);





  const userLogIn = async (email, password) => {
    setIsLoading(true)
  try{
  
      const signInResponse = await signIn({ username: email, password });
      // Optionally process signInResponse if needed to extract user information
      await initializeAuth()
      await initialFetch()
      

      return { success: true,signInResponse};
    } catch (error) {
      if (error.name === "UserAlreadyAuthenticatedException"){
        await initializeAuth()
        await initialFetch()
        return { success: true ,signInResponse:null};
      }
      else{
      console.error("Error in userLogIn:", error);
      return { success: false, error: error.name };
    }
    } finally {
      setIsLoading(false);
    }
  };


  const confirmSignInEnforceNewPassword = async (password) => {
    try {
      const newPassword=password
     
      const res=await confirmSignIn({
        challengeResponse: newPassword,
      });
      console.log(res)
      
      return { success: true };
    } catch (error) {
     // console.error("Error updating password", error);
      return { success: false,error: error.message };
    }
  }

  
           
  

  const userLogOut = async () => {
    setIsLoading(true);
    //console.log("in logging out:", "");
    try {
      // Use AWS Amplify's Auth.signOut to log the user out
      await signOut();
      updateAuthStatus()
      
      //console.log("in LogOut setIdTokenID Token:", "");
    } catch (error) {
      setIsLoading(false);
      throw error; // Allow components to handle any errors
    }
  };

  const initiatePasswordReset = async (email) => {
    setIsLoading(true);
    try {
      const output = await resetPassword({  username: email });
      console.log("output initiatePasswordReset",output);
      setIsLoading(false);
      // Successfully initiated password reset process
    } catch (error) {
      setIsLoading(false);
      throw error; // Allow components to handle the error
    }
  };

  

  async function handleConfirmResetPassword(email,confirmationCode,newPassword)
    {
    try {
      //console.log("in handleConfirmResetPassword email,confirmationCode,newPassword:",email,confirmationCode,newPassword);
      const res= await confirmResetPassword({  username: email,confirmationCode,newPassword });
      console.log("output confirmResetPassword",res);
      return { success: true }; // Indicate success
    } catch (error) {
      return { success: false, error: error.name };
    }
  }
  async function handleSignUp(email, password, name, family_name, company_name,tenant_id,user_id) {
    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: email, // Using email as username
        password,
          options: {
          userAttributes: {
            email:email, // Email also set as a user attribute
            name: name, // 'given_name' for the first name
            family_name: family_name, // 'family_name' for the last name
            'custom:company_name': company_name,
            'custom:tenant_id': tenant_id,
            'custom:user_id': user_id,
            

            // Additional standard or custom attributes can be added here if needed
          },
          autoSignIn: {
              enabled: true, // Optionally enable auto sign-in upon successful signup
              // authFlowType can be specified if needed
          }
        }}
      );
      console.log("isSignUpComplete:", isSignUpComplete);
      console.log("nextStep:", nextStep);
      const CognitoUserId=userId
      return { success: true , CognitoUserId}; // Indicate success
      
    } catch (error) {
     
    return { success: false, error:error.name }; 
    }
  }

  async function handleSignUpConfirmation(email, confirmationCode) {
    try {
        const res=await confirmSignUp({ username: email, confirmationCode });
        console.log("handleSignUpConfirmation",res)
        return { success: true }; // Indicate success when no error occurs
    } catch (error) {
        // Check for specific error message indicating the user is already confirmed
        if (error.message.includes("User cannot be confirmed. Current status is CONFIRMED")) {
            console.log("User is already confirmed. No need to confirm again.");
            return { success: true }; // Treat this as a success to continue with the onboarding process
        }
        // For other errors, return them as part of the failure response
        return { success: false, error: error.name };
    }
}

  async function resendVerificationCode(email) {
    try {
      await resendSignUpCode({ username: email });
      return { success: true, message: 'Code resent successfully' };

      // Display success message to the user
    } catch (error) {
      return { success: false, error: error.name };
      // Display error message to the user
    }
  }

  const handleAutoSignIn = async () => {
    try {
        const autoSignInResponse = await autoSignIn();
        if (autoSignInResponse.success) {
            // Auto sign-in successful
            const userData = { ...autoSignInResponse.attributes, username: autoSignInResponse.username };
            console.log('handleAutoSignIn Auto sign-in successful userData:', userData);
            setUser(userData);
            const session = await fetchAuthSession();
            const idTokenString = session.tokens?.idToken?.toString();
            setIdToken(idTokenString);
            return { success: true,autoSignInResponse };
        } 
        
    } catch (error) {
      return { success: false,error }
    }
};


 



  // This return exposes your context's state and the signIn/signOut functions
  return { user, isLoading,idToken, userLogIn,confirmSignInEnforceNewPassword, userLogOut,initiatePasswordReset ,
    handleSignUp,handleConfirmResetPassword,handleSignUpConfirmation,resendVerificationCode,handleAutoSignIn,
   
    
    
    
  };
};



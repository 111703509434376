








export const mapUserData = (body) => {
    
    
    
    return {
        id: body.id,
        name: body.name,
        surname: body.surname,
        email:body.email,
        createdAt: Date(body.created_at),
        updatedAt: Date(body.updated_at),

    };
};


export const mapKeyData = (body) => {
    
    
    return {
        id: body.id,
        value: body.value,
        name:body.name,
        updatedAt :body.updatedAt? new Date(body.updatedAt) : new Date(body.updated_at) || "" ,
        createdAt :body.createdAt?  new Date(body.createdAt) : new Date(body.created_at)|| "" ,


    };
};




  
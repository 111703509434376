import { useState, useEffect } from 'react';

const usePasswordValidation = ({ initialPassword = "" }) => {
    const [password, setPassword] = useState(initialPassword);
    const [passwordValid, setPasswordValid] = useState(false);

    const [passwordRequirements, setPasswordRequirements] = useState([
        { regex: /.{8,}/, label: "8 characters or more", satisfied: false },
        { regex: /[a-z]/, label: "1 lower case letter", satisfied: false },
        { regex: /[A-Z]/, label: "1 upper case letter", satisfied: false },
        { regex: /\d/, label: "1 number", satisfied: false },
    ]);
  
    useEffect(() => {
        // Update the satisfaction of each requirement based on the current password
        const updatedRequirements = passwordRequirements.map(req => ({
            ...req,
            satisfied: req.regex.test(password)
        }));
        setPasswordRequirements(updatedRequirements);

        // Check if all requirements are met
        setPasswordValid(updatedRequirements.every(req => req.satisfied));
    }, [password]);

    return { password, setPassword, passwordValid, passwordRequirements };
};

export default usePasswordValidation;
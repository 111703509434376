import React, { useState } from "react";
import { Button, Divider } from "antd";
import { UserForm } from "./UserForm.jsx";

import { api } from "../API/API.js";
import { showNotification,areObjectsEqualForPatch,isValidEmail } from "./Utils.jsx";

import { useUserContext } from '../contexts/UserContext';
import {User} from "../models/User.js";

const Form = ({
  data,
  mode,
  onSubmit,
  onCancelForm,
  onSubmitLabel,
  onCancelLabel,
}) => {
  const ObjectName="User"
  const ObjectNameL="user"
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [issavedisabled, setIsSaveDisabled] = useState(true);
  const [itemData, setItemData] = useState({});
  const { createUser,updateUser,userList, loading, error } = useUserContext();


  const handleSubmit = async () => {
    try {
     
      setIsLoadingSubmit(true);
      let response;
      if (mode === "add") {
        response = await createUser(itemData);
       
      } else if (mode === "edit") {
        let areEqual
        areEqual=areObjectsEqualForPatch(data, itemData)
        
        if (areEqual){
          onSubmit()
          setIsLoadingSubmit(false);
          return
         }
        
        response = await updateUser(itemData.id, itemData);
      }

      setIsLoadingSubmit(false);
      if (response.success) {
        if (mode === "add") {
        showNotification(
          "success",
          `${ObjectName} created` ,
          "Your team member will receive an email with a temporary password for the first login.",
          4
        );}
        
        
        const newUser =new User(response.body);
        onSubmit(newUser)
        return;
      } else {
        console.log("response", response);
        switch (response.body.code) {
          case "409-USER_ID-ALREADY-EXISTS":
            showNotification(
              "error",
              `${ObjectName}id. already exists!` ,
              `${ObjectName}id. must be unique in your company. Leave customer id. empty to auto-generate.` 
            );
            break;
          case "409-EMAIL-ALREADY-EXISTS":
              showNotification(
                "error",
                `Email already exists!` ,
                `Email must be unique in your company.` 
              );
              break;
          default:
            showNotification(
              "error",
              `Failed to create ${ObjectNameL}.`,
              response.body.message || "An unexpected error occurred."
            );
        }
      }
    } catch (error) {
      showNotification(
        "error",
        `Failed to create ${ObjectNameL}.`,
        "An internal error occurred. Please try again."
      );
    }
  };

  const dataChanged = (currentData) => {

    let email=currentData.email
    // Define new customer data structure
    const newData = {
      name: currentData.name,
      id: currentData.id,
      surname: currentData.surname,
      email: currentData.email.toLowerCase(),
    };

    // Check if there are changes between newData and original data

    let propertiesCondition = isValidEmail(email);

   
if (propertiesCondition ) {
  setIsSaveDisabled(false); // Enable the save button
} else {
  setIsSaveDisabled(true);  // Disable the save button
}

setItemData(newData);
  };


 

  return (
    <div className="column-container-flex dir-col">
     
      <div className="column-container-overflow ">
        <UserForm
          
          ContainerClassName="createcustomercontainer"
          onDataChanged={dataChanged}
          data={data}
          mode={mode}
        />
      </div>
      <Divider />
      <div className="form-layout-spacer-button-bottom">
        <Button
          type="primary"
          onClick={handleSubmit}
          className="submit-button"
          loading={isLoadingSubmit}
          disabled={issavedisabled}
        >
          {onSubmitLabel}
        </Button>
        <Button type="default" onClick={onCancelForm} className="submit-button">
          {onCancelLabel}
        </Button>
      </div>
    </div>
  );
};

export default Form;

//forgotpassword

import React, { useEffect } from "react";
import { useNavigate,useLocation } from 'react-router-dom'; // Import useHistory

import { Form, Input, Button, message } from 'antd';
import { useAuth } from '../logic/useAuth'; // Assuming useAuth includes a password reset method
import AppLayoutLogin from '../components/AppLayoutLogin';

const ForgotPasswordPage = () => {
  const [form] = Form.useForm();
  const { initiatePasswordReset } = useAuth(); 
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || ''; // Get email from state, with fallback

  useEffect(() => {
    
    if (email) {
      form.setFieldsValue({ email });
    }
  }, [email, form]);

  const handlePasswordReset = async () => {
    try {
      const email = form.getFieldValue('email'); // Get the email from the form
      await initiatePasswordReset(email); 
      navigate('/passwordreset', { state: { email } }); // Navigate programmatically with state
     
    } catch (error) {
      message.error('Failed to send password reset email.');
    }
  };

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      handlePasswordReset(values.email);
    } catch (error) {
      console.log('Validation Failed:', error);
    }
  };

  return (
   
    <AppLayoutLogin>
      <div style={{ height: "3rem", flexShrink: 1 }}></div>
     
     
      <div style={contentStyle}>
      <h1>Request a password reset</h1>
      <div style={{ height: "2rem", flexShrink: 1 }}></div>
        <div style={childDivStyle}>
          <Form
            form={form}
            name="resetpassword"
            autoComplete="on"
            layout="vertical"
            onFinish={onSubmit}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                },
              ]}
            >
              <Input placeholder="Email" className="input-big" autoComplete="email" />
            </Form.Item>
            
            <Form.Item>
                <Button
                     className="input-big"
                     type="primary"
                     ghost={true}
                    htmlType="submit"
                    style={{ width: "100%", height: "3.5rem", fontSize: "large", fontWeight: 500,  }}
                >
                    Request
                </Button>
            </Form.Item>

          </Form>
        </div>
      </div>
    </AppLayoutLogin>
  );
};

export default ForgotPasswordPage;


const contentStyle = {
  padding: "3rem", // Padding as needed
  backgroundColor: "white",
  borderRadius: "26px", // Rounded corners
  width: "100%", // Width is 100% of its parent
  maxWidth: "600px", // Max width is 600px
};

const childDivStyle = {
  padding: "0rem", // Padding for the form
  width: "100%",
  margin: "auto", // Auto margin for automatic centering
};

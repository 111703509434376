import React, { useState, useEffect } from "react";

import { Layout } from "antd";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import useWindowSize from "./useWindowSize";
import { useTheme } from '../contexts/ThemeContext';

import con from "../config.json";


const { Header, Content } = Layout;

const AppLayoutLogin = ({ children,showLogIn,showCreateAccount,forceBrightMode=false }) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [collapsed, setCollapsed] = useState(!isMobile);
  const { setIsDarkMode } = useTheme();
  useEffect(() => {
    if (forceBrightMode) {
      setIsDarkMode(false);
      return;
    }
   
  }, []); // Dependency array ensures this runs only once on mount


  const handleLogoClick = () => {
    window.open(con.urls.landing_url, "_self");
  };

  const handleLogInClick = () => {
    navigate("/login");
  };
  const handleSignUpClick = () => {
    navigate("/signup");
  };

  useEffect(() => {
    setCollapsed(!isMobile); // Collapse for non-mobile sizes, expand for mobile
  }, [isMobile]);
  return (
    <Layout className="log-in-layout-style">
      <Header className="flex justify-space-between header-container-landing left-padding1">
        <div
          onClick={handleLogoClick}
          cursor="pointer"
          style={{ cursor: "pointer" }}
          className="header-title-landing "
        >
          Checklynx
        </div>

        <div className="flex dir-row side-padding1 gap1">
        {showLogIn && (
          <Button
            type="default"
            className="h48 header-button-landing"
            onClick={handleLogInClick}
            size="large"
            
          >
            Log in
          </Button>)}
       
          {showCreateAccount && (
          <Button
            className="h48 hide-small-screen header-button-landing"
            type="default"
            size="large"
            
            onClick={handleSignUpClick}
          >
            Create account
          </Button>)}
        </div>
      </Header>
      <Content className="log-in-content-style">{children}</Content>
    </Layout>
  );
};

export default AppLayoutLogin;






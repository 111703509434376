import React, { useState, useEffect } from 'react';
import { Form, Input, Typography } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import usePasswordValidation from '../hooks/usePasswordValidation'; // Adjust path as necessary

const { Text } = Typography;

const PasswordField = ({ form }) => {
    const { password, setPassword, passwordValid, passwordRequirements } = usePasswordValidation({ initialPassword: "" });
    const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);

    useEffect(() => {
        console.log("passwordValid", passwordValid);    
        form.validateFields(['password']);
    }, [passwordValid, form]);

    return (
        <>
            <Form.Item
                name="password" 
               
                rules={[
                    { required: true },
                    // Custom validation based on passwordValid
                    () => ({
                        validator(_, value) {
                            if (passwordValid) {
                                return Promise.resolve();
                            
                        } else if (!passwordValid && value) {
                            // Reject only if password is not valid and value is not empty
                            return Promise.reject(new Error(''));
                        }
                            
                                
                        }
                    })
                ]}
                validateTrigger="onBlur"

            >
                <Input.Password
                    placeholder="Password"
                    className="input-big"
                    onChange={(e) => {
                        setPassword(e.target.value);  // Local state update (if still needed)
                        form.setFieldsValue({ password: e.target.value });  // Update form state
                      }}
                    onFocus={() => setShowPasswordRequirements(true)}
                    
                    //onBlur={() => setShowPasswordRequirements(false)}
                    autoComplete="new-password"
                />
               
            </Form.Item>
            {showPasswordRequirements && (
                    <div style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }}>
                        {passwordRequirements.map((req, index) => (
                            <Text key={index} type="secondary" style={{ fontSize: "smaller", display: "block" }}>
                                {req.satisfied ? (
                                    <CheckCircleOutlined style={{ color: "green" }} />
                                ) : (
                                    <CheckCircleOutlined style={{ opacity: 0.5 }} />
                                )} {req.label}
                            </Text>
                        ))}
                    </div>
                )}
        </>
    );
};

export default PasswordField;

//authContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { getCurrentUser, signOut, fetchAuthSession,fetchUserAttributes } from "@aws-amplify/auth";
import { setIdToken,api } from "../API/API.js";

// Initialize your AuthContext with default values
const AuthContext = createContext({
  user: null,
  setUser: () => {},
  isLoading: true,
  updateAuthStatus: () => {},
  idToken: "",
  userName: "",
  companyName: "",
  setUserName: () => {},
  setCompanyName: () => {},
  setIdTokenState: () => {},
  userId: "",
  setUserId: () => {},
  setTenantInfo: () => {},
  tenantInfo: null,
  userInfo: null,
  
  

});



const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [idToken, setIdTokenState] =  useState('');
  const [userName, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [userId, setUserId] =useState('');


  const [tenantInfo, setTenantInfo] = useState(() => {
    const savedTenantInfo = localStorage.getItem('tenantInfo');
    return savedTenantInfo ? JSON.parse(savedTenantInfo) : { name:"",cancel_at: null,
       subscription_active: false,cancel_at_period_end:false,found:false,billing_method:"" };
  });

  const[userInfo,setUserInfo]=useState(() =>{
    const savedUserInfo = localStorage.getItem('userInfo');
    return savedUserInfo ? JSON.parse(savedUserInfo) : { name:"",id: "" ,surname:"", email:""};
  }
  )




  






  const initializeAuth = async () => {
    setIsLoading(true);
    try {
      //auth session
      const session = await fetchAuthSession();
      // Assuming session.tokens.idToken.toString() gives you the JWT token string
      const idTokenString = session.tokens?.idToken?.toString();
      setIdTokenState(idTokenString); // Assuming this state is for React context
      setIdToken(idTokenString); // Update API.js token with the JWT string
      //user
      const userData = await getCurrentUser();
      const userAttributes = await fetchUserAttributes()
      
      setUser(userData);
      const userInfo={  name: userAttributes.name, surname:userAttributes.family_name, email:userAttributes.email,
       id: userAttributes['custom:user_id'] 
      }
      setUserInfo(userInfo)
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      
    } catch (error) {
      setUser(null);
      setUserInfo(null);
    } finally {
      setIsLoading(false);
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      await initializeAuth();
      if (!tenantInfo.name) {
        await initialFetch();
      }
    };
    fetchData();
  }, []); 



  useEffect(() => {
    // This effect ensures the API's token is always in sync with the context's token
    setIdToken(idToken);
  }, [idToken]);

  const updateAuthStatus = async (authStatus) => {
    setIsLoading(true);
    if (authStatus) {
      try {
        const userData = await getCurrentUser();
        setUser(userData);
        const session = await fetchAuthSession();

        const idTokenString = session.tokens?.idToken?.toString();

        setIdTokenState(idTokenString); 
        setIdToken(idTokenString);
      } catch (error) {
        console.log("Error updating auth status:", error);
        setUser(null);
       
      }
    } else {
      try {
        await signOut();
        setUser(null);
        setIdTokenState(null); 
        setIdToken(null); 
       
        localStorage.removeItem('tenantInfo');
        localStorage.removeItem('userInfo');
      } catch (error) {
        console.log("Error signing out:", error);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchTenantInfo = async () => {
      await initializeAuth();
      await initialFetch();
      const newUrl = window.location.href.replace('/companysettingsupdated', '');
      window.history.replaceState({}, document.title, newUrl);
    };
    if (window.location.href.includes('/companysettingsupdated')) {
      fetchTenantInfo(false);
    }
  }, [window.location.href]);
  
  const initialFetch = async (newCompany = false) => {
    try {
      console.log("in authcontext initialFetch");
      console.log("newCompany", newCompany);
  
      let tenant = await api.getTenant();
  
      let tenantInfo;
      if (tenant.errorCode === 404) {
        console.error("Tenant details not found");
        tenantInfo = {
          name: "",
          subscription_active: false,
          cancel_at_period_end: true,
          cancel_at: null,
          billing_method:"",
          found: false,
        };
      } else {
        tenantInfo = {
          name: tenant.body.name,
          billing_method: tenant.body.billing_method,
          subscription_active: newCompany ? true : tenant.body.subscription_active,
          cancel_at_period_end: newCompany ? false : tenant.body.cancel_at_period_end,
          cancel_at:null,
          found: true,
        };
      }
      console.log("tenant", tenant)
      if (tenantInfo.cancel_at_period_end) {
        // Convert billing_period_end to a timestamp
        const normalizedDateString = tenant.body.billing_period_end.replace(/\.\d{3}\d*/, '');
        const shortDate = new Date(normalizedDateString);
        tenantInfo.cancel_at = shortDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    
});
       
      }
      console.log("tenantInfo", tenantInfo)
    
    
  
      setTenantInfo(tenantInfo);
      localStorage.setItem('tenantInfo', JSON.stringify(tenantInfo));
  
      return { success: true, tenant: tenant.body };
    } catch (error) {
      console.error("Error during initialFetch:", error);
      return { success: false, error };
    }
  };
  

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        isLoading,
        setIsLoading,
        updateAuthStatus,
        idToken,
        setIdToken,
        userName,
        setUserName,
        companyName,
        setCompanyName,
        userId,
        setUserId,
        setTenantInfo,
        tenantInfo,
        userInfo,
        setUserInfo,
        initialFetch,
        initializeAuth

      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider };

import React from "react";
import { Tag, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const CustomerStatusTag = ({ status }) => {
  const getStatusTagColor = (status) => {
    switch (status) {
      case "onboarded":
        return "rgba(79,193,109,0.95)"; // Green with reduced transparency
      case "review":
        return "rgba(232,213,28,0.96)";
      case "waiting":
        return "rgba(232,213,28,0.96)";
      case "rejected":
        return "rgba(230,108,50.96)"; // Red with reduced transparency
      default:
        return "rgba(18,196,191,0.96)"; // Default color with reduced transparency
    }
  };

  const tagColor = getStatusTagColor(status);

  return (
    <Tag color={tagColor} style={{ color: "black", fontWeight: 500 }}>
      {status}
    </Tag>
  );
};

export const ResultStatusTagRender = ({ value }) => {
  switch (value) {
    case 0:
      return (
        <CheckTag value="Results not allowlisted" tagType="resultNotAllowed" />
      );
    case 1:
      return <CheckTag value="Results allowlisted" tagType="resultAllowed" />;
    default:
      return <CheckTag value="" tagType="" />;
  }
};

export const ResultStatusTag = ({ allowlisted }) => {
  if (allowlisted === true) {
    return <CheckTag value="allowlisted" tagType="resultAllowed" />;
  } else if (allowlisted === false) {
    return <CheckTag value="not allowlisted" tagType="resultNotAllowed" />;
  }
};

export const riskOptions = [
  { value: 0, label: "unknown" },
  { value: 1, label: "low" },
  { value: 2, label: "medium" },
  { value: 3, label: "high" },
  { value: 4, label: "unacceptable" },
];

export const RiskStatusTag = ({
  risklevel,
  loading = false,
  textAfter = "",
}) => {
  const status = riskOptions.find(
    (option) => option.value === Number(risklevel)
  );
  let className;

  switch (Number(risklevel)) {
    case 0:
      className = "tagStyleUnkonwn";

      break;
    case 1:
      className = "tagStyleLow";
      break;
    case 2:
      className = "tagStyleMedium";
      break;
    case 3:
      className = "tagStyleHigh";
      break;

    case 4:
      className = "tagStyleUnacceptable";
      break;

    default:
      className = "tagStyleUnkonwn";

      break;
  }
  const labelText = status
    ? status.label + textAfter
    : `Unknown Status${textAfter}`;

  return <CheckTag value={labelText} className={className} loading={loading} />;
};

export const RiskTagRender = ({ value }) => <RiskStatusTag risklevel={value} />;

export const MatchStatusTag = ({
  matchStatus,
  loading = false,
  textAfter = "",
}) => {
  const status = matchStatusOptions.find(
    (option) => option.value === Number(matchStatus)
  );
  let className;

  switch (Number(matchStatus)) {
    case 0: //unknown
      className = "tagStyleUnkonwn";
      break;
    case 1: //no match
      className = "tagStyleLow";
      break;
    case 2: //false positive
      className = "tagStyleLow";
      break;
    case 3: //potential match
      className = "tagStyleMedium";
      break;
    case 4: //true positive
      className = "tagStyleHigh";
      break;
    case 5: //true positive approve
      className = "tagStyleAccepted";
      break;
    case 6: //true positive reject
      className = "tagStyleUnacceptable";
      break;

    default:
      className = "tagStyleUnkonwn";
      break;
  }
  const labelText = status
    ? status.label + textAfter
    : `Unknown Status${textAfter}`;
  return <CheckTag value={labelText} className={className} loading={loading} />;
};

export const CustomerRiskLevelTag = ({
  riskLevel,
  loading = false,
  textAfter = "",
}) => {
  let status;
  let tagStyle;

  switch (Number(riskLevel)) {
    case 0:
      status = "unknown";
      tagStyle = "tagStyleRiskUnknown";
      break;
    case 1:
      status = "low";
      tagStyle = "tagStyleLow";
      break;
    case 2:
      status = "medium";
      tagStyle = "tagStyleMedium";
      break;
    case 3:
      status = "high";
      tagStyle = "tagStyleHigh";
      break;
    case 4:
      status = "unacceptable";
      tagStyle = "tagStyleUnacceptable";
      break;
    default:
      status = "unknown";
      tagStyle = "tagStyleUnkonwn";
      break;
  }

  status = status + textAfter;
  return <CheckTag value={status} tagType={tagStyle} loading={loading} />;
};

export const progressStatusOptions = [
  { value: 0, label: "unreviewed" },
  { value: 1, label: "in progress" },
  { value: 2, label: "pending" },
  { value: 3, label: "ready for closure" },
  { value: 4, label: "escalated" },
];

export const ProgressTagRender = ({
  value,
  loading = false,
  textAfter = "",
}) => {
  let className;
  const status = progressStatusOptions.find(
    (option) => option.value === Number(value)
  ); // Ensure value is a number

  // Determine the tag style based on the progress status
  switch (Number(value)) {
    case 0:
      className = "tagStyleUnkonwn";
      break;

    case 1:
      className = "tagStyleLow";
      break;
    case 2:
      className = "tagStyleMedium";
      break;
    case 3:
      className = "tagStyleAccepted";
      break;
    case 4:
      className = "tagStyleHigh";
      break;

    default:
      className = "tagStyleUnkonwn";
      break;
  }

  // Handle missing status and append text if necessary
  const labelText = status
    ? status.label + textAfter
    : `Unknown Status${textAfter}`;

  return <CheckTag value={labelText} className={className} loading={loading} />;
};

const LoadingTag = ({ tagColor, loading, status, textColor, className }) => {
  const computedTagColor = loading ? "white" : tagColor;
  const greyIcon = <LoadingOutlined style={{ color: "lightgrey" }} spin />;

  if (status === null || status === undefined) return null;
  return (
    <div>
      {loading ? (
        <Spin size="small" indicator={greyIcon} />
      ) : (
        <Tag
          className={className}
          color={computedTagColor}
          style={{
            color: textColor,
            fontWeight: 700,
            borderColor: "lightgrey",
            position: "relative",
          }}
        >
          {status}
        </Tag>
      )}
    </div>
  );
};

export default LoadingTag;
export const MatchStatusTagRender = ({ value }) => (
  <MatchStatusTag matchStatus={value} />
);

export const caseStatusOptions = [
  { value: 0, label: "open" },
  { value: 1, label: "closed" },
];

export const CaseStatusTagRender = ({ value,loading }) => {
  const status = caseStatusOptions.find((option) => option.value === value);

  return <CheckTag value={status ? status.label : ""} loading={loading} tagType={"caseStatus"} />;
};

export const matchStatusOptions = [
  { value: 0, label: "unknown" },
  { value: 1, label: "no match" },
  { value: 2, label: "false positive" },
  { value: 3, label: "potential match" },
  { value: 4, label: "true positive" },
  { value: 5, label: "true positive approve" },
  { value: 6, label: "true positive reject" },
];

export const customerRiskOptions = [
  { value: 0, label: "unknown" },
  { value: 1, label: "low" },
  { value: 2, label: "medium" },
  { value: 3, label: "high" },
  { value: 4, label: "unaceptable" },
];

const tagStyles = {
  customer: "tagStyle tagStyleCustomer",
  check: "tagStyle tagStyleCheck",
  source: "tagStyle tagStyleSource",
  resultAllowed: "tagStyle tagStyleResult allowed",
  resultNotAllowed: "tagStyle tagStyleResult notAllowed",
  caseStatus: "tagStyle tagStatus",
  region:"tagStyle tagStyleRegion"
};

export const CheckTag = ({
  value,
  tagType = "tagStyle",
  className = "",
  loading = false,
  show = true,
}) => {
  // Combine the passed className and the dynamic tagType class
  const combinedClassName = `${tagStyles[tagType] || tagType} ${className}`; // If tagType doesn't exist in tagStyles, use the raw tagType

  if (!show || value === "") return null;

  return (
    <LoadingTag
      className={combinedClassName}
      loading={loading}
      status={value}
    />
  );
};

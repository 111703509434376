import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import { Row,Alert} from "antd";

import AppLayout from "../components/AppLayout";
import CasesTable from "../components/CasesTable";
import { Customer } from "../models/Customer.js";
import { Check } from "../models/CheckResult.js";
import { CustomerDetailCard,CheckDetailCard ,CheckActionsCard} from "../components/CustomerCardViews.jsx";
import CustomBreadcrumb from "../components/CustomBreadcrumb.jsx";

import { useNavigate } from "react-router-dom";

import ColumnContainer from "../components/ColumnContainer";
import Notes from "../components/Notes";
import { useCustomerContext } from '../contexts/CustomerContext'; 


import {
  HelpTitleCases,
  HelpContentCases,
} from "../components/HelpText";



const useColumnDisplay = (selectedItem, isMonoColumn, mode) => {
  const isItemSelected = !!selectedItem && !!selectedItem.id;
  const showFirstColumn = !isMonoColumn || (!isItemSelected && mode !== "add");
  const showSecondColumn =
    isItemSelected ||
    mode === "add" ||
    mode === "edit" ||
    (isMonoColumn && mode === "add");
  return { showFirstColumn, showSecondColumn };
};

const CasesPage = () => {
  const navigate = useNavigate();

  const { caseList,getCustomer,loading } = useCustomerContext();


  const ObjectNameL = "customer";

  const [selectedCase, setSelectedCase] = useState(null || new Check({}));
  const [selectedCustomer, setSelectedCustomer] = useState(null || new Customer({}));


  const [mode, setMode] = useState("view"); // Modes: view, add, edit
  const isCard = useMediaQuery({ maxWidth: 1000 });
  const isMonoColumn = useMediaQuery({ maxWidth: 800 });
  const isMonoColDetails=useMediaQuery({ maxWidth: 1600 });
  const [loadingChecks, setLoadingChecks] = useState(false);

  const [showHelp, setShowHelp] = useState(false);
  const [helpTitle, setHelpTitle] = useState("");
  const [helpContent, setHelpContent] = useState("");




  const handleShowHelpToggleAddEditUser = () => {
    setHelpTitle(HelpTitleCases);
    setHelpContent(HelpContentCases);
    handleShowHelpToggle();
  };

  const handleShowHelpToggle = () => {
    setShowHelp((prevShowHelp) => !prevShowHelp);
  };

  


  const { showFirstColumn, showSecondColumn } = useColumnDisplay(
    selectedCase,
    isMonoColumn,
    mode
  );

  const handleItemSelect = async (id) => {
    let existingCase;
    setMode("view");
   
      existingCase = caseList.find((item) => item.id === id);
    
    if (existingCase === undefined) {
      onSelectedItemChange(null);
      return;
    }

    const existingCustomer =await getCustomer(existingCase.customerId)
    setSelectedCustomer(existingCustomer)

    onSelectedItemChange(existingCase);
  };

  const fetchCustomer = async (id) => {
    setLoadingChecks(true);
    const customer=await getCustomer(id)
    onSelectedItemChange(customer)
    setLoadingChecks(false);
  };

  const onCustomerViewDetails = async () => {
    const existingCase = caseList.find(
      (customer) => customer.id === selectedCase.id
    );
    setSelectedCase(existingCase);

    navigate(`/cases/${existingCase.id}`);
  };

  const onSelectedItemChange = (item) => {
    setSelectedCase(item);
    if (item === null) {
      const newUrl = `${host}/cases`;
      window.history.replaceState(null, "", newUrl);
      return;
    }
    const newUrl = `${host}/cases/${item.id}`;
    window.history.replaceState(null, "", newUrl);
  };

 

  

  useEffect(() => {
    const updateUrl = () => {
      if (!selectedCase?.id) return;
      let newUrl = `${host}/cases/${selectedCase.id}`;
      window.history.replaceState(null, "", newUrl);
    };
    updateUrl();
  }, [selectedCase]);

  useEffect(() => {
    if(caseList.length>0 ){
      const check=caseList[0]
      handleItemSelect(check.id)
    }
  }, [caseList]);
  

  const host = window.location.origin;
  let breadcrumbItems = [
    { key: "1", title: "Cases ", href: `${host}/cases` },
    ...(selectedCase?.id
      ? [
          {
            key: "2",
            title: `${selectedCase.id}`,
            href: `${host}/cases/${selectedCase.id}`,
          },
        ]
      : []),
  ];

  return (
    <AppLayout
      handleShowHelpToggle={handleShowHelpToggle}
      showHelp={showHelp}
      helpTitle={helpTitle}
      helpContent={helpContent}
    >
       {false && (<Alert
          message="Beta functionality. Do not use in production."  // Message displayed in the alert
          type="info"  // Type of alert styling
          showIcon  // Show an icon next to the alert message
          style={{ marginBottom: "8px"}}
          
        />)}
      <Row gutter={0} style={{ display: "flex", flex: "1 1 auto" }}>
        {showFirstColumn && (
          <ColumnContainer
            colsize={16}
            title={<CustomBreadcrumb items={breadcrumbItems} />}
            helpIcon={true}
            handleShowHelpToggleAddEditUser={handleShowHelpToggleAddEditUser}
           
            
            showBackButton={false}
          >
            <CasesTable
              selectedItemId={selectedCase?.id}
              data={caseList}
             
             
              loadingCustomers={loading}
              onSelectItem={handleItemSelect}
              isCard={isCard}
            />
          </ColumnContainer>
        )}
        {showSecondColumn && (
          <ColumnContainer
            colsize={isMonoColumn ? 24 : 8}
            itemNameDelete={`${ObjectNameL}'s`}
            title={
                selectedCase
                ? mode === "view"
                  ? `${selectedCase.id}`
                  : ""
                : ""
            }
            showBackButton={
              mode === "add" ? false : mode === "edit" ? false : isMonoColumn
            }
            onBackClick={() => setSelectedCase(null)}
           
           
          >
            <CustomerDetailsAndAudit
              customer={selectedCustomer}
              selectedCase={selectedCase  }
              onCustomerViewDetails={onCustomerViewDetails}
              onFetchChecks={fetchCustomer}
              loadingChecks={loadingChecks}
              isMonoColDetails={isMonoColDetails}
            ></CustomerDetailsAndAudit>
          </ColumnContainer>
        )}
      </Row>

     
    </AppLayout>
  );
};

export default CasesPage;

export const CustomerDetailsAndAudit = ({
  customer,
  selectedCase,
  onEdit,
  onCustomerViewDetails,
  onFetchChecks,
  loadingChecks,
  isMonoColDetails,
  onAdd,
}) => {
  return (

    <div className= "expand-height expand-width">
    {!isMonoColDetails ? (
      // Two columns layout for large screens
     
      <div className="dir-row expand-width">
          <div className="column-container-overflow">
            <div style={{ height: "0px", width: "100%" }}>
            <CustomerDetailCard
            customer={customer}
            onEdit={onEdit}
            
          />
          <CheckActionsCard
          checkId={selectedCase.id          }
          customer={customer}/>
          <CheckDetailCard
          item={selectedCase }
          ></CheckDetailCard>

       </div>
          </div>
          <div style={{ width: "8px" }} />
          <div className="notes-container-width expand-width">
            <Notes  customerId={customer.id} overflow={true} loading={loadingChecks}/>
          </div>
        </div>
    
    ) : (
      <div className="dir-col expand-width">
        <div className="expand-width  column-container-overflow ">
        <div style={{ height:'0px', width:"100%" }}>
        <CustomerDetailCard
            customer={customer}
            onEdit={onEdit}
            
          />
          <CheckActionsCard
          checkId={selectedCase.id          }
          customer={customer}/>
          <CheckDetailCard
          item={selectedCase }/>
        <Notes  customerId={customer.id} overflow={false} loading={loadingChecks} />

        </div>
        </div>
      </div>
    )}
    </div>

  
  );
};

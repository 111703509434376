import React, { useState, useEffect } from "react";
import { Popover, Checkbox, Space, Slider, Drawer, Button, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import CountrySelector, { MapNationalityToLabels } from "./CountrySelector"; // Adjust import path as necessary
import BirthYearSelector from "./BirthYearSelector"; // Adjust import path as necessary
import { CloseOutlined } from "@ant-design/icons"; // Import the close icon
import { useListContext } from "../contexts/ListProviderContext"; // Adjust the path as needed
import { Capitalize, formatSourceType } from "./Utils";
import { CheckTag } from "./Tags";

export const FilterForm = ({ isMobile, handleSearch,loading }) => {
  const { filterSearchParams, updateFilterSearchParams } = useListContext();

  const handleBirthYearChange = (value) => {
    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      birthYear: value,
    }));
  };

  const handleFuzzinessChange = (value) => {
    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      fuzziness: value,
    }));
  };

  const handleNationalityChange = (value) => {
    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      nationality: value,
    }));
  };
  const handleJurisdictionChange = (value) => {
    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      jurisdiction: value,
    }));
  };

  const handleGenderChange = (value) => {
    if (
      filterSearchParams.gender !== null &&
      filterSearchParams.gender !== undefined
    ) {
      if (
        value.includes("male") &&
        filterSearchParams.gender.includes("female")
      ) {
        value = ["male"];
      }
      if (
        value.includes("female") &&
        filterSearchParams.gender.includes("male")
      ) {
        value = ["female"];
      }
      if (value.includes("male") && value.includes("female")) {
        value = [];
      }
    }

    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      gender: value,
    }));
  };

  const handleSourceTypeChange = (value) => {
    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      sourceType: value,
    }));
  };

  const toggleIndividual = () => {
    const newOnlyIndividual = !filterSearchParams.onlyIndividual;

    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      onlyIndividual: newOnlyIndividual,
      groupType: newOnlyIndividual ? "individual" : "",
      onlyEntity: false,
      onlyVessel: false,
      onlyAircraft: false,
    }));
  };

  const toggleEntity = () => {
    const newOnlyEntity = !filterSearchParams.onlyEntity;

    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      onlyEntity: newOnlyEntity,
      groupType: newOnlyEntity ? "entity" : "",
      onlyIndividual: false,
      onlyVessel: false,
      onlyAircraft: false,
    }));
  };

  const toggleVessel = () => {
    const newOnlyVessel = !filterSearchParams.onlyVessel;

    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      onlyVessel: newOnlyVessel,
      groupType: newOnlyVessel ? "vessel" : "",
      onlyIndividual: false,
      onlyEntity: false,
      onlyAircraft: false,
    }));
  };
  const toggleAircraft = () => {
    const newOnlyAircraft = !filterSearchParams.onlyAircraft;

    updateFilterSearchParams((prevFilters) => ({
      ...prevFilters,
      onlyAircraft: newOnlyAircraft,
      groupType: newOnlyAircraft ? "aircraft" : "",
      onlyIndividual: false,
      onlyEntity: false,
      onlyVessel: false,
    }));
  };



  useEffect(() => {
    if (
      filterSearchParams.onlyEntity === undefined ||
      filterSearchParams.onlyEntity === null
    ) {
      updateFilterSearchParams((prevFilters) => ({
        ...prevFilters,
        onlyEntity: false,
        onlyIndividual: false,
        onlyVessel: false,
        onlyAircraft: false,
      }));
    }
  }, []);

  return (
    <>
      <div
        className="filter-container"
        style={{ width: "100%", padding: "0.5rem" }}
      >
        <FuzzinessSlider
          defaultValue={filterSearchParams?.fuzziness}
          onFuzzinessChange={handleFuzzinessChange}
        />
        <p className="display-property-label top-margin-label-s">Group type</p>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          <Button
            type={filterSearchParams.onlyIndividual ? "primary" : "default"}
            onClick={toggleIndividual}
            style={{
              width: "25%",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
          >
            Individual
          </Button>

          <Button
            type={filterSearchParams.onlyEntity ? "primary" : "default"}
            onClick={toggleEntity}
            style={{ width: "25%", borderRadius: "0px" }}
          >
            Entity
          </Button>
          <Button
            type={filterSearchParams.onlyVessel ? "primary" : "default"}
            onClick={toggleVessel}
            style={{
              width: "25%",
              borderRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
          >
            Vessel
          </Button>
          <Button
            type={filterSearchParams.onlyAircraft ? "primary" : "default"}
            onClick={toggleAircraft}
            style={{
              width: "25%",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
            }}
          >
            Aircraft
          </Button>
        </div>
        {filterSearchParams.onlyIndividual && (
          <>
            <p className="display-property-label top-margin-label-s">
              Nationality
            </p>
            <CountrySelector
              isMobile={false}
              onChange={handleNationalityChange}
              defaultCountries={filterSearchParams.nationality}
            />
            <p className="display-property-label top-margin-label-s">
              Birth year
            </p>
            <BirthYearSelector
              isMobile={false}
              onChange={handleBirthYearChange}
              defaultYear={filterSearchParams.birthYear}
            />
            <div className="filter-cluster">
              <p className="display-property-label top-margin-label-s">
                Source type
              </p>
              <Checkbox.Group
                options={[
                  { label: "PEP", value: "pep" },
                  { label: "Sanctions", value: "sanctions" },
                ]}
                value={filterSearchParams.sourceType}
                onChange={handleSourceTypeChange}
              />
            </div>
            <div className="filter-cluster">
              <p className="display-property-label top-margin-label-s">
                Gender
              </p>
              <Checkbox.Group
                options={[
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                ]}
                value={filterSearchParams.gender}
                onChange={handleGenderChange}
              />
            </div>
          </>
        )}
        {(filterSearchParams.onlyEntity ||
          filterSearchParams.onlyVessel ||
          filterSearchParams.onlyAircraft) && (
          <>
            <p className="display-property-label">Jurisdiction</p>
            <CountrySelector
              isMobile={isMobile}
              onChange={handleJurisdictionChange}
              defaultCountries={filterSearchParams.jurisdiction}
            />
          </>
        )}
       {!isMobile && (
  <div
    className="filter-cluster"
    style={{
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "2rem",
    }}
  >
    <Button type="primary" ghost  onClick={() => handleSearch()} loading={loading}>
      Search
    </Button>
  </div>
)}
      </div>
    </>
  );
};

const PopoverFilter = ({ isMobile,handleSearch ,loading}) => {
  const [showFilters, setShowFilters] = useState(false);

  return (
    <>
      {isMobile ? (
        <>
          <FontAwesomeIcon
            icon={faSliders}
            onClick={() => setShowFilters(!showFilters)}
            style={{ cursor: "pointer" }}
          />
          <Drawer
            placement="bottom"
            height="85%"
            onClose={() => setShowFilters(false)}
            open={showFilters}
            title={"Search filters"} // This effectively removes the header part
            closeIcon={
              <div
                style={{ position: "absolute", top: 10, right: 18, zIndex: 10 }}
              >
                <CloseOutlined /> {/* Your custom close icon */}
              </div>
            }
            styles={{
              body: { padding: 0 },
              wrapper: { width: "100%" },
            }}
          >
            <div style={{ padding: "0.5rem 1rem 1rem 1rem", width: "100%" }}>
              <FilterForm isMobile={isMobile} />
            </div>
          </Drawer>
        </>
      ) : (
        <Popover
          placement="bottomLeft"
          arrow={false}
          content={
            <div style={{ width: "100%" }}>
              <FilterForm isMobile={isMobile} handleSearch={handleSearch} loading={loading}/>
            </div>
          }
          trigger="click"
          open={showFilters}
          onOpenChange={setShowFilters}
        >
          <FontAwesomeIcon
            icon={faSliders}
            onClick={() => setShowFilters(!showFilters)}
            style={{ cursor: "pointer" }}
          />
        </Popover>
      )}
    </>
  );
};

export default PopoverFilter;

export const FilterTags = ({ filters }) => {
  const {
    onlyEntity,
    onlyAircraft,
    onlyVessel,
    onlyIndividual,
    groupType,
    gender,
    sourceType,
    nationality,
    birthYear,
    jurisdiction,
    fuzziness,
  } = filters;

  // Prepare the tags based on conditions
  const tags = {};

  if (String(fuzziness) === "0") {
    tags["Fuzziness"] = "Exact fuzzy";
  }
  if (String(fuzziness) === "1") {
    tags["Fuzziness"] = "Medium fuzzy";
  }
  if (String(fuzziness) === "2") {
    tags["Fuzziness"] = "Broad fuzzy";
  }
  
    
  

  if (groupType) {
    tags["Group type"] = Capitalize(groupType);
  } else {
    tags["Group type"] = "Any Group Type";
  }

  // Conditionally render based on onlyEntity and onlyIndividual
  if ((onlyEntity || onlyAircraft || onlyVessel) && jurisdiction) {
    tags["Jurisdiction"] = MapNationalityToLabels(jurisdiction);
  }

  if (onlyIndividual) {
    if (sourceType && sourceType.length > 0) {
      tags["Source type"] = formatSourceType(sourceType.join(", "));
    }
    if (nationality) {
      tags["Nationality"] = MapNationalityToLabels(nationality);
    }
    if (birthYear) {
      tags["Birth year"] = birthYear;
    }
    if (gender && gender.length > 0) {
      tags["Gender"] = Capitalize(gender.join(", "));
    }
  }

  // Render the tags
  return (
    <Space style={{ flexWrap: "wrap", columnGap: "3px", rowGap: "3px" }}>
      {Object.entries(tags).map(([key, value], index) => (
        <CheckTag value={value} key={index}>
          {key}
        </CheckTag>
      ))}
    </Space>
  );
};

export const FuzzinessSlider = ({ defaultValue = 1, onFuzzinessChange }) => {
  return (
    <div>
      <p className="display-property-label top-margin-label-s">Fuzziness</p>

      <FuzzSlider
        defaultValue={defaultValue}
        onFuzzinessChange={onFuzzinessChange}
      ></FuzzSlider>
    </div>
  );
};

export const FuzzSlider = ({
  defaultValue = 1,
  onFuzzinessChange,
  loading,
}) => {
  const [fuzziness, setFuzziness] = useState(defaultValue);

  const handleChange = (value) => {
    setFuzziness(value);
    onFuzzinessChange(value);
  };

  useEffect(() => {
    setFuzziness(defaultValue);
  }, [defaultValue]);
  if (loading) return <Spin size="small" />;

  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <p className="display-property-label">Exact</p>
      <div
        style={{
          width: "100%",
          display: "block",
          paddingLeft: "0.8rem",
          paddingRight: "0.8rem",
        }}
      >
        <Slider
          min={0}
          max={2}
          step={1}
          value={fuzziness}
          onChange={handleChange}
        />
      </div>
      <p className="display-property-label">Broad</p>
    </div>
  );
};

//OnboardingStepperAndForm
import React from 'react';
import { Form, Input, Button } from 'antd';
import PasswordField from './PasswordField';
import OnboardingStepper from './OnboardingStepper';
import { Steps } from 'antd';



const OnboardingCluser = ({ currentStep, completion, awsConnect,children }) => {
  const clusterTitle = awsConnect 
    ? "Let's connect your AWS account to Checklynx" 
    : "Let's create your acccount";

  return (
    <div className="onboarding-box">
      <h1 className="onboarding-header">{clusterTitle}</h1>
      <div className="onboarding-grid">
        <OnboardingStepper
          currentStep={currentStep}
          completion={completion}
          awsConnect={awsConnect}
        />

        
        {children}


      </div>
    </div>
  );
};

export default OnboardingCluser;










import React, { useState,useEffect } from "react";
import { useLocation ,useNavigate} from "react-router-dom";

import { Form, Input, Button, message } from "antd";
import AppLayoutLogin from "../components/AppLayoutLogin";
import con from "../config";
import PasswordField from "../components/PasswordField";
import OnboardingCluster from "../components/OnboardingCluster";
import qs from 'qs';
import { useIncommingUser } from "../logic/onboarding";



const SignUpPage = () => {
  const [form] = Form.useForm();
  const [formIsValid, setFormIsValid] = useState(false);
  const [currentStep, setCurrentStep] = useState(0); // 1-indexed step number
  const [completion, setCompletion] = useState(false); // Example: step 1 is not yet complete
  const [regToken, setRegToken] = useState(null);
  const [awsConnect, setAwsConnect] = useState(false);



  const { signUp } = useIncommingUser(); 


  useEffect(() => {
    
    const searchParams = new URLSearchParams(window.location.search);
    console.log("searchParams", searchParams);
   
    const tokenParam = searchParams.get("x-amzn-marketplace-token");
    
    if(tokenParam){

      setRegToken(tokenParam.replace(/\s+/g, "+"));
      setAwsConnect(true);

    }

  }, []);


  const onFinish = async () => {
    try {
     
      onboardCustomer()
      
    } catch (err) {
      console.log("Error during form validation or other issues:", err);
    }
  };

  const onboardCustomer = async () => {
    try {
      console.log("create aws customer",awsConnect)
      const values = await form.validateFields();
      console.log("Form values:", values);

      const { email, password, name, surname, company_name } = values;

      const result = await signUp(email, password, name, surname, company_name,regToken);
      if (result.success) {
        console.log("Sign-up successful");
        //setCompletion(true);
        //navigate("/verifyemail", { replace: true, state: { email: email, password:password ,regToken:regToken} });

        
      } else {
        console.log("Sign-up with error:", result.error);
        const { error } = result;
       
        if (error === "UsernameExistsException") {
          message.error("An account with this email already exists.");
          
       
        form.setFields([
          {
            name: "email",
            errors: ["An account with this email already exists."],
          },
        ]);
      }
      
        
      }
    

    } catch (err) {
      console.log("Error during form validation or other issues:", err);
    }
  };

 

  const onFieldsChange = (_, allFields) => {
    const allFieldsValid = allFields.every((field) => {
      const errors = form.getFieldError(field.name);
      const valueIsNotEmpty = field.validated;
      return errors.length === 0 && valueIsNotEmpty;
    });
    setFormIsValid(allFieldsValid);
  };

  return (
    <AppLayoutLogin
      showCreateAccount={false}
      showLogIn={false}
      forceBrightMode={true}
    >
     <OnboardingCluster
        currentStep={currentStep}
        completion={completion}
        awsConnect={awsConnect}
      >
        <OnboardingForm 
          form={form} 
          onFinish={onFinish} 
          onFieldsChange={onFieldsChange} 
          formIsValid={formIsValid} 
          con={con}
        />
      </OnboardingCluster>
    
      
    </AppLayoutLogin>
  );
};

export default SignUpPage;



const OnboardingForm = ({ form, onFinish, onFieldsChange, formIsValid, con }) => (
  <div className="onboarding-containers">
    <h2>Company details: </h2>
    <h5>Already an account? <a href="/login">Log in</a> </h5>
    <div style={{ height: "1rem", flexShrink: 1 }}></div>
    <Form
      form={form}
      name="sign_in_form"
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      autoComplete="on"
      layout="vertical"
    >
      <Form.Item
        name="company_name"
        rules={[
          {
            required: true,
            message: "Please input your Company´s Name!",
          },
        ]}
      >
        <Input
          placeholder="Company´s Name"
          className="input-big"
          autoComplete="organization"
        />
      </Form.Item>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: "Please input your First Name!" },
        ]}
      >
        <Input
          placeholder="Contact Person´s First Name"
          className="input-big"
          autoComplete="given-name"
        />
      </Form.Item>
      <Form.Item
        name="surname"
        rules={[
          { required: true, message: "Please input your Surname!" },
        ]}
      >
        <Input
          placeholder="Contact Person´s Surname"
          className="input-big"
          autoComplete="family-name"
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Please input your Email!",
          },
        ]}
        validateTrigger="onBlur"
      >
        <Input
          placeholder="Email"
          className="input-big"
          autoComplete="email"
        />
      </Form.Item>
      <Form.Item>
        <PasswordField form={form} />
      </Form.Item>
      <h5>
        By proceeding, you confirm that you have read and agree to
        <a
          href={con.urls.landing_url + "/terms"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Terms and Conditions{" "}
        </a>
        and
        <a
          href={con.urls.landing_url + "/privacy"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          Privacy Policy
        </a>
        .
      </h5>
      <Form.Item>
        <Button
          type="primary"
          ghost={true}
          disabled={!formIsValid}
          className="input-big"
          htmlType="submit"
        >
          Agree and proceed
        </Button>
      </Form.Item>
    </Form>
  </div>
);



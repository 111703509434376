///users///////////////////////////////////////////////////////////////////////////////////////////////////////////
export const HelpTitleAddEditUser = "Users";
export const HelpContentAddEditUser = () => (
  <div>
     <h2 className="t-p-margin1">Get started</h2>

<p className="p-margin06">
<h3 className="t-p-margin1">User id.</h3>
    &#8226; Provide a unique User ID to maintain the same identifier across systems between your company and Checklynx. <br />
    &#8226; If not provided, Checklynx will generate a unique identifier.<br />
    <h3 className="t-p-margin1">Next steps</h3>
    &#8226; Your teammate will receive an email invitation to verify their account before proceeding. <br />
    &#8226; They will set up their password during their first login. <br />

  </p>
  

  </div>
);

///clients///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const HelpTitleAddAPIKey = "API Keys";
export const HelpContentAddAPIKey = () => (
  <div>
     <h2 className="t-p-margin1">Get started</h2>

<p className="p-margin06">
    &#8226; Enter a friendly name for the API key.<br />
    &#8226; You will get an API key value and id. in response.<br />
    &#8226; The API key value will be shown only once.<br />
    
    &#8226; Visit <a href="https://docs.checklynx.com" target="_blank" rel="noopener noreferrer">https://docs.checklynx.com</a><br />
    

  </p>
  <h3 className="t-p-margin1">Start with a Postman test</h3>
      <p className=" p-margin06">
      &#8226; Configure a get request <code>https://api.checklynx.com/v1/search </code><br />
      &#8226; Add a new key to the headers "x-api-key" and in the value paste the generated API key value <br />
      &#8226; Test with a very simple body like:
    <br />
    <pre>
      {`{
  "search_term": "name to search",
  "fuzziness": "1",
  "filters": {}
}`}
    </pre>

    &#8226; Test with somenthing a bit more complex:
    <br />
    <pre>
      {`{
  "search_term": "name to search",
  "fuzziness":"1",
  
  "filters":{
    "source_type": ["sanctions"],
    "group_type": ["individual"],
    "gender": ["male"],
    "birth_year":["1955"],
    "nationality":[ "SO"]
    
    
}}`}
    </pre>
<br />
       
      </p>
  <h3 className="t-p-margin1">Use it in your Stripe App</h3>
      <p className=" p-margin06">
      If you have installed Checklynx as a Stripe App you can use the API Key value for authentication. <br />
     Go to App settings and enter the API key value. <br />
      </p>

  <h3 className="t-p-margin1">Basic concepts</h3>
      <p className=" p-margin06">
      Our API leverages API key authorization. It ensures that your interactions with our API are both secure and efficient, aligning with industry practices for safeguarded access.
      </p>

  </div>
);

///search///////////////////////////////////////////////////////////////////////////////////////////////////////////
export const HelpTitleSearch = "Search";
export const HelpContentSearch = () => (
  <div>
    <h2 className="t-p-margin1">Basic Search</h2>

    <p className="p-margin06">
        &#8226; Type a name or identification you are looking for in the search bar.<br />
        &#8226; Searching "John" will return all targets with "John" in their name or alias  <br />
        &#8226; Searching 123456 will return all targets associated with that identity number. such tax id., passports, national id., driver licence, vessel IMO, vessel MMSI, etc. <br />
        &#8226; You can narrow down the entity type between Individual, Entity, Vessel and Aircraft. <br />
       
      </p>

    <div>
      <h3 className="t-p-margin1">Basic concepts</h3>
      <h6> Target: </h6>
      <p className=" p-margin06">
        Refers to an individual or entity that is identified and designated for
        restrictions based on their actions, affiliations, or status. This can
        include Sanctioned Individuals or Entities and Politically Exposed
        Persons
      </p>
      <h6> Sanctioned Individuals or Entities: </h6>

      <p className=" p-margin06">
        These are parties that have been placed on a sanctions list by
        governments or international bodies due to their involvement in
        activities such as terrorism, drug trafficking, corruption, or other
        internationally recognized offenses.
      </p>

      <h6> Politically Exposed Persons (PEPs): </h6>
      <p className=" p-margin06">
        These are individuals who hold a prominent public position or function,
        which can make them higher risk for involvement in corruption or bribery
        due to their position and influence. PEPs themselves are not necessarily
        subject to sanctions, but have the risks associated with their status.
      </p>

      <h6> Individual: </h6>
      <p className=" p-margin06">
        This target refers to a natural person. This can be someone directly
        involved in activities that are grounds for sanctions such as terrorism,
        corruption, or other illegal acts.
      </p>
      <h6> Entity: </h6>
      <p className=" p-margin06">
        This target refers to a legal person or organization that can be held
        legally responsible. This includes companies, governments, charities,
        non-profit organizations, and any other non-human legal entities.
      </p>
      <h6>Vessel:</h6>
<p className="p-margin06">
  This target refers to any sea or river-going ship or boat.
   It can include cargo ships, tankers, passenger ships, and other watercraft that may be involved in illegal activities such as smuggling, 
   piracy, or violating maritime sanctions.
</p>

<h6>Aircraft:</h6>
<p className="p-margin06">
  This target refers to any flying vehicle. 
  Aircraft may be involved in illegal activities like arms trafficking, smuggling, or breaching airspace restrictions as part of sanctions violations.
</p>
    </div>
  </div>
);

export const HelpTitleCustomers = "Customers";
export const HelpContentCustomers = () => (
  <div>
      <div>
     <h2 className="t-p-margin1">Get started</h2>
     <p className="p-margin06">
        &#8226; Customers in the list will be daily checked agains santions list.<br />
        &#8226; Every cutomer edit (e.g. change of name) will trigger a check agains sanctions.  <br />
        &#8226; If results in sanctions list are found, then a case will be opened and the results will be grouped under that case.  <br />
       

        &#8226; Audit log: All changes to customers, cases, and results are automatically recorded in the audit trail. <br />
        &#8226; To add a note related to a customer, use the edit function in the Audit Log.  <br />
      
       
      </p>
      </div>

      <div>
<p className="p-margin06">
<h3 className="t-p-margin1">Adding customers</h3>
    &#8226; Provide a unique Customer ID to maintain the same identifier across system between your company and Checklynx. <br />
    &#8226; If not provided, Checklynx will generate a unique identifier.<br />
   
  </p>
  
  </div>


    <div>
      <h3 className="t-p-margin1">Basic concepts</h3>
      <h6> Customer: </h6>
      <p className=" p-margin06">
      Refers to an individual or entity that is subject to screening against sanctions lists to determine if they match any designated targets 
      for legal or compliance restrictions.
      </p>
      <h6> Target: </h6>
      <p className=" p-margin06">
        Refers to an individual or entity that is identified and designated for
        restrictions based on their actions, affiliations, or status. 
      </p>
      <h6> Sanctioned Individuals or Entities: </h6>

      <p className=" p-margin06">
        These are parties that have been placed on a sanctions list by
        governments or international bodies due to their involvement in
        activities such as terrorism, drug trafficking, corruption, or other
        internationally recognized offenses.
      </p>

     

      <h6> Individual: </h6>
      <p className=" p-margin06">
        This target refers to a natural person. This can be someone directly
        involved in activities that are grounds for sanctions such as terrorism,
        corruption, or other illegal acts.
      </p>
      <h6> Entity: </h6>
      <p className=" p-margin06">
        This target refers to a legal person or organization that can be held
        legally responsible. This includes companies, governments, charities,
        non-profit organizations, and any other non-human legal entities.
      </p>
    </div>
  </div>
);


export const HelpTitleCustomerCase = "Customer cases";
export const HelpContentCustomersCase = () => (
  <div>
      <div>
     <h2 className="t-p-margin1">Get started</h2>
     <p className="p-margin06">
        &#8226; Cases related to the customer will be listed here.<br />
        &#8226; If you add results to the allowlist, they will no longer appear in future searches for this customer.   <br />
        &#8226; Audit log: All changes to customers, cases, and results are automatically recorded in the audit trail. <br />
        &#8226; To add a note related to a case, use the Case actions edit function  <br />
        &#8226; To add a note related to a customer, use the edit function in the Audit Log.  <br />
      
       
      </p>
      </div>

      <div>
<p className="p-margin06">
<h3 className="t-p-margin1">Managing cases</h3>
    &#8226;Start by assigning the case to a teammate. They will be able to view the case in the cases tab. <br />
    &#8226; Update the case status based on progress, risk, and match evaluation.  <br />
    &#8226;Once resolved, change the case status from open to closed. You can also reopen a closed case if necessary. <br />

   
  </p>
  
  </div>


    <div>
      <h3 className="t-p-margin1">Basic concepts</h3>
      <h6> Case: </h6>
      <p className=" p-margin06">
      A case is a collection of related results, investigations, and activities triggered by potential 
      matches in sanctions screening. It represents an ongoing process to assess and resolve whether the flagged results pose a compliance risk to a customer.
      </p>
      <h6> Result: </h6>
      <p className=" p-margin06">
      A result refers to a potential match found in a sanctions list. 
      It indicates that a customer or entity may have ties to restricted or sanctioned individuals, organizations, or activities.
      </p>
      
     

     

   
    </div>
  </div>
);


export const HelpTitleCases = "Cases assignment";
export const HelpContentCases = () => (
  <div>
      <div>
     <h2 className="t-p-margin1">Get started</h2>
     <p className="p-margin06">
        &#8226; All existing cases for customers will be listed here. <br />
        &#8226; You can filter cases by status and by those assigned to you.   <br />
        &#8226; Audit log: All changes to customers, cases, and results are automatically recorded in the audit trail. <br />
        &#8226; To add a note related to a case, use the Case actions edit function  <br />
        &#8226; To add a note related to a customer, use the edit function in the Audit Log.  <br />
      
       
      </p>
      </div>

      <div>
<p className="p-margin06">
<h3 className="t-p-margin1">Managing cases</h3>
    &#8226;Start by assigning the case to a teammate. They will be able to view the case in the cases tab. <br />
    &#8226; Update the case status based on progress, risk, and match evaluation.  <br />
    &#8226;Once resolved, change the case status from open to closed. You can also reopen a closed case if necessary. <br />


   
  </p>
  
  </div>


    <div>
      <h3 className="t-p-margin1">Basic concepts</h3>
      <h6> Case: </h6>
      <p className=" p-margin06">
      A case is a collection of related results, investigations, and activities triggered by potential 
      matches in sanctions screening. It represents an ongoing process to assess and resolve whether the flagged results pose a compliance risk to a customer.
      </p>
      <h6> Result: </h6>
      <p className=" p-margin06">
      A result refers to a potential match found in a sanctions list. 
      It indicates that a customer or entity may have ties to restricted or sanctioned individuals, organizations, or activities.
      </p>
      
     

     

   
    </div>
  </div>
);


export const HelpTitleSettings = "Settings";
export const HelpContentSettings = () => (
  <div>
      <div>
     <h2 className="t-p-margin1">Get started</h2>
     <p className="p-margin06">
        &#8226; Use this section to configure your settings according to your compliance needs and risk assesment. <br />
        &#8226; In case new sources are added, they will be automatically included in the search criteria. <br />
       
    
      </p>
      </div>




    <div>
      <h3 className="t-p-margin1">Basic concepts</h3>
      <h6> Fuzziness: </h6>
      <p className=" p-margin06">
      &#8226; Exact Match (0): Performs strict matching, only identifying targets that exactly match the provided search terms.
       This approach ensures high accuracy but may return fewer results, making it suitable for highly precise screening needs. <br />
      &#8226;Moderate Match (1): Allows slight variations in the search terms, expanding the scope to capture targets with minor differences or discrepancies.
       This option strikes a balance between accuracy and result volume, tolerating small inconsistencies while maintaining reasonable precision. <br />
      &#8226; Broad Match (2): Widens the search significantly by accepting broader variations in the terms, capturing the maximum number of results, 
      including potential false positives. This level is ideal for identifying potential risks but may require additional review to filter out irrelevant matches.
      </p>
     
      
     

     

   
    </div>
  </div>
);

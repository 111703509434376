import React from 'react';
import { Steps} from 'antd';
import { LoadingOutlined, CheckCircleOutlined,UserOutlined,SolutionOutlined } from '@ant-design/icons';
import useWindowSize from "./useWindowSize";




const { Step } = Steps;




const OnboardingStepper = ({ className,currentStep, completion,awsConnect }) => {

const { isMobile,isSmall } = useWindowSize();
const title3 = awsConnect 
    ? "Finish in aws console" 
    : 'Payment details';

  const steps = [
    
    { title: 'Account details',  },
    { title: 'Confirm email',   },
    { title: title3,  },
  ];

  // Update the status and icons of each step based on currentStep and completion
  for (let i = 0; i < steps.length; i++) {
    if (i < currentStep) {
      steps[i].status = 'finish'; // All previous steps are marked finished
      steps[i].icon = <UserOutlined />;
    } else if (i === currentStep) {
      if (completion) {
        steps[i].status = 'finish'; // Current step is marked finished if completed
        steps[i].icon = <CheckCircleOutlined />;
      } else {
        steps[i].status = 'process'; // Current step is in process if not completed
        
      }
    } else {
      steps[i].status = 'wait'; // Future steps are waiting
    }
  }

  return (
    <div className='onboarding-stepper'>
    {isSmall ? (
       <Steps direction="horizontal" responsive={false} 
       className={className} current={currentStep} items={steps.map(step => ({
       
        status: step.status,
       
      }))} />
    ) : (
        <Steps direction="vertical" responsive={false} 
            className={className} current={currentStep} items={steps.map(step => ({
            title: step.title, // assuming vertical steps should have titles
            status: step.status,
            
          }))} />
     
    )}
  </div>
  );
};

export default OnboardingStepper;

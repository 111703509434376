import React from 'react';
import AppLayout from '../components/AppLayout';






const AboutPage = () => {

  return (
    <AppLayout >
      {/* Replace with actual users content */}
      
     
     
     
    </AppLayout>
  );
};

export default AboutPage;

// TenantContext.js
import React, { createContext, useState, useContext } from 'react';
import { api } from "../API/API";
import { Tenant } from "../models/Tenant"; // Import the Tenant model

const TenantContext = createContext();

export const useTenantContext = () => useContext(TenantContext);

export const TenantProvider = ({ children }) => {
  const [tenant, setTenant] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch tenant settings
  const fetchTenant = async () => {
    setLoading(true);
    try {
      const response = await api.getTenant();
      if (response.success) {
        const tenant =new Tenant(response.body); 
        setTenant(tenant);
      } else {
        console.error('Failed to fetch tenant settings');
      }
    } catch (error) {
      console.error('Error fetching tenant settings:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Update tenant settings
  const updateTenant = async (tenant) => {
    setLoading(true);
    try {
      const tenantModel = new Tenant(tenant);
      const response = await api.updateTenant(tenantModel.toBackendFormat()); // Convert format
      if (response.success) {
        setTenant(new Tenant(response.body));
      } else {
        console.error('Failed to update tenant settings');
      }
    } catch (error) {
      console.error('Error updating tenant settings:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TenantContext.Provider value={{ tenant, fetchTenant, updateTenant, loading, error }}>
      {children}
    </TenantContext.Provider>
  );
};

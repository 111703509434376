import React, { useState, useEffect, useRef } from "react";

const OverFlowLayout = ({ children }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      const current = containerRef.current;
      const isOverflowing = current.scrollHeight > current.clientHeight || current.scrollWidth > current.clientWidth;
      setIsOverflowing(isOverflowing);
    };

    checkOverflow();

    // Optional: Re-check on window resize if your layout is responsive
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [children]); // Re-run effect if children change

  return (
    <div className="column-container-flex dir-col">
      <div
        ref={containerRef}
        className="column-container-overflow"
        style={{ paddingRight: isOverflowing ? '3px' : '10px' }}
      >
        <div style={{ height: '0', width: "100%", flexShrink: 0 }}>
          {children}
        </div>
      </div>
      <div style={{ height: '1rem', flexShrink: 1 }}></div>
    </div>
  );
};

export default OverFlowLayout;

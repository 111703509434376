// StripeCheckout.jsx
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import React from 'react';
import { api } from '../API/API';



export default function StripeCheckout({ className }) {


    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    const fetchClientSecret = async () => {
        const data = {
            "billing_method": "stripe"
        };
        console.log("before call in separated component stripecheckout", data);
        
        try {
            const response = await api.createTenantSubscription(data);
            console.log("response", response);
            if (response.success) {
                console.log("secret",response.body.session.client_secret);
                return response.body.session.client_secret;
            } else {
                console.error('Failed to create a checkout session:', response);
                
            }
        } catch (error) {
            console.error('Error during the checkout process:', error);
            
        }
    };

    const options={fetchClientSecret}

    

    return (
        
        <div className={className}>
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>

            <EmbeddedCheckout></EmbeddedCheckout>
            
        </EmbeddedCheckoutProvider>
        </div>

    );
}

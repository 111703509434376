import React, { useState } from "react";
import { Button, Col, Dropdown, Modal } from "antd";
import { LeftOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";

const ColumnContainer = ({
  colsize,
  title,
  buttonLabel,
  onButtonClick,
  children,
  showBackButton,
  onBackClick,
  showDelete,
  onDelete,
  helpIcon,
  handleShowHelpToggleAddEditUser,
  itemNameDelete,
  disableDelete,
}) => {
  // State to manage modal visibility
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  // Function to handle delete click
  const handleDeleteClick = () => {
    setIsModalDeleteOpen(true); // Open the modal
  };

  // Function to handle modal's "OK" button click
  const handleOkDelete = () => {
    onDelete();
    setIsModalDeleteOpen(false); // Close the modal
    // Add your delete logic here
  };

  // Function to handle modal's "Cancel" button click
  const handleCancelDelete = () => {
    setIsModalDeleteOpen(false); // Close the modal
  };

  let items = [{ label: "Delete", key: "1", onClick: handleDeleteClick }];
  if (disableDelete === true) {
    items = items.filter((item) => item.label !== "Delete");
  }
  // Pass the items to the menuProps
  const menuProps = {
    items,
  };

  return (
    <Col xs={24} sm={24} md={colsize}>
      <Modal
        title="Confirm Delete"
        open={isModalDeleteOpen}
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
      >
        <p>
          This will permanently remove the {itemNameDelete} data. This action
          cannot be undone.{" "}
        </p>
      </Modal>

      <div className="column-container-flex-main ">
        <div className="column-container-header">
          {helpIcon && (
            <InfoCircleOutlined
              onClick={handleShowHelpToggleAddEditUser}
              className="info-icon auto-text-color right-char-margin "
            />
          )}
          {showBackButton && (
            <Button
              onClick={onBackClick}
              type="ghost"
              icon={<LeftOutlined />}
              size="large"
            />
          )}

          <div className="column-container-header-text">
            <h4>{title}</h4>
          </div>

          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            {buttonLabel && (
              <Button
                type="primary"
                ghost={true}
                onClick={onButtonClick}
                icon={<PlusOutlined />}
              >
                {buttonLabel}
              </Button>
            )}
            {showDelete && (
              <Dropdown menu={menuProps}>
                <Button type="ghost" size="large" icon={<MoreOutlined />} />
              </Dropdown>
            )}
          </div>
        </div>

        <div className="column-container-flex ">
          {children}
          </div>
      </div>
    </Col>
  );
};

export default ColumnContainer;

/**
 * @param {Customer} data - The customer object to update with checks.
 * @returns {Promise<void>}
 */
import React, { useState,useEffect } from "react";
import { Button, Divider } from "antd";
import { FormTargetSelector } from "./CustomerForm.jsx";

import { showNotification,areObjectsEqualForPatch } from "./Utils.jsx";

import {Customer} from "../models/Customer.js"
import { useCustomerContext } from '../contexts/CustomerContext'; 



const UserForm = ({
  customer,
  mode,
  onSubmit,
  onCancelForm,
  onSubmitLabel,
  onCancelLabel,
}) => {
  const ObjectName="Customer"
  const ObjectNameL="customer"

  const [issavedisabled, setIsSaveDisabled] = useState(true);
  const [itemData, setItemData] = useState();
  const { createCustomer,updateCustomer, loading } = useCustomerContext();




  const handleSubmit = async () => {
    try {
     
      let response;
      if (mode === "add") {
        response =await createCustomer(itemData);
       
       
      } else if (mode === "edit") {
        let areEqual
        areEqual=areObjectsEqualForPatch(customer, itemData)
        
        if (areEqual){
          onSubmit(itemData)
          return
         }
        
         response = await updateCustomer(itemData.id,itemData );
      }

      if (response.success) {
        
        
        onSubmit(response.customer)
        return;
      } else {
        console.log("response", response);
        switch (response.body.code) {
          case "409-CUSTOMER_ID-ALREADY-EXISTS":
            showNotification(
              "error",
              `${ObjectName}id. already exists!` ,
              `${ObjectName}id. must be unique in your company. Leave customer id. empty to auto-generate.` 
            );
            break;
          default:
            showNotification(
              "error",
              `Failed ${ObjectNameL}.`,
              response.body.message || "An unexpected error occurred."
            );
        }
      }
    } catch (error) {
      showNotification(
        "error",
        `Failed ${ObjectNameL}.`,
        "An internal error occurred. Please try again."
      );
    }
  };

  const dataChanged = (currentData) => {
    
      
    let propertiesCondition = currentData.name.length > 2;

   
if (propertiesCondition ) {
  setIsSaveDisabled(false); // Enable the save button
} else {
  setIsSaveDisabled(true);  // Disable the save button
}

setItemData(currentData);
  };



 

  return (
    <div className="column-container-flex dir-col">
      {/* This div contains the first part of the form */}
      <div className="column-container-overflow ">
        <FormTargetSelector
          isMobile={false}
          showSourceType={false}
          showName={true}
          ContainerClassName="createcustomercontainer"
          onDataChanged={dataChanged}
          customer={customer}
          mode={mode}
        />
      </div>
      <Divider />
      <div className="form-layout-spacer-button-bottom">
        <Button
          type="primary"
          onClick={handleSubmit}
          className="submit-button"
          loading={loading}
          disabled={issavedisabled}
        >
          {onSubmitLabel}
        </Button>
        <Button type="default" onClick={onCancelForm} className="submit-button">
          {onCancelLabel}
        </Button>
      </div>
    </div>
  );
};

export default UserForm;

import React, { useState, useEffect,useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { Row, Drawer } from "antd";
import AppLayout from "../components/AppLayout";
import UsersTable from "../components/UsersTable";
import UserFormLogic from "../components/UserFormLogic.jsx";
import { UserDetailCard } from "../components/CardViews";
import {AuthContext } from '../contexts/authContext.js'; // Adjust the import path as needed



import ColumnContainer from "../components/ColumnContainer";
import {
  HelpTitleAddEditUser,
  HelpContentAddEditUser,
} from "../components/HelpText";

import { useUserContext } from '../contexts/UserContext';
import { showNotification } from "../components/Utils.jsx";


const useColumnDisplay = (selectedItem, isMonoColumn, mode) => {
  const isItemSelected = !!selectedItem; // Explicitly convert to boolean
  const showFirstColumn = !isMonoColumn || (!isItemSelected && mode !== "add");
  const showSecondColumn =
    isItemSelected ||
    mode === "add" ||
    mode === "edit" ||
    (isMonoColumn && mode === "add");
  return { showFirstColumn, showSecondColumn };
};

const CustomersPage = () => {
  const ObjectName = "User";
  const ObjectNameL = "user";

  const [selectedItem, setSelectedItem] = useState(null);
  const [formDrawerVisible, setFormDrawerVisible] = useState(false);

  const [mode, setMode] = useState("view"); // Modes: view, add, edit
  const isCard = useMediaQuery({ maxWidth: 1000 });
  const isMonoColumn = useMediaQuery({ maxWidth: 800 });
  const [disableDelete,setDisableDelete]=useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [helpTitle, setHelpTitle] = useState("");
  const [helpContent, setHelpContent] = useState("");
  const [pagination, setPagination] = useState({
    cursor: null,
    limit: 20,
    
});
  const {userInfo} = useContext(AuthContext);

  const {  fetchUsers,userList,deleteUser, error } = useUserContext();
  
  

  const toggleEditPanelCollapsed = () => {
    setFormDrawerVisible(!formDrawerVisible); // Toggle menu drawer for mobile
  };

  const handleShowHelpToggleAddEditUser = () => {
    setHelpTitle(HelpTitleAddEditUser);
    setHelpContent(HelpContentAddEditUser);
    handleShowHelpToggle();
  };

  const handleShowHelpToggle = () => {
    setShowHelp((prevShowHelp) => !prevShowHelp);
  };

  const handleItemSelect = (item) => {
    if  (item.id === userInfo.id){
      setDisableDelete(true)}
    else
      {setDisableDelete(false)}
   
    setSelectedItem(item);
    setMode("view");
  };
  const handleAddItem = () => {
    setMode("add");
    setFormDrawerVisible(true);
  };
  const handleItemDelete = async (item) => {
    try {
      const response_delete= await deleteUser(item.id)
      if (response_delete.success) {
        showNotification(
          "success",
          "Done!",
          `${ObjectName} deleted successfully`
        );
      }
     
    } catch (error) {
      console.error("Failed to delete:", error);
    }
  };

  const handleOnSubmit = (item) => {
    
    setFormDrawerVisible(false);
    setMode("view");
  };

  const handleCancelForm = () => {
    setMode("view");
    setFormDrawerVisible(false);
  };
  const handleEdit = () => {
    console.log("mode", mode);
    setMode("edit");
    setFormDrawerVisible(true);
  };

  const { showFirstColumn, showSecondColumn } = useColumnDisplay(
    selectedItem,
    isMonoColumn,
    mode
  );

  

 
  const Fetch = async (initial = false) => {
    try {
        await fetchUsers(initial)
    } catch (error) {
        console.error("Error fetching item:", error);
    }
};

  useEffect(() => {
    console.log("userList",userList)
    if (userList.length === 0) {
      Fetch(true);
    }
  }, []); // Em

 
  return (
    <AppLayout
      handleShowHelpToggle={handleShowHelpToggle}
      showHelp={showHelp}
      helpTitle={helpTitle}
      helpContent={helpContent}
    >
      <Row gutter={10} style={{ display: "flex", flex: "1 1 auto" }}>
        {showFirstColumn && (
          <ColumnContainer
            colsize={14}
            title={`${ObjectName}s`}
            helpIcon={true}
            handleShowHelpToggleAddEditUser={handleShowHelpToggleAddEditUser}
            buttonLabel={`${ObjectNameL}`}
            onButtonClick={handleAddItem}
            showBackButton={false}
            
          >
            <UsersTable
              data={userList}
              pagination={pagination}
              Fetch={Fetch}
              
              onSelectItem={handleItemSelect}
              isCard={isCard}
            />
          </ColumnContainer>
        )}
        {showSecondColumn && (
          <ColumnContainer
            colsize={isMonoColumn ? 24 : 10}
            itemNameDelete={`${ObjectNameL}'s`}
            title={mode === "view" ? `${selectedItem.name}  ${selectedItem.surname}` : ""}
            showBackButton={
              mode === "add" ? false : mode === "edit" ? false : isMonoColumn
            }
            onBackClick={() => setSelectedItem(null)}
            showDelete={!disableDelete} // Set showDelete based on mode
            onDelete={() => handleItemDelete(selectedItem)}
            disableDelete={disableDelete}
          >
            <div className="dir-col">
            <UserDetailCard item={selectedItem} onEdit={handleEdit} />
            </div>
          </ColumnContainer>
        )}
      </Row>

      <Drawer
        className="drawer-add-edit"
        placement="right"
        closable={true}
        maskClosable={false}
        mask={true}
        title={
          <>
            {mode === "add"
              ? `Create ${ObjectNameL}`
              : mode === "edit"
              ? `Edit ${ObjectNameL}`
              : ""}
          </>
        }
        onClose={toggleEditPanelCollapsed}
        open={formDrawerVisible}
        width={500}
      >
        <UserFormLogic
          data={selectedItem}
          mode={mode}
          onSubmit={handleOnSubmit} //user clicked submit edit changes
          onSubmitLabel="Save"
          onCancelLabel="Cancel"
          onCancelForm={handleCancelForm}
        />
      </Drawer>
    </AppLayout>
  );
};

export default CustomersPage;

import React, { useState, useRef, useEffect } from "react";
import { Layout, Button, Menu, Popover } from "antd";
import { api } from "../API/API.js";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useAuth } from "../logic/useAuth.js"; // Adjust the path as per your file structure
import { AuthContext } from "../contexts/authContext.js"; // Adjust the import path as needed
import { useContext } from "react";
import { SimpleModal } from "../components/Modals.jsx";

const { Header } = Layout;

const HeaderComponent = ({ toggleCollapsed, collapsed, isMobile }) => {
  const { userLogOut } = useAuth();
  const { tenantInfo, userInfo } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [manageSubscriptionTitle, setManageSubscriptionTitle] = useState("");
  const [manageSubscriptionContent, setManageSubscriptionContent] =
    useState("");
  const [popOverMenuVisible, setPopOverMenuVisible] = useState(false);
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopOverMenuVisible(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef]);

  const fetchSessionUrl = async () => {
    const result = await api.manageTenantSubscription();
    if (result.success) {
      window.location.href = result.body.session;
      setIsModalVisible(false);
    }
  };

  const showModal = () => {
    if (tenantInfo.billing_method === "stripe") {
      setManageSubscriptionTitle("Connecting to the billing platform");
      fetchSessionUrl();
    } else {
      setManageSubscriptionTitle("Manage your subscription in AWS marketplace");
      setManageSubscriptionContent(
        "Go to your AWS marketplace subscriptions and manage your product directly from there."
      );
    }
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const selectAction = async ({ key }) => {
    switch (key) {
      case "1": {
        setPopOverMenuVisible(false);
        showModal();

        break;
      }
      case "2": {
        // Sign Out
        await userLogOut();
        break;
      }
      default:
        console.log("No action found for the key:", key);
    }
  };

  const togglePopOverMenu = () => {
    if (popOverMenuVisible) {
      setPopOverMenuVisible(false);
    } else {
      setPopOverMenuVisible(true);
    }
  };

  const menu = (
    <div className="userMenuContent">
      <h4> {`${userInfo.name} ${userInfo.surname}`} </h4>
      <h5> {userInfo.email} </h5>
      <div style={{ height: "1rem" }}></div>
      <Menu onClick={selectAction} className="userMenuContainerItems">
        <Menu.Item key="1" icon={<SettingOutlined />}>
          Manage subscription
        </Menu.Item>

        <Menu.Item key="2" icon={<LogoutOutlined />}>
          Sign Out
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Header className=" header ">
      <SimpleModal
        title={manageSubscriptionTitle}
        content={manageSubscriptionContent}
        isVisible={isModalVisible}
        closeModal={closeModal}
      />

      <div className="  header-container ">
        <Button
          type="default"
          size="large"
          onClick={toggleCollapsed}
          className="header-button"
        >
          {isMobile ? (
            <MenuUnfoldOutlined className="header-icon font-big" />
          ) : collapsed ? (
            <MenuUnfoldOutlined className="header-icon font-big" />
          ) : (
            <MenuFoldOutlined className="header-icon font-big" />
          )}
        </Button>

        <div className="header-font-size  flex left-padding1 ">Checklynx </div>
      </div>

      <div className="  header-container ">
        <div className="hide-small-screen">
          <h4 className="header-text-color">
            {userInfo && userInfo.name} <span>|</span>{" "}
            {tenantInfo && tenantInfo.name}
          </h4>
        </div>

        <div className="side-padding3-0 user-menu-popOver" ref={popoverRef}>
          <Popover
            placement="bottom"
            arrow={false}
            open={popOverMenuVisible}
            content={menu}
            trigger="click"
            getPopupContainer={() => popoverRef.current}
          >
            <Button
              type="default"
              shape="circle"
              onClick={togglePopOverMenu}
              icon={<UserOutlined className="header-icon" />}
            />
          </Popover>
        </div>
      </div>
    </Header>
  );
};

export default HeaderComponent;

// models/Tenant.js
export class Tenant {
    constructor(data) {
      this.tenantId = data.tenant_id;
      this.name = data.name;
      this.email = data.email;
      this.billingMethod = data.billing_method;
      this.billingPeriodEnd = data.billing_period_end;
      this.cancelAtPeriodEnd = data.cancel_at_period_end;
      this.createdAt = data.created_at;
     
      this.subscriptionActive = data.subscription_active;
      this.updatedAt = data.updated_at;
  
      // Settings structure for ongoing monitoring and search preferences
      // Settings structure for ongoing monitoring and search preferences
    this.settings = {
        ongoingMonitoring: {
          fuzziness:
            data.settings?.ongoing_monitoring?.fuzziness !== undefined
              ? Number(data.settings.ongoing_monitoring.fuzziness)
              : data.settings?.ongoingMonitoring?.fuzziness !== undefined
              ? Number(data.settings.ongoingMonitoring.fuzziness)
              : null,
          excludeSanctionSourceId:
            data.settings?.ongoing_monitoring?.exclude_sanction_source_id ||
            data.settings?.ongoingMonitoring?.excludeSanctionSourceId ||
            [],
        },
        search: {
          fuzziness:
            data.settings?.search?.fuzziness !== undefined
              ? Number(data.settings.search.fuzziness)
              : data.settings?.search?.fuzziness !== undefined
              ? Number(data.settings.search.fuzziness)
              : null,
          excludeSanctionSourceId:
            data.settings?.search?.exclude_sanction_source_id ||
            data.settings?.search?.excludeSanctionSourceId ||
            [],
        },
      };
    }
    
  
    // Optional: Create methods to handle complex transformations or validations
    isActive() {
      return this.subscriptionActive && !this.cancelAtPeriodEnd;
    }
 
  

    toBackendFormat() {
        return {
        tenant_id: this.tenantId,
        name: this.name,
        email: this.email,
        billing_method: this.billingMethod,
        billing_period_end: this.billingPeriodEnd,
        cancel_at_period_end: this.cancelAtPeriodEnd,
        created_at: this.createdAt,
        stripe_customer_id: this.stripeCustomerId,
        subscription_active: this.subscriptionActive,
        updated_at: this.updatedAt,
        settings: {
            ongoing_monitoring: {
                fuzziness: this.settings.ongoingMonitoring.fuzziness?.toString() || null, // Convert to string for backend
                exclude_sanction_source_id: this.settings.ongoingMonitoring.excludeSanctionSourceId || [], // Convert to string for backend
                },
            search: {
                    fuzziness: this.settings.search.fuzziness?.toString() || null, // Convert to string for backend
                    exclude_sanction_source_id: this.settings.search.excludeSanctionSourceId || [], // Convert to string for backend
                    }
        },
        };
    } 


}
import React, { useState, useEffect } from "react";

import { useMediaQuery } from "react-responsive";
import { Row,Alert } from "antd";
import AppLayout from "../components/AppLayout";
import { Customer } from "../models/Customer.js";
import { Result, Check } from "../models/CheckResult.js";
import { ResultCard } from "../components/CardViews";
import {
  CheckActionsCard,
  CheckDetailCard,
  ResultActionsCard,
  CheckResultActionsCard,
} from "../components/CustomerCardViews.jsx";

import { useParams } from "react-router-dom";
import CustomBreadcrumb from "../components/CustomBreadcrumb.jsx";
import CustomerScans from "../components/CustomerScans";

import ColumnContainer from "../components/ColumnContainer";
import {
  HelpTitleCustomerCase,
  HelpContentCustomersCase,
} from "../components/HelpText";

import Notes from "../components/Notes";
import { useCustomerContext } from '../contexts/CustomerContext'; 


const useColumnDisplay = (selectedItem, isMonoColumn, mode) => {
  const isItemSelected = !!selectedItem && !!selectedItem.id;
  const showFirstColumn = !isMonoColumn || (!isItemSelected && mode !== "add");
  const showSecondColumn =
    isItemSelected ||
    mode === "add" ||
    mode === "edit" ||
    (isMonoColumn && mode === "add");
  return { showFirstColumn, showSecondColumn };
};

const CustomersDetailPage = () => {
  const ObjectNameL = "customer";
  const ListName = "customerList";
  
  const { customerId } = useParams();
  const { caseId } = useParams();
  const { resultId } = useParams();

  const [selectedCustomer, setSelectedCustomer] = useState(
    null || new Customer({})
  );
  const [selectedResult, setSelectedResult] = useState(null || new Result({}));
  const [selectedCheck, setSelectedCheck] = useState(new Check({}));
  const [selectedCheckId, setSelectedCheckId] = useState(null);
  const [selectedResultId, setSelectedResultId] = useState(null);
  const [showSelectedCheckResultInView, setShowSelectedCheckResultInView] =
    useState(true);
  const [showResultInDetail, setShowResultInDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [secondColumnTitle, setSecondColumnTitle] = useState("");

  const [loadingAllowResult, setLoadingAllowResult] = useState(false);

  const [formDrawerVisible, setFormDrawerVisible] = useState(false);

  const [mode, setMode] = useState("view"); // Modes: view, add, edit
  const isCard = useMediaQuery({ maxWidth: 1000 });
  const isMonoColumn = useMediaQuery({ maxWidth: 800 });
  const isMonoColDetails = useMediaQuery({ maxWidth: 1600 });

  const [showHelp, setShowHelp] = useState(false);
  const [helpTitle, setHelpTitle] = useState("");
  const [helpContent, setHelpContent] = useState("");
  const { customerList,getCustomer,updateCustomerCheck,updateCustomerAllowList,loading: loadingCustomers } = useCustomerContext();


  const { showFirstColumn, showSecondColumn } = useColumnDisplay(
    selectedItem,
    isMonoColumn,
    mode
  );

 

  const toggleEditPanelCollapsed = () => {
    setFormDrawerVisible(!formDrawerVisible); // Toggle menu drawer for mobile
  };

  const handleShowHelpToggleAddEditUser = () => {
    setHelpTitle(HelpTitleCustomerCase);
    setHelpContent(HelpContentCustomersCase);
    handleShowHelpToggle();
  };

  const handleShowHelpToggle = () => {
    setShowHelp((prevShowHelp) => !prevShowHelp);
  };

  const handleItemResultSelect = async (itemResult) => {
    setShowResultInDetail(true);
    setSelectedResult(itemResult);
    setSelectedResultId(itemResult.id);
    setSelectedItem(itemResult); //consumed by usecolumn for correct column view
    setSecondColumnTitle("Result");
  };

  const handleItemCheckSelect = async (itemCheck) => {
    if (isCard) {
      setSelectedCheckId(itemCheck.id);
      setSelectedItem(null);
      setSecondColumnTitle("Case");
    } else {
      setSelectedCheck(itemCheck);
      setSelectedCheckId(itemCheck.id);
      setSelectedItem(itemCheck);
      setSecondColumnTitle("Case");
    }
    setSelectedResult(null);
    setSelectedResultId(null);
    setShowResultInDetail(false);
  };

  const handleShowCheckInDetailWhenIsCard = async (itemCheck) => {
    setSelectedCheck(itemCheck);
    setSelectedCheckId(itemCheck.id);
    setSelectedResult(null);
    setSelectedResultId(null);
    setShowResultInDetail(false);
    setSelectedItem(itemCheck);
    setSecondColumnTitle("Case");
  };

  const handleAllowlistChange = async ({ checked, resultId }) => {
    setLoadingAllowResult(true);
    const updatedCustomer = await updateCustomerAllowList({
      customerId: selectedCustomer.id,
      caseId: selectedCheckId,
      allowlist: resultId,
      checked: checked,
    });
    setSelectedCustomer(updatedCustomer);

    setLoadingAllowResult(false);
  };

  const handleUpdateCustomerCheck = async ({ checkId, matchStatus,  riskLevel, progress, caseStatus,assignee,note }) => {

    const updatedCustomer = await updateCustomerCheck({customerId:selectedCustomer.id,
      checkId: checkId,
      riskLevel: riskLevel,
      matchStatus: matchStatus,
      progress: progress,
      caseStatus: caseStatus,
      assignee:assignee,
      note:note
    });
    //setSelectedCustomer(updatedCustomer);
    
  };

  const handleonShowResultInActiveCheck = async (showResultStatus) => {
    setShowSelectedCheckResultInView(showResultStatus);
  };


  useEffect(() => {
    // Check if customer is selected and has cases
    if (selectedCustomer?.id && selectedCustomer.cases?.length) {
      if (selectedCheckId === null) {
        // Select the first case if none is selected
        handleItemCheckSelect(selectedCustomer.cases[0]);
      } else if (caseId) {
        const selectedCase = selectedCustomer.cases.find((c) => c.id === caseId);
        if (selectedCase) {
          handleItemCheckSelect(selectedCase);
          if (resultId) {
            const selectedResult = selectedCase.results.find((r) => r.id === resultId);
            handleItemResultSelect(selectedResult);
          }
        }
      }
    } else {
      // Fetch customer if not already fetched or selected
      const fetchCustomer = async () => {
        const customer = await getCustomer(customerId);
        if (customer) {
          setSelectedCustomer(customer);
  
          // Select the first case if there's only one case
          if (customer.cases?.length === 1) {
            handleItemCheckSelect(customer.cases[0]);
          }
        }
      };
  
      if (!selectedCustomer?.id) {
        fetchCustomer();
      }
    }
  }, [selectedCustomer, customerId, caseId, resultId]);

  
  
  useEffect(() => {
    const updateUrl = () => {
      if (!selectedCustomer?.id) return;
      let newUrl = `${host}/customers/${selectedCustomer.id}`;
      if (selectedCheckId) {
        newUrl += `/cases/${selectedCheckId}`;
      }
      if (selectedResultId) {
        newUrl += `/results/${selectedResultId}`;
      }
      window.history.replaceState(null, "", newUrl);
    };
    
    updateUrl();
  }, [selectedCheckId, selectedResultId, selectedCustomer?.id]);
  



  const host = window.location.origin;
  let breadcrumbItems = [
    { key: "1", title: "Customers ", href: `${host}/customers` },
    {
      key: "2",
      title:
        selectedCustomer && selectedCustomer.name
          ? `${selectedCustomer.name}`
          : "",
      href: `${host}/customers/${customerId}`,
    },

    {
      key: "3",
      title: selectedCheck && selectedCheckId ? `Case: ${selectedCheckId}` : "",
      href: `${host}/customers/${customerId}/cases`,
    },

    ...(selectedResultId
      ? [
          {
            key: "4",
            title: `Result: ${selectedResultId}`,
            href: `${host}/customers/${customerId}/cases/`,
          },
        ]
      : []),
  ];

  return (
    <AppLayout
      handleShowHelpToggle={handleShowHelpToggle}
      showHelp={showHelp}
      helpTitle={helpTitle}
      helpContent={helpContent}
    >
      {false && (<Alert
                message="Beta functionality. Do not use in production."  // Message displayed in the alert
                type="info"  // Type of alert styling
                showIcon  // Show an icon next to the alert message
                style={{ marginBottom: "8px"}}
                
              />)}
      <Row gutter={0} style={{ display: "flex", flex: "1 1 auto" }}>
        {showFirstColumn && (
          <ColumnContainer
            colsize={16}
            title={<CustomBreadcrumb items={breadcrumbItems} />}
            helpIcon={true}
            handleShowHelpToggleAddEditUser={handleShowHelpToggleAddEditUser}
            showBackButton={false}
          >
            <CustomerScans
              customer={selectedCustomer}
              loading={loadingCustomers}
              onSelectResultItem={handleItemResultSelect}
              onCheckClick={handleItemCheckSelect}
              activeCheckId={selectedCheckId}
              activeResultId={selectedResultId}
              isCard={isCard}
              onShowResultInActiveCheck={handleonShowResultInActiveCheck}
              showSelectedCheckResultInView={showSelectedCheckResultInView}
              onShowCheckInDetailWhenIsCard={handleShowCheckInDetailWhenIsCard}
            ></CustomerScans>
          </ColumnContainer>
        )}
        {showSecondColumn && (
          <ColumnContainer
            colsize={isMonoColumn ? 24 : 8}
            itemNameDelete={`${ObjectNameL}'s`}
            title={
              selectedItem && selectedItem.id
                ? mode === "view"
                  ? `${secondColumnTitle} id. ${selectedItem.id}`
                  : ""
                : ""
            }
            showBackButton={isMonoColumn}
            onBackClick={() => setSelectedItem(null)}
          >
            <CustomerDetailsAndAuditWrapper
              selectedCustomer={selectedCustomer}
              selectedResult={selectedResult}
              selectedCheckId={selectedCheckId}
              selectedCheck={selectedCheck}
              showResultInDetail={showResultInDetail}
              handleAllowlistChange={handleAllowlistChange}
              handleUpdateCustomerCheck={handleUpdateCustomerCheck}
              loadingAllowResult={loadingAllowResult}
              handleItemResultSelect={handleItemResultSelect}
              handleItemCheckSelect={handleItemCheckSelect}
              selectedResultId={selectedResultId}
              handleonShowResultInActiveCheck={handleonShowResultInActiveCheck}
              showSelectedCheckResultInView={showSelectedCheckResultInView}
              handleShowCheckInDetailWhenIsCard={
                handleShowCheckInDetailWhenIsCard
              }
              isCard={isCard}
              loading={loadingCustomers}
              isMonoColDetails={isMonoColDetails}
            />
          </ColumnContainer>
        )}
      </Row>
    </AppLayout>
  );
};

export default CustomersDetailPage;

export const CustomerDetailsAndAuditWrapper = ({
  selectedCustomer,
  selectedResult,
  selectedCheckId,
  selectedCheck,
  showResultInDetail,
  handleAllowlistChange,
  handleUpdateCustomerCheck,
  loadingAllowResult,

  isMonoColDetails,
  onAdd,
}) => {
  return (
    <div className="expand-height expand-width">
      {!isMonoColDetails ? (
        <div className="dir-row expand-width">
          <div className="column-container-overflow">
            <div style={{ height: "0px", width: "100%" }}>
              <CaseResultsActionsWrapper
                selectedResult={selectedResult}
                selectedCustomer={selectedCustomer}
                selectedCheckId={selectedCheckId}
                selectedCheck={selectedCheck}
                showResultInDetail={showResultInDetail}
                handleAllowlistChange={handleAllowlistChange}
                handleUpdateCustomerCheck={handleUpdateCustomerCheck}
                loadingAllowResult={loadingAllowResult}
              />
            </div>
          </div>
          <div style={{ width: "8px" }} />
          <div className="notes-container-width expand-width">
            <Notes customerId={selectedCustomer.id} overflow={true} />
          </div>
        </div>
      ) : (
        <div className="dir-col expand-width">
          <div className="expand-width column-container-overflow">
            <div style={{ height: "0px", width: "100%" }}>
              <CaseResultsActionsWrapper
                selectedResult={selectedResult}
                selectedCustomer={selectedCustomer}
                selectedCheckId={selectedCheckId}
                selectedCheck={selectedCheck}
                showResultInDetail={showResultInDetail}
                handleAllowlistChange={handleAllowlistChange}
                handleUpdateCustomerCheck={handleUpdateCustomerCheck}
                loadingAllowResult={loadingAllowResult}
              />
              <Notes customerId={selectedCustomer.id} overflow={false} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const CaseResultsActionsWrapper = ({
  selectedResult,
  selectedCustomer,
  selectedCheckId,
  selectedCheck,
  showResultInDetail,
  handleAllowlistChange,
  handleUpdateCustomerCheck,
  loadingAllowResult,
}) => {
  return (
    <div className="dir-col expand-width">
      <>
        <ResultActionsCard
          item={selectedResult}
          onAllowlistChange={handleAllowlistChange}
          loading={loadingAllowResult}
          customer={selectedCustomer}
          show={showResultInDetail}
        />
        <ResultCard item={selectedResult} show={showResultInDetail} />

        <CheckActionsCard
          show={!showResultInDetail}
          checkId={selectedCheckId}
          onUpdateCustomerCheck={handleUpdateCustomerCheck}
          loading={loadingAllowResult}
          customer={selectedCustomer}
        />
        <CheckResultActionsCard
          show={!showResultInDetail}
          check={selectedCheck}
          checkId={selectedCheckId}
          onAllowlistChange={handleAllowlistChange}
          loading={loadingAllowResult}
          customer={selectedCustomer}
        />
        <CheckDetailCard item={selectedCheck} show={!showResultInDetail} />
      </>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Input, Tooltip } from "antd";


export const KeyForm = ({
  mode,
  onDataChanged,
  data,
  
  ContainerClassName,
}) => {
  // State object to hold all form data
  const [formData, setFormData] = useState({
    name: "",
   
  });

  // Handle form data changes
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Effect to update state when mode or data changes
  useEffect(() => {
    console.log("Data in userform useEffect",data)
    
      resetValues();
    
  }, []);

  // Reset form values for create mode
  const resetValues = () => {
    setFormData({
      name: "",
     
    });
  };



  // Effect to pass updated form data to parent component
  useEffect(() => {
    onDataChanged({
      
        name: formData.name,
        
     
    });
  }, [formData]);

 

  return (
    <div className={ContainerClassName}>
      

      <><div>
          <Tooltip title={mode === "edit" ? "" : "Friendly name for this api key."}>
          <h5>
            <span style={{ color: "red", marginRight: "4px" }}>*</span>
            Name
          </h5>

            
            <Input
              placeholder="connector"
              value={formData.id}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </Tooltip>
        </div>
     
       
        
      
     
        
      
      
    
      
      
    </>
     

    
    </div>
  );
};

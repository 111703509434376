import React, { useState, useEffect ,useMemo} from "react";
import { Table, Pagination, Tooltip,Dropdown } from "antd";
import { CustomerCardView } from "./CustomerCardViews";
import { MapNationalityToLabels } from "./CountrySelector";
import { Customer } from "../models/Customer.js";
import { useCustomerContext } from '../contexts/CustomerContext'; 

import { Button } from "antd";


import {  CheckTag, CustomerRiskLevelTag } from "./Tags";
import { useListContext } from "../contexts/ListProviderContext";

const CustomersTable = ({data,  onSelectItem,  isCard,selectedItemId,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { customerList, fetchCustomers, loading ,pagination} = useCustomerContext();
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'updatedAt', // Default sorted column
    order: 'descendent',    // Default sort order
  });


  const handleSelectItem = (item) => {
    onSelectItem(item.id);
  };

  const { customerTableFilters, updateCustomerTableFilters } = useListContext();

  const customerData = useMemo(() => data.map((item) => new Customer(item)), [data]);

  const columns = useMemo(() => getColumns(sortedInfo), [sortedInfo]);


  // Effect to initialize customerTableFilters
  useEffect(() => {
    if (customerTableFilters.onlyUnClosedCases === undefined) {
      const initialQuery = {
        unAllowedResults: false,
        onlyUnClosedCases: false,
        allowedResults: false,
      };
      const queryHash = generateQueryHash(initialQuery);
      updateCustomerTableFilters((prevFilters) => ({
        ...prevFilters,
        switchCases: false,
        resultSelectOption: 2,
        unAllowedResults: initialQuery.unAllowedResults,
        allowedResults: initialQuery.allowedResults,
        anyResults: true,
        onlyUnClosedCases: initialQuery.onlyUnClosedCases,
        onlyClosedCases: false,
        onlyNoCases: false,
        queryHashes: new Set().add(queryHash),
      }));
    }
  }, [customerTableFilters, updateCustomerTableFilters]);

  // Row selection handler
  const rowSelectionStyle = (record) => ({
    onClick: () => handleSelectItem(record),
    style: {
      cursor: "pointer",
    },
    className: record.id === selectedItemId ? "selected-row" : "",
  });

  // Pagination handler
  const onPageChange = async (page, pageSize) => {
    setCurrentPage(page);
    try {
      if (pagination.cursor !== null) {
        await fetchCustomers(); // Fetch new customers based on pagination
      }
    } catch (error) {
      console.error("Failed to fetchCustomers data:", error);
    }
  };

  // Query Hash generator
  const generateQueryHash = (query) => {
    const {
      onlyUnClosedCases = false,
      onlyClosedCases = false,
      onlyNoCases = false,
      unAllowedResults = false,
      allowedResults = false,
    } = query;

    return `${onlyUnClosedCases}-${onlyClosedCases}-${onlyNoCases}-${unAllowedResults}-${allowedResults}`;
  };

  // Filter Result Selection Handler
  const onFilterResultSelection = async ({ resultSelectOption }) => {
    let query = {
      initial: false,
      onlyUnClosedCases: customerTableFilters.onlyUnClosedCases,
      onlyClosedCases: customerTableFilters.onlyClosedCases,
      onlyNoCases: customerTableFilters.onlyNoCases, // Corrected duplication
      unAllowedResults: customerTableFilters.unAllowedResults,
      allowedResults: customerTableFilters.allowedResults,
    };
    if (resultSelectOption !== undefined) {
      switch (resultSelectOption) {
        case 0:
          query.unAllowedResults = true;
          query.allowedResults = false;
          break;
        case 1:
          query.allowedResults = true;
          query.unAllowedResults = false;
          break;
        case 2:
          query.unAllowedResults = true;
          query.allowedResults = true;
          break;
        default:
          break;
      }
      updateCustomerTableFilters((prevFilters) => ({
        ...prevFilters,
        resultSelectOption: resultSelectOption,
      }));
    }

    // Update the customer filters context
    updateCustomerTableFilters((prevFilters) => ({
      ...prevFilters,
      unAllowedResults: query.unAllowedResults,
      allowedResults: query.allowedResults,
      anyResults: (query.unAllowedResults && query.allowedResults),
    }));

    const queryHash = generateQueryHash(query);
    if (!customerTableFilters.queryHashes.has(queryHash)) {
      await fetchCustomers(query);
      updateCustomerTableFilters((prevFilters) => ({
        ...prevFilters,
        queryHashes: new Set(prevFilters.queryHashes).add(queryHash),
      }));
    }
  };

  // Unclosed Cases Change Handler
  const onOnlyUnClosedCasesChange = async (value) => {
    let query = {
      initial: false,
      allowedResults: customerTableFilters.allowedResults,
      unAllowedResults: customerTableFilters.unAllowedResults,
    };

    // Adjust other properties based on the value
    if (value === false) {
      query.onlyClosedCases = true;
      query.onlyNoCases = true;
      query.onlyUnClosedCases = true;
    } else {
      query.onlyClosedCases = false;
      query.onlyNoCases = false;
      query.onlyUnClosedCases = true;
    }

    const queryHash = generateQueryHash(query);
    if (!customerTableFilters.queryHashes.has(queryHash)) {
      console.log("queryhashes", customerTableFilters.queryHashes);
      await fetchCustomers(query);
      updateCustomerTableFilters((prevFilters) => ({
        ...prevFilters,
        queryHashes: new Set(prevFilters.queryHashes).add(queryHash),
      }));
    }
    updateCustomerTableFilters((prevFilters) => ({
      ...prevFilters,
      switchCases: value,
      onlyUnClosedCases: query.onlyUnClosedCases,
      onlyClosedCases: query.onlyClosedCases,
      onlyNoCases: query.onlyNoCases,
    }));
  };

  // Data Filtering Logic
  const filteredData = useMemo(() => {
    return customerData.filter((customer) => {
      const hasAllowedResults = customer.amountAllowedResults > 0 && customer.amountUnAllowedResults < 1;
      const hasUnAllowedResults = customer.amountUnAllowedResults > 0;
      const hasUnclosedCases = customer.amountUnClosedCases > 0;

      if (customerTableFilters.switchCases === true) {
        if (!hasUnclosedCases) {
          console.log("hasUnclosedCases (open cases) is ", hasUnclosedCases);
          return false;
        }
      }
      if (customerTableFilters.anyResults) {
        return true;
      }

      if (customerTableFilters.allowedResults) {
        return hasAllowedResults;
      }
      if (customerTableFilters.unAllowedResults) {
        return hasUnAllowedResults;
      }

      return true;
    });
  }, [customerData, customerTableFilters]);

  

  const sortedData = useMemo(() => {
    let sorted = [...filteredData];
    console.log("sorted", sorted)
    if (sortedInfo && sortedInfo.columnKey && sortedInfo.order) {
      sorted.sort((a, b) => {
        let compare = 0;
        switch (sortedInfo.columnKey) {

          case "updatedAt":
            
            const dateA = new Date(a.updatedAt);
            const dateB = new Date(b.updatedAt);
            const isValidA = !isNaN(dateA);
            const isValidB = !isNaN(dateB);

            if (!isValidA && !isValidB) return 0;
            if (!isValidA) return -1;
            if (!isValidB) return 1;

            compare = dateA - dateB;
          break;
          case 'id':
            compare = a.id - b.id;
            break;
          case 'name':
            compare = a.name.localeCompare(b.name);
            break;
          case 'groupType':
            compare = a.groupType.localeCompare(b.groupType);
            break;
            case 'nationality':
              const aString = a.nationality ? a.nationality.join(', ') : '';
              const bString = b.nationality ? b.nationality.join(', ') : '';
              compare = aString.localeCompare(bString);
              break;
          case 'riskLevel':
            compare = a.riskLevel - b.riskLevel; 
            break;
          case 'birthYear':
            compare = a.birthYear - b.birthYear;
            break;
          default:
            compare = a.id - b.id;
        }
        return sortedInfo.order === 'ascend' ? compare : -compare;
      });
    }
    return sorted;
  }, [filteredData, sortedInfo]);

  // Apply Pagination
  const paginatedData = useMemo(() => {
    const startIdx = (currentPage - 1) * pagination.limit;
    const endIdx = startIdx + pagination.limit;
    return sortedData.slice(startIdx, endIdx).map((item) => ({
      ...item,
      key: item.id,
    }));
  }, [sortedData, currentPage, pagination.limit]);

  // Calculate total for pagination
  const validTotal = pagination.cursor !== null ? sortedData.length + 1 : sortedData.length;

  const handleTableChange = (paginationInfo, filters, sorter) => {
    setSortedInfo(sorter);
    // You can also handle filters here if needed
  };
  
  


  return (
    <div className="table-pagination-container">
      <div className="column-container-header filter-customers-results">
        <FilterCasesRadioGroup
          onOnlyUnClosedCasesChange={onOnlyUnClosedCasesChange}
          customerTableFilters={customerTableFilters}
          show={true}
        />

        <FilterResultsRadioGroup
          onFilterResultSelection={onFilterResultSelection}
          resultSelectOption={customerTableFilters.resultSelectOption}
          loading={loading}
          customerTableFilters={customerTableFilters}
        />
      </div>

      {isCard ? (
        <>
          <CustomerCardView
            data={paginatedData}
            selectedItemId={selectedItemId}
            handleSelectItem={handleSelectItem}
            loading={loading}
          />
          <PaginationComponent
            currentPage={currentPage}
            pagination={pagination}
            validTotal={validTotal}
            onPageChange={onPageChange}
            loading={loading}
          />
        </>
      ) : (
        <Table
          size="big"
          columns={columns}
          dataSource={paginatedData}
          onChange={handleTableChange}
          sortedInfo={sortedInfo}
          onRow={rowSelectionStyle}
          scroll={{ y: "calc(100vh - 280px)" }}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pagination.limit,
            total: validTotal,
            position: ["bottomCenter"],
            onChange: onPageChange,
          }}
        />
      )}
    </div>
  );
};


export default CustomersTable;

const getColumns = (sortedInfo) => ([
  {
    key: "id",
    title: "Cust. id.",
    dataIndex: "id",
    responsive: ["xl"],
    
    width: 100,
   
  },
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
    responsive: ["md"],
    ellipsis: true,
    
   
  },
  {
    key: "groupType",
    width: 94,
    title: "Type",
    dataIndex: "groupType",
    responsive: ["xl"],
    
    sorter: (a, b) => a.groupType.localeCompare(b.groupType),
    sortDirections: ["ascend", "descend"],
    sortOrder: sortedInfo.columnKey === 'groupType' && sortedInfo.order,
    
  },
  {
    key: "riskLevel",
      width: 110,
      title: "Risk",
      dataIndex: "riskLevel",
      responsive: ["md"],
      render: (riskLevel) => <CustomerRiskLevelTag riskLevel={riskLevel} />,

      ellipsis: true,
      sorter: (a, b) => a.riskLevel - b.riskLevel, // Numerical sorter
    sortDirections: ["ascend", "descend"],
    sortOrder: sortedInfo.columnKey === 'riskLevel' && sortedInfo.order,
    
  },
  {
    key: "nationality",
    title: "Nat./Juri.",
    dataIndex: "nationality",
    responsive: ["xl"],
    render: (nationalities) =>
      nationalities ? MapNationalityToLabels(nationalities) : "",
    ellipsis: true,
    sorter: (a, b) => {
      const aString = a.nationality ? a.nationality.join(', ') : '';
      const bString = b.nationality ? b.nationality.join(', ') : '';
      return aString.localeCompare(bString);
    },
    sortDirections: ["ascend", "descend"],
    sortOrder: sortedInfo.columnKey === 'nationality' && sortedInfo.order,
  },
  {
    key: "birthYear",
    title: "B. year",
    dataIndex: "birthYear",
    responsive: ["xxl"],
    width: 100,
    ellipsis: true,
    sorter: (a, b) => a.birthYear - b.birthYear,
    sortDirections: ["ascend", "descend"],
    sortOrder: sortedInfo.columnKey === 'birthYear' && sortedInfo.order,
    render: (text, record) => {
      // Format birth year and age together, e.g., "1990 (34)"
      return record.birthYear ? `${record.birthYear} (${record.age})` : ``;
    },
    // Sorting and Filtering can be added here later
  },
  {
    key: "updatedAt",
    title: "Updated",
    dataIndex: "updatedAt",
    responsive: ["md"],
    width: 110,
    ellipsis: true,
    render: (date) => {
      if (!date) return "";
      const validDate = new Date(date);
      if (isNaN(validDate)) return "";
      return validDate.toLocaleDateString();
    },
    sorter: (a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      const isValidA = !isNaN(dateA);
      const isValidB = !isNaN(dateB);

      if (!isValidA && !isValidB) return 0;
      if (!isValidA) return -1;
      if (!isValidB) return 1;

      return dateA - dateB;
    },
    sortDirections: ["ascend", "descend"],
    sortOrder: sortedInfo.columnKey === 'updatedAt' && sortedInfo.order,
  },
  {
    key: "openCases",
    width: 70,
    title: (
      <Tooltip title={`Amount of cases with open status`}>
        <div className=" ">
          Open <br /> cases
        </div>
      </Tooltip>
    ),
    dataIndex: "amountUnClosedCases",
    responsive: ["md"],
    ellipsis: true,
    render: (text, record) => ( 
      <div className="dir-row align-center">
        <CheckTag
          value={record.amountUnClosedCases}
          className="tagStatus"
        />
      </div>
    ),
    // Sorting and Filtering can be added here if needed
  },
  {
    key: "Results",
    width: 108,
    title: (
      <Tooltip title={`Results not allowlisted `}>
        <>
          Results 
          not <br /> allowlisted
        </>
      </Tooltip>
    ),
    dataIndex: "notAllowedResults",
    responsive: ["md"],
    ellipsis: true,
    render: (text, record) => (
      <div className="dir-row align-center">
        {record.amountAllowedResults || record.amountUnAllowedResults ? (
          <>
            <CheckTag
              value={record.amountUnAllowedResults}
              tagType="resultNotAllowed"
            />
          </>
        ) : (
          <CheckTag value="no results" tagType="resultAllowed" />
        )}
      </div>
    ),
    // Sorting and Filtering can be added here if needed
  },
]);


export const PaginationComponent = ({
  currentPage,
  pagination,
  validTotal,
  onPageChange,
}) => {
  if (validTotal === 0) {
    return null;
  }
  return (
    <Pagination
      current={currentPage}
      pageSize={pagination.limit}
      total={validTotal}
      onChange={(page, pageSize) => onPageChange(page, pageSize)}
      showSizeChanger={false}
      disabled={false}
    />
  );
};

export const FilterResultsRadioGroup = ({
  show = true,
  onFilterResultSelection,
  loading,
  customerTableFilters,
}) => {
  const onSelectOption = (key) => {
    const keyNum=Number(key.key)
    onFilterResultSelection({ resultSelectOption:keyNum }); //
  };

 

 

  const items = [
    { key: '0', label: 'Results not allowlisted', onClick: onSelectOption },
    { key: '1', label: 'All results allowlisted', onClick: onSelectOption },
    { key: '2', label: 'Any result status', onClick: onSelectOption },
  ];

  if (!show) {
    return null;
  }

  return (
    <div className="dir-row filter-customers-block">
     <Dropdown
        menu={{
          items,
        }}
        trigger={['click']}
        placement="bottomLeft"
      >
        <Button
          size="small"
          className={customerTableFilters.resultSelectOption!==2 ? "button-default-primary button-filter" : "button-default-grey button-filter"}
          
          >
          <div>
            {customerTableFilters.resultSelectOption !== undefined
              ? items.find((item) => item.key === customerTableFilters.resultSelectOption.toString())?.label // Display current selected option
              : "Select result status"}
         
          </div>
        </Button>
      </Dropdown>
    </div>
  );
};


export const FilterCasesRadioGroup = ({
  loading,
  customerTableFilters,
  onOnlyUnClosedCasesChange,
}) => {
  return (
    <div className="dir-row ">
      <Button
        size="small"
        loading={loading}
        type={customerTableFilters.switchCases ? "default" : "default"} // Use "primary" when toggled on
        className={customerTableFilters.switchCases ? "button-default-primary button-filter" : "button-default-grey button-filter"}
        onClick={() => onOnlyUnClosedCasesChange(!customerTableFilters.switchCases)} // Toggle value on click
      >
        {customerTableFilters.switchCases ? "Open cases" : "Any case status"} {/* Toggle button label */}
      </Button>
      
      <div style={{ width: '1rem' }} /> {/* Spacer */}
    </div>
  );
};

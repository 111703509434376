import React, { createContext, useState, useContext,useEffect } from 'react';
import { User } from '../models/User'; // Import the User model
import { api } from "../API/API";


const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userList, setUserList] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10, cursor: null });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchUsers = async (initial = false) => {
    setLoading(true);
    try {
      const query = {
        limit: pagination.limit,
        cursor: pagination.cursor,
      };
      const response = await api.getUsers(query); // Fetch from User model
     
      if (response.success) {
        const cursor = response.body.cursor;
        const users = response.body.users.map((userData) => new User(userData));
              
        setPagination((prev) => ({ ...prev, cursor })); // Update cursor
        if (initial) {
          setUserList(users); // Initialize user list
        } else {
          setUserList((prevList) => [...prevList, ...users]); // Extend user list
        }
      }

       
    
    

     
    } catch (error) {
      console.log('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Automatically fetch users on mount
    fetchUsers(true);
  }, []);

  const getUsers=async () =>{
    if(userList.length!==0){
    return userList;
  }else{
    await fetchUsers(true);
    return userList;
  }

  }

  const createUser = async (userData) => {
    try {
      const response = await api.createUser(userData);
      if (response.success) {
        setUserList((prevList) => [new User(response.body), ...prevList]);
      } 
      return response;
      
    } catch (error) {
    setError(error.message)
      console.error('Failed to add user:', error);
    }
  };

  const updateUser = async (userId, userData) => {
    try {
      const response = await api.updateUser(userId, userData);
      if (response.success) {
        setUserList((prevList) =>
          prevList.map((user) => (user.id === userId ? new User(response.body) : user))
        );
      }
      return response;

      
    } catch (error) {
      console.error('Failed to edit user:', error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      const response = await api.deleteUser(userId);
      if (response.success) {
        setUserList((prevList) => prevList.filter((user) => user.id !== userId));
      } 
      return response;
    } catch (error) {
      console.error('Failed to delete user:', error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        userList,
        getUsers,
        fetchUsers,
        createUser,
        updateUser,
        deleteUser,
        loading,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

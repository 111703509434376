import React, { useState, useEffect } from "react";
import AppLayout from "../components/AppLayout";
import { Divider, Button, Collapse } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { useTenantContext } from "../contexts/TenantContext";
import { FuzzSlider } from "../components/PopoverFilter"; // Import the FuzzSlider component
import RegionSourceSelector from "../components/RegionSourceSelector";
import sanctionSources from "../resources/sanctionSources.json";

import { HelpTitleSettings, HelpContentSettings } from "../components/HelpText";

const { Panel } = Collapse;

const SettingsPage = () => {
  const [showHelp, setShowHelp] = useState(false);
  const [helpTitle, setHelpTitle] = useState("");
  const [helpContent, setHelpContent] = useState("");
  const [updatedSettings, setUpdatedSettings] = useState({
    search: { fuzziness: 1, excludeSanctionSourceId: [] },
    ongoingMonitoring: { fuzziness: 1, excludeSanctionSourceId: [] },
  });
  
  const {
    tenant,
    fetchTenant,
    updateTenant, // Add updateTenant from context
    loading,
    error,
  } = useTenantContext();

  useEffect(() => {
    if (!tenant){
    fetchTenant();}
  }, []);

  useEffect(() => {
    if (tenant) {
      const set={
        search: {
          fuzziness: tenant.settings.search.fuzziness ,
          excludeSanctionSourceId: tenant.settings?.search.excludeSanctionSourceId || [],
        },
        ongoingMonitoring: {
          fuzziness: tenant.settings.ongoingMonitoring.fuzziness ,
          excludeSanctionSourceId: tenant.settings?.ongoingMonitoring.excludeSanctionSourceId || [],
        },
      }
      setUpdatedSettings(set);
    }
  }, [tenant]);


  // Handle fuzziness change
  const handleFuzzinessChange = (section, value) => {
    setUpdatedSettings((prevSettings) => ({
      ...prevSettings,
      [section]: { ...prevSettings[section], fuzziness: value },
    }));
  };

  const handleSourceSelectionChange = (section, value) => {
    console.log("section,value",section ,value) 
    
      setUpdatedSettings((prevSettings) => ({
      ...prevSettings,
      [section]: { ...prevSettings[section], excludeSanctionSourceId: value },
    }));
  };
  

  const handleSave = async () => {
      const updatedTenant = {
        ...tenant,
        settings: updatedSettings,
      };
      await updateTenant(updatedTenant);
  };
  

  const handleShowHelpToggleAddEditUser = () => {
    setHelpTitle(HelpTitleSettings);
    setHelpContent(HelpContentSettings);
    handleShowHelpToggle();
  };

  const handleShowHelpToggle = () => {
    setShowHelp((prevShowHelp) => !prevShowHelp);
  };

  return (
    <AppLayout
      handleShowHelpToggle={handleShowHelpToggle}
      showHelp={showHelp}
      helpTitle={helpTitle}
      helpContent={helpContent}
    >
      <div style={{ marginTop: "1rem"}}></div>
        <Section
          icon={<InfoCircleOutlined />}
          onIconClick={handleShowHelpToggleAddEditUser}
          sectionTitle="Settings"
          divider={false}
        >
            <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="primary" onClick={handleSave} loading={loading}>
              Save Settings
            </Button>
          </div>
        </Section>
        <div style={{ marginTop: "0rem", overflowY: "scroll" }}>

        <Section
          sectionTitle="Search in Portal"
          sectionSubtitle="Settings applied to Search functionality in the Portal"
        >
          <SubSection
            sectionTitle="Fuzziness"
            sectionSubtitle="Defines the degree of fuzziness used in search functionality within the web portal."
          >
            <Block blockLabel="">
              <FuzzSlider
              loading={loading}
                 defaultValue={updatedSettings.search.fuzziness}
                onFuzzinessChange={(value) =>
                  handleFuzzinessChange("search", value)
                }
              />
            </Block>
          </SubSection>

          <SubSection
            sectionTitle="Sanction Sources"
            sectionSubtitle="Select the sanction sources you want to include in your search when using the Portal"
            cascade={true}
            cascadeTitle="Show available sources"
          >
            <RegionSourceSelector
              sourceData={sanctionSources}
              excludedSourceIds={updatedSettings.search.excludeSanctionSourceId}
              onSelectionChange={(value) =>
                handleSourceSelectionChange("search", value)
              }
            />
          </SubSection>
        </Section>

        <Section
          sectionTitle="Customer Ongoing Monitoring"
          sectionSubtitle="Settings applied to Customer Ongoing Monitoring"
        >
          <SubSection
            sectionTitle="Fuzziness"
            sectionSubtitle="Defines the degree of fuzziness used in customer ongoing monitoring funcionality."
          >
            <Block blockLabel="">
              <FuzzSlider
              loading={loading}
                defaultValue={updatedSettings.ongoingMonitoring.fuzziness}
                onFuzzinessChange={(value) =>
                  handleFuzzinessChange("ongoingMonitoring", value)
                }
              />
            </Block>
          </SubSection>

          <SubSection
            sectionTitle="Sanction Sources"
            sectionSubtitle="Select the sanction sources you want to include in your search when using the Portal"
            cascade={true}
            cascadeTitle="Show available sources"
          >
            <RegionSourceSelector
              sourceData={sanctionSources}
              excludedSourceIds={updatedSettings.ongoingMonitoring.excludeSanctionSourceId}
              onSelectionChange={(value) =>
                handleSourceSelectionChange("ongoingMonitoring", value)
              }
            />
          </SubSection>
        </Section>

        
      </div>
    </AppLayout>
  );
};

export default SettingsPage;

export const Section = ({
  sectionTitle = "Settings Section",
  sectionSubtitle = "",
  divider = true,
  icon,
  children,
  onIconClick,
}) => {
  return (
    <div className="section-container">
      {divider && <Divider />}
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon && (
          <InfoCircleOutlined
            onClick={onIconClick}
            className="info-icon auto-text-color right-char-margin "
          />
        )}
        <p className="section-title">{sectionTitle}</p>
      </div>
      <p className="sub-section-subtitle" style={{ marginBottom: "0.8rem" }}>
        {sectionSubtitle}
      </p>

      {children}
    </div>
  );
};

export const SubSection = ({
  sectionTitle = "Settings Sub Section",
  sectionSubtitle = "Describe options",
  children,
  cascade = false,
  cascadeTitle = "Expand to view settings",
}) => {
  return (
    <>
      <p className="sub-section-title"style={{ marginBottom: "0rem", marginTop: "1.8rem" }} >
        {sectionTitle}
      </p>
      <p className="sub-section-subtitle" style={{ marginBottom: "0.8rem" }}>
        {sectionSubtitle}
      </p>

      {cascade ? (
        <Collapse>
          <Panel header={cascadeTitle} key="1">
            <div>{children}</div>
          </Panel>
        </Collapse>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

export const Block = ({
  blockLabel = "block label",
  children,
  maxWidth = "300px",
}) => {
  return (
    <div className="display-property-container" style={{ maxWidth: maxWidth }}>
      <p className="display-property-label">{blockLabel}</p>
      <div className="display-property-value">{children}</div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import AppLayout from '../components/AppLayout';
import { Spin } from 'antd';

import { api } from '../API/API';   



const CompanySettings = () => {

 
  

  return (
    <AppLayout>
<div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column'
      }}>
        <h4>Connecting to billing partner...</h4>
        <div style={{ height: "2rem", flexShrink: 1 }}></div>
        <Spin size="large" />

        
      </div>

     
    </AppLayout>
  );
};

export default CompanySettings;

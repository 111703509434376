import React, { useState, useEffect } from "react";



import CheckResultsTable from "./CustomerCheckResultsTable.jsx";

import {  ScanHolder} from './CustomerCardViews.jsx';

import OverFlowLayout from "../components/OverFlowLayout";



const CustomerScans = ({ customer,activeCheckId,activeResultId, loading, onSelectResultItem,onCheckClick, 
  isCard,onShowResultInActiveCheck,showSelectedCheckResultInView,
  onShowCheckInDetailWhenIsCard }) => {

  

  

  const handleResultClick = (result,event) => {
    if (event){
  event.stopPropagation(); 
    }
   
    onSelectResultItem(result)
    return
  };

  
  if (!customer || !customer.cases) {
    return null;
  }

  return (
    <OverFlowLayout
    children={
      customer.cases.map((check) => (
        <div  key={check.id}>
          <ScanHolder 
            loading={loading}
            check={check}
            activeCheckId={activeCheckId}
            activeResultId={activeResultId}
            isCard={isCard}
            onSelectResult={(result, event) => handleResultClick(result, event)}
            customer={customer}
            onShowResultInActiveCheck={onShowResultInActiveCheck}
            showSelectedCheckResultInView={showSelectedCheckResultInView}
            onClick={() => onCheckClick(check)} // Handle card click
            onShowCheckInDetailWhenIsCard={onShowCheckInDetailWhenIsCard}
          />
        </div>
       
      ))}
       />
    
  );

};

export default CustomerScans;


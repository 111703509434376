import React from 'react';
import { Modal, Button } from 'antd';

// First Modal: Simple design with only title and content
export const SimpleModal = ({
  title, 
  content, 
  isVisible, 
  closeModal
}) => {
  return (
    <Modal
      title={title}
      footer={null}
      open={isVisible}
      onCancel={closeModal}
    >
      <h5>{content}</h5>
    </Modal>
  );
};

// Second Modal: A different design with footer buttons and more complex content
export const CustomModalNotUsedExample = ({
  title, 
  content, 
  isVisible, 
  closeModal,
  onOk
}) => {
  return (
    <Modal
      title={title}
      open={isVisible}
      onCancel={closeModal}
      footer={[
        <Button key="back" onClick={closeModal}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onOk}>
          Confirm
        </Button>,
      ]}
    >
      <div>
        <p>{content}</p>
        <p>This modal includes more complex content and a footer with buttons.</p>
      </div>
    </Modal>
  );
};



///billingmethod
import React, { useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Form } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import AppLayoutLogin from '../components/AppLayoutLogin';
import OnboardingStepper from '../components/OnboardingStepper';
import StripeCheckout from '../components/StripeCheckOut';
import { useNavigate } from "react-router-dom";

import { useContext } from 'react';
import {AuthContext } from '../contexts/authContext.js'; // Adjust the import path as needed





const BillingMethodPage = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [showStripeCheckOut, setShowStripeCheckOut] = useState(true);
  const [showBillingSelector, setShowBillingSelector] = useState(false);
  const [showSuccessMessage, setShowSuccessYouMessage] = useState(false); 
  const { initialFetch} = useContext(AuthContext);



  const navigate = useNavigate();
  const selectOption = (option) => {
    setSelectedOption(option);
    setContinueDisabled(false);
  };



  const goToPortal = async () => {
    await initialFetch({ newCompany: true });
    navigate("/companycreated");
};

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const stripeSuccess = queryParams.get('stripesuccess');
    console.log('Query params:', location.search); // Log the entire query string
    console.log('Stripe success param:', stripeSuccess); 
    if (stripeSuccess) {
      setShowSuccessYouMessage(true); // Show thank you message when 'stripesuccess' is found
      setShowStripeCheckOut(false)
    }

    

  
  }, [location]); // Effect depends on location
  


 

 

  return (
    <AppLayoutLogin showCreateAccount={false} showLogIn={false}>

      <div className="onboarding-box">
        <h1 className="onboarding-header">Let's create an account for you</h1>
        <div className="onboarding-grid">
          <OnboardingStepper className="grid-onboarding-item" currentStep={2} />
         
          
    
    <StripeCheckout className={`onboarding-containers ${showStripeCheckOut ? '' : 'hidden'}`} />

          {showSuccessMessage && (
            <div
            className="onboarding-containers"
          >
            <div className="">
              <h1 className="text-dark">   
            
                Well done!</h1>
               
            </div>
            <div className="align-left ">
            <div style={{ height: "1rem", flexShrink: 1 }}></div>
           
             <h2 className="text-dark">
             <div style={{ height: "1rem", flexShrink: 1 }}></div>
             Your account was created succesfully</h2>
            
              <div style={{ height: "3rem", flexShrink: 1 }}></div>
              <Button
                  type="primary"
                  ghost="true"
                  onClick={goToPortal}
                  size="large"
                  className="input-big"
                >
                  Proceed to Checklynx Portal
                </Button>

                
            </div>
          </div>
          )}
        </div>
      </div>
    </AppLayoutLogin>
  );
};

export default BillingMethodPage;

import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';


const BirthYearSelector = ({ onChange,disabled,isMobile,defaultYear }) => {
  const defaultDateValue = dayjs(`${defaultYear}-01-01`);

  const currentYear = new Date().getFullYear();


  // Function to disable years outside of 1925 to current year
  const disabledDate = (current) => {
    return current && (current.year() < 1925 || current.year() > currentYear);
  };
  
// Updated onChange function to handle Day.js object and extract the year
const handleYearChange = (date, dateString) => {
  if (date) {
    const selectedYear = date.$y; // Extracting year from Day.js object
    onChange(selectedYear); // Assuming onChange expects a year value
  } else {
    onChange(undefined); // Handle clear or invalid input
  }
};

return (
  <DatePicker
    onChange={handleYearChange} // Updated to use the new handler
    picker="year"
    placement={isMobile ? "top" : undefined}
    disabled={disabled}
    style={{ width: '100%' }}
    allowClear
    disabledDate={disabledDate}
    onFocus={isMobile ? e => e.target.blur() : undefined}
    {...(defaultYear ? { value: defaultDateValue } : {})}
   // {...(defaultYear ? { defaultValue: defaultDateValue } : {})}
  />
);
};

export default BirthYearSelector;

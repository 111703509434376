//verifyemail
//after admin user has created an account to confirm email and create tenant resources
import React, { useState ,useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../logic/useAuth";
import { Form, Input, Button, message } from "antd";
import AppLayoutLogin from "../components/AppLayoutLogin";
import OnboardingCluster from "../components/OnboardingCluster";

import {useIncommingUser} from "../logic/onboarding"
import { api } from "../API/API.js";


const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [isConfimDisabled,setIsConfimDisabled]= useState(true);
  const [completion, setCompletion] = useState(false); // Example: step 1 is not yet complete
  const [loading,setLoading]= useState(false);

  const { resendVerificationCode } = useAuth();


  const location = useLocation();
  const { state } = location;
  const [form] = Form.useForm();
  const { email = "",password="",regToken="" } = state || {};
  let awsConnect = regToken ? true : false;

  const {completeOnboarding} = useIncommingUser();
//
useEffect(() => {
  // Check if there is no active user and loading is complete
  if ( !email ) {
    // navigate("/login",{ replace: true });
  }})

useEffect(() => { async function fetchData() {
      if(awsConnect){return}
      const data = {
        "billing_method": ""
      };
      //"warm up"
      
      try {
        const response = await api.createTenantSubscription(data);
        console.log("response", response);
        
      } catch (error) {
        console.error('Error during the checkout process:', error);
      }
    }

    fetchData();
  }, []); 

 

  const onVerify = async () => {
    console.log("onVerify sign-up with:");
   
    if (email ) {
      try {
        setLoading(true)
        setIsConfimDisabled(true);
        const confirmationCode = form.getFieldValue("verificationCode");
        

        const result = await completeOnboarding(email, confirmationCode,password,regToken);
        console.log("onVerify result:", result);
        if (result.error) {

       
      
          switch (result.error) {
            case 'CodeMismatchException':
              message.error('Invalid verification code.');
              form.setFields([
                {
                  name: 'verificationCode',
                  errors: ['Invalid verification code.'],
                },
              ]);
              break;
          
            case 'LimitExceededException':
              message.error('Attempt limit exceeded. Please wait 15 minutes before trying again.');
              form.setFields([
                {
                  name: 'verificationCode',
                  errors: ['Attempt limit exceeded. Please wait before trying again.'],
                },
              ]);
              break;
          
            case 'ExpiredCodeException':
              
              form.setFields([
                {
                  name: 'verificationCode',
                  errors: ['The code has expired. Request password reset again'],
                },
              ]);
              break;
            case "400-TOKEN-EXPIRED":
                message.error('The aws token has expired');
  
                break;
            case "400-TOKEN-INVALID":
                  message.error('The aws token is invalid');
    
                  break;

            case "404-TENANT-NOT-FOUND":
                  message.error('The AWS account id is not registered in the system. Try to subscribe again in AWS marketplace or contact support')
            
                  break
            case "FailedCreateTenant":
              message.error('Failed to connect to our servers. Please try again later or contact support');

              break;
            
          
            default:
              console.log("defaultuerror",result.error)
              message.error(result.error);
              break;
          }
        }
      } catch (error) {
        console.error("Unexpected error confirming sign up:", error);
        setLoading(false)
      }
      finally{
        setLoading(false)
      }
    }
  };
  const handleResendCodeClick = async () => {
    
    setIsResendDisabled(true);
    const resultResend = await resendVerificationCode(email);
   
    setTimeout(() => setIsResendDisabled(false), 120);
    if (resultResend.success) {
      message.success(`Code sent to ${email}`);
    } else {
      message.error(`Error resending code`);
    }
  };

  const handleFieldChange = (_, allFields) => {
    if (loading){
      return
    }
    console.log("handleFieldChange", allFields);
    const verificationCodeField = allFields.find(field => field.name[0] === 'verificationCode');
    const hasErrors = verificationCodeField && Array.isArray(verificationCodeField.errors) && verificationCodeField.errors.length > 0;

    try {
      if (verificationCodeField) {
        const isValid = verificationCodeField.value.length === 6 && !hasErrors;
        setIsConfimDisabled(!isValid);  // Update the state based on validity
        if(isValid){
          onVerify();}
       
      }
    } catch (error) {
      console.error(error);  // Log any errors encountered during validation
    }
  };

  return (
    <AppLayoutLogin
      showCreateAccount={false}
      showLogIn={false}
      forceBrightMode={true}
    >
     <OnboardingCluster
        currentStep={2}
        completion={completion}
        awsConnect={awsConnect}
      >
        <VerifyEmailForm
          form={form}
          state={state}
          onVerify={onVerify}
          handleFieldChange={handleFieldChange}
          handleResendCodeClick={handleResendCodeClick}
          loading={loading}
          isConfirmDisabled={isConfimDisabled}
          isResendDisabled={isResendDisabled}
        />
      </OnboardingCluster>
    
      
    </AppLayoutLogin>
  );
};

export default VerifyEmailPage;




const VerifyEmailForm = ({ form, state, onVerify, handleFieldChange, handleResendCodeClick, loading, isConfirmDisabled, isResendDisabled }) => {
  return (
   
    <div className="onboarding-containers">
      <h2>A verification code was sent to: </h2>
      <h3>{state && state.email}</h3>
      <div style={{ height: "1rem", flexShrink: 1 }}></div>

      <Form
        form={form}
        name="verify_email_form"
        autoComplete="on"
        layout="vertical"
        onFieldsChange={handleFieldChange}
      >
        <Form.Item
          name="verificationCode"
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: 'Please input the verification code!',
            },
            {
              pattern: /^[0-9]{6}$/,
              message: 'Verification code must be exactly 6 digits!',
            },
          ]}
        >
          <Input
            placeholder="Verification Code"
            className="input-big"
            autoComplete="one-time-code"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            ghost={true}
            onClick={onVerify}
            className="input-big"
            disabled={isConfirmDisabled}
            loading={loading}
          >
            Confirm
          </Button>
        </Form.Item>
        <Button
          type="default"
          disabled={isResendDisabled}
          onClick={handleResendCodeClick}
          className="input-big"
        >
          Resend code
        </Button>
      </Form>
    </div>
  );
};



import React, { useState, useEffect } from "react";
import { Layout, Drawer,Alert,Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";

import AppMenu from "./AppMenu";
import AppHeader from "./AppHeader";
import useWindowSize from "./useWindowSize";
import { CloseOutlined } from "@ant-design/icons";
import { AuthContext } from "../contexts/authContext.js"; // Adjust the import path as needed

import OverFlowLayout from "../components/OverFlowLayout";
const { Content, Sider } = Layout;

const AppLayout = ({children,
  showHelp,
  handleShowHelpToggle,
  helpTitle,
  helpContent,
}) => {
  const { isMobile, isSmall } = useWindowSize();
  const [collapsed, setCollapsed] = useState(!isMobile);
  const [drawerVisible, setDrawerVisible] = useState(false);


  const { tenantInfo} = useContext(AuthContext);
 

  useEffect(() => {
    setCollapsed(!isMobile); // Collapse for non-mobile sizes, expand for mobile
  }, [isMobile]);


  const toggleCollapsed = () => {
    if (isMobile) {
      setDrawerVisible(!drawerVisible); // Toggle menu drawer for mobile
    } else {
      setCollapsed(!collapsed); // Toggle menu collapse for non-mobile
    }
  };

  const layoutStyle = {
    width: "calc(100%)",
    maxWidth: "calc(100%)",
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    flexGrow: 1,
    overflowX: "hidden",
   
  };

  return (
    <Layout style={layoutStyle}>
      <AppHeader
        toggleCollapsed={toggleCollapsed}
        collapsed={collapsed}
        isMobile={isMobile}
      ></AppHeader>
      {isMobile ? (
        <Layout className="secondlayout">
          <Drawer
            placement="left"
            closable={true}
            maskClosable={true}
            mask={true}
            width={350}
            onClose={toggleCollapsed}
            open={drawerVisible}
            styles={{
              header: {
                padding: "1.2rem",
                color: "transparent",
                backgroundColor: "transparent",
              },
              body: { padding: 0 },
            }}
          >
            <AppMenu />
          </Drawer>
          <Layout style={{ padding: "0px 0px 0px" }}>
            <Content
              style={{
                padding: 0,
                marginLeft: 1,
                marginRight: 1,
                display: "flex",
                flexDirection:"column"
              }}
            >
              <AlertSubscription show={!tenantInfo?.subscription_active} ></AlertSubscription>
              <AlertSubscriptionCancel tenantInfo={tenantInfo} />
              {children}
            </Content>
          </Layout>

          <Drawer
            width={350}
            mask={true}
            maskClosable={false}
            closeIcon={null}
            onClose={handleShowHelpToggle}
            open={showHelp}
            styles={{
              body: { padding: 0 },
            }}
          >
            <HelpPanel
              handleShowHelpToggle={handleShowHelpToggle}
              helpTitle={helpTitle}
              helpContent={helpContent}
            />
          </Drawer>
        </Layout>
      ) : (
        <Layout>
          <Sider
            width={160}
            collapsedWidth={60}
            collapsed={collapsed}
            className="sider-menu"
            style={{ backgroundColor: "transparent" }}
          >
            <AppMenu />
          </Sider>
         
          <Content
         
            style={{  display: "flex", flexDirection:"column" }}
          >
             <AlertSubscription show={!tenantInfo?.subscription_active} ></AlertSubscription>
             <AlertSubscriptionCancel tenantInfo={tenantInfo} />

            {children}
          </Content>

          {showHelp && !isSmall ? (
            <Sider
              width={350}
              style={{
                padding: "0rem",
                backgroundColor: "transparent",
                borderLeft: "1px solid var(--color-border-grey)",
              }}
            >
              <HelpPanel
                handleShowHelpToggle={handleShowHelpToggle}
                helpTitle={helpTitle}
                helpContent={helpContent}
              />
            </Sider>
          ) : showHelp ? (
            <Drawer
              width={350}
              mask={false}
              maskClosable={false}
              closeIcon={null}
              onClose={handleShowHelpToggle}
              open={showHelp}
              styles={{
                body: { padding: 0 },
              }}
            >
              <HelpPanel
                handleShowHelpToggle={handleShowHelpToggle}
                helpTitle={helpTitle}
                helpContent={helpContent}
              />
            </Drawer>
          ) : null}
        </Layout>
      )}
    </Layout>
  );
};

export default AppLayout;

const HelpPanel = ({ handleShowHelpToggle, helpTitle, helpContent }) => {
  return (
    <div className="column-container-flex padding-right-drawer">
      <div className="help-header-container auto-text-color">
        <CloseOutlined
          onClick={handleShowHelpToggle}
          style={{
            fontSize: "24px",
            marginRight: "16px",
          }}
        />

        <h2> {helpTitle} </h2>
      </div>
      <OverFlowLayout children={helpContent} />
    </div>
  );
};


const AlertSubscription = ({ show }) => {  // Correct destructuring of props
  const navigate = useNavigate();

  const handleSubscribe = () => {
    navigate("/billingmethod");
  };
 
  return (
    <>
      {show && (  // Conditional rendering based on 'show' prop
        <Alert
          message="Missing subscription"  // Message displayed in the alert
          description="Your account has not subscribed to a payment method."  // Additional description
          type="error"  // Type of alert styling
          showIcon  // Show an icon next to the alert message
          action={
            <Button size="large" type="default" onClick={handleSubscribe}>
              Subscribe
            </Button>
          }
          
        />
      )}
    </>
  );
};
const AlertSubscriptionCancel = ({  tenantInfo }) => {

  if (tenantInfo.cancel_at_period_end === false) {
    return null;
  }



  

  return (
    <>
       
        <Alert
          
          description={`Your account is scheduled for cancelation on  ${tenantInfo.cancel_at}. Please renew it in the Manage subscription menu to avoid interruption.`}
          type="warning"
          showIcon
        />
      
    </>
  );
};


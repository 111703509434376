// Class to represent Case details within a Result
import { Customer } from "../models/Customer"; // Import the Customer model
import { Capitalize } from "../components/Utils";

class Case {
  constructor(caseData = {}) {
    this.basis = caseData.basis || null;
    this.since = caseData.since || null;
    this.updatedOn = caseData.updated_on || caseData.updatedOn || null;
    this.designationDate =
      caseData.designation_date || caseData.designationDate || null;
    this.designationAct =
      caseData.designation_act || caseData.designationAct || null;
    this.sanctionType = caseData.sanction_type || caseData.sanctionType || null;
    this.program = caseData.program || null;
    this.url = caseData.url || null;
    this.otherIds = caseData.other_ids || caseData.otherIds || null;
  }
}

class Vessel {
  constructor(vesselData = {}) {
    this.type = vesselData.type || null;
    this.flag = vesselData.flag || null;
    this.formerFlag = vesselData.former_flag || vesselData.former_flag || null;
    this.IMO = vesselData.IMO || null;
    this.MMSI = vesselData.MMSI || null;
    this.callSign = vesselData.call_sign || vesselData.callSign || null;
    this.owner = vesselData.owner || null;
    this.lenght = vesselData.lenght || null;
    this.yearBuilt = vesselData.year_built || vesselData.yearBuilt || null;
    this.type = vesselData.type || null;
    this.grossTonnage =
      vesselData.grossTonnage || vesselData.gross_tonnage || null;
    this.grossRegisteredTonnage =
      vesselData.grossRegisteredTonnage ||
      vesselData.gross_registered_tonnage ||
      null;
  }
}

class Target {
  constructor(targetData = {}) {
    this.name = targetData.name || null;
    this.firstName = targetData.first_name || targetData.firstName || null;
    this.secondName = targetData.second_name || targetData.secondName || null;
    this.thirdName = targetData.third_name || targetData.thirdName || null;
    this.otherNames =
      targetData.other_names || targetData.otherNames
        ? (targetData.other_names || targetData.otherNames).map(
            (otherName) => otherName
          )
        : null;

    this.gender = targetData.gender || null;
    this.groupType = targetData.group_type || targetData.groupType || null;
    this.remarks = targetData.remarks || null;
    this.nationality = targetData.nationality
      ? targetData.nationality.map((nationality) => nationality)
      : null;
    this.identification = targetData.identification
      ? targetData.identification.map((id) => new Identification(id))
      : null;
    this.isDeceased = targetData.is_deceased || targetData.isDeceased || false;

    this.contactInformation = targetData.contact_information
      ? new ContactInformation(targetData.contact_information)
      : null;
    this.associates = targetData.associates
      ? targetData.associates.map((associate) => new Associate(associate))
      : null;
    this.birthPlace =
      targetData.birth_place || targetData.birthPlace
        ? (targetData.birth_place || targetData.birthPlace).map(
            (place) => new Address(place)
          )
        : null;
    this.address = targetData.address
      ? targetData.address.map((addr) => new Address(addr))
      : null;
    this.function = targetData.function
      ? targetData.function.map((func) => func)
      : null;
    this.title = targetData.title
      ? targetData.title.map((title) => title)
      : null;
    this.birthDate =
      targetData.birth_date || targetData.birthDate
        ? (targetData.birth_date || targetData.birthDate).map((bd) => bd)
        : null;
    this.birthYear =
      targetData.birth_year || targetData.birthYear
        ? (targetData.birth_year || targetData.birthYear).map((year) => year)
        : null;
    this.age = this.calculateAgeList();
  }
  calculateAgeList() {
    const currentDate = new Date();

    if (this.birthDate && this.birthDate.length > 0) {
      return this.birthDate.map((bd) => {
        const birthDate = new Date(bd);
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        const monthDiff = currentDate.getMonth() - birthDate.getMonth();
        if (
          monthDiff < 0 ||
          (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        return age;
      });
    } else if (this.birthYear && this.birthYear.length > 0) {
      return this.birthYear.map((year) => {
        return currentDate.getFullYear() - year;
      });
    } else {
      return null; // If both are missing, return null
    }
  }
}

class ContactInformation {
    constructor(contactData = {}) {
      this.email = contactData.email && contactData.email.length > 0 ? [...contactData.email] : [];
      this.telephone = contactData.telephone && contactData.telephone.length > 0 ? [...contactData.telephone] :[];
      this.website = contactData.website && contactData.website.length > 0 ? [...contactData.website] : [];
    }
  
    static merge(contactInfoExisting = new ContactInformation(), contactInfoNew = new ContactInformation()) {
        const combinedEmails = Array.from(
            new Set([
                ...(contactInfoExisting?.email || []),
                ...(contactInfoNew?.email || [])
            ])
        );
        const combinedTelephones = Array.from(
            new Set([
                ...(contactInfoExisting?.telephone || []),
                ...(contactInfoNew?.telephone || [])
            ])
        );
        const combinedWebsites = Array.from(
            new Set([
                ...(contactInfoExisting?.website || []),
                ...(contactInfoNew?.website || [])
            ])
        );
    
        const mergedData = {
            email: combinedEmails.length > 0 ? combinedEmails : null,
            telephone: combinedTelephones.length > 0 ? combinedTelephones : null,
            website: combinedWebsites.length > 0 ? combinedWebsites : null,
        };
    
        return new ContactInformation(mergedData);
    }
  }


class Identification {
    constructor(identificationData = {}) {
      this.detail = identificationData.detail || null;
      this.id = identificationData.id || null;
      this.issuer = identificationData.issuer || null;
      this.type = identificationData.type || null;
      this.issueDate =
        identificationData.issue_date || identificationData.issueDate || null;
  
      this.completeness =
        (this.id ? 3 : 0) +
        (this.issuer ? 2 : 0) +
        (this.issueDate ? 2 : 0) +
        (this.type ? 1 : 0) +
        (this.constructor ? 1 : 0);
    }
  
   
    
    }

class Associate {
  constructor(associateData = {}) {
    this.details = associateData.details || null;
    this.type = associateData.type || null;
    this.value = associateData.value || null;
    this.completeness =
      (this.details ? 2 : 0) +
      (this.type ? 1 : 0) +
      (this.value ? 1 : 0);
  }

}

class Address {
  constructor(addressData = {}) {
    this.address = addressData.address || null;
    this.city = addressData.city || null;
    this.country = addressData.country || null;
    this.province = addressData.province || null;
    this.zipCode = addressData.zip_code || addressData.zipCode || null;
    this.postalBox = addressData.postal_box || addressData.postalBox || null;
    this.completeness =
      (this.country ? 3 : 0) +
      (this.city ? 2 : 0) +
      (this.address ? 2 : 0) +
      (this.province ? 1 : 0) +
      (this.zipCode ? 1 : 0) +
      (this.postalBox ? 1 : 0);
  }
}

class Source {
  constructor(sourceData = {}) {
    this.id = sourceData.id || null;
    this.listingId = sourceData.listing_id || sourceData.listingId || null;
    this.name = sourceData.name || null;
    this.region = sourceData.region || null;
    this.sourceType = sourceData.source_type || sourceData.sourceType || null;
  }
}

export class Result {
  constructor(result = {}, customer) {
    this.id = result.id || null;
    this.score = result.score || null;
    this.case = result.case ? result.case.map((c) => new Case(c)) : null;
    this.target = result.target ? new Target(result.target) : null;
    this.source = result.source ? new Source(result.source) : null;
    this.vessel = result.vessel ? new Vessel(result.vessel) : null;
    this.allowlisted = this.calculateIsAllowListedcustomer(customer);
    this.matchProfileId =  result.matchProfileId || String(result.match_profile_id) ;
  }

  calculateIsAllowListedcustomer(customer) {
    if (!customer) {
      return null;
    }
    const isAllowed = customer.allowlist.includes(this.id);
    return isAllowed;
  }
}

export class Check {
    constructor(check = {}, customer) {
      const getVal = (obj, keys, fallback = null) => {
        for (const key of keys) if (obj[key] !== undefined) return obj[key];
        return fallback;
      };
  
      this.birthYear = getVal(check, ["birthYear", "birth_year"]);
      this.birthDate = getVal(check, ["birthDate", "birth_date"]);
      this.age = Customer.calculateAge(this.birthYear, this.birthDate);
      this.customerId = String(getVal(check, ["customerId", "customer_id"], "")) || null;
      this.assignee = String(check.assignee ?? "") || null;
      this.progress = Number(check.progress ?? 0);
      this.riskLevel = Number(getVal(check, ["riskLevel", "risk_level"], 0));
      this.matchStatus = Number(getVal(check, ["matchStatus", "match_status"], 0));
      this.caseStatus = Number(getVal(check, ["caseStatus", "case_status"], 0));
      this.gender = Capitalize(check.gender) || null;
      this.groupType = Capitalize(getVal(check, ["groupType", "group_type"])) || null;
      this.nationality = check.nationality || null;
      this.id = check.id || null;
      this.searchTerm = getVal(check, ["searchTerm", "search_term"]);
      this.createdAt = check.createdAt ? new Date(check.createdAt)
        : check.created_at ? new Date(check.created_at) : "";
      this.updatedAt = check.updatedAt ? new Date(check.updatedAt)
        : check.updated_at ? new Date(check.updated_at) : "";
      this.results = Array.isArray(check.results)
        ? check.results.map((r) => new Result(r, customer))
        : null;
      this.matchProfiles = generateMatchProfiles(this.results);
    }
  
    updateProperties(data) {
      if (data.matchStatus !== undefined) this.matchStatus = Number(data.matchStatus);
      if (data.riskLevel !== undefined) this.riskLevel = Number(data.riskLevel);
      if (data.progress !== undefined) this.progress = Number(data.progress);
      if (data.assignee !== undefined) this.assignee = String(data.assignee);
      if (data.note !== undefined) this.note = String(data.note);
      if (data.caseStatus !== undefined) this.caseStatus = Number(data.caseStatus);
      if (data.updatedAt !== undefined) this.updatedAt = new Date(data.updatedAt);
    }
  
    resultsAmount() {
      return this.results ? this.results.length : 0;
    }
  
    sourcesAmount() {
      return this.results
        ? this.results.reduce((total, r) => total + (r.source ? 1 : 0), 0)
        : 0;
    }
  
    allowlistedAmount(customerAllowList) {
      if (!customerAllowList || !Array.isArray(customerAllowList) || !this.results) return 0;
      return this.results.reduce((count, r) => count + (customerAllowList.includes(r.id) ? 1 : 0), 0);
    }
  
    notAllowlistedAmount(customerAllowList) {
      if (!customerAllowList || !Array.isArray(customerAllowList) || !this.results) return 0;
      return this.results.reduce((count, r) => count + (customerAllowList.includes(r.id) ? 0 : 1), 0);
    }
  }
  
  function generateMatchProfiles(results) {
    try {
        if (!Array.isArray(results)) return [];
    
        const profileMap = new Map();
    
        for (const result of results) {
        const id = result.matchProfileId;
        if (!id) continue;
    
        if (!profileMap.has(id)) {
            profileMap.set(id, {
            matchProfileId: id,
            name: null,
            otherNames:new Set(),
            firstName: null,
            secondName: null,
            thirdName: null,
            gender: null,
            nationality:  new Set(),
            birthYear: new Set(),
            birthDate: new Set(),
            function: new Set(),
            title:new Set(),
            groupType: null,
            identification: [],
            contactInformation:null,
            associates: new Set(),
            address:new Set(),
            birthPlaceList:new Set(),
            isDeceased: false,
            sourcesType:new Set(),
            sourcesRegion:new Set(),
            remarks: null,
            isFirst: true,
            });
        }
    
        const profile = profileMap.get(id);
        const t = result.target;
        const s=result.source;
        if (profile.isFirst) {
            profile.groupType = t.groupType ?? null;
            profile.name = t.name ?? null;
            profile.isFirst = false;
        }
    
        if (t.firstName && profile.firstName === null) {
            profile.firstName = t.firstName;
            profile.secondName = t.secondName ?? null;
            profile.thirdName = t.thirdName ?? null;

        }
        if (t.otherNames) for (const n of t.otherNames) profile.otherNames.add(n);
        profile.sourcesType=profile.sourcesType.add(s.sourceType)
        profile.sourcesRegion=profile.sourcesRegion.add(s.region)
    
        if (profile.function === null && t.function) profile.function = t.function;
        if (profile.title === null && t.title) profile.title = t.title;
        if (profile.gender === null && t.gender) profile.gender = t.gender;
        if (t.isDeceased) {t.isDeceased = "Yes"}
        if (profile.remarks === null && t.remarks) profile.remarks = t.remarks;
        if (t.nationality) for (const n of t.nationality) profile.nationality.add(n);
        if (t.birthYear) for (const y of t.birthYear) profile.birthYear.add(y);
        if (t.birthDate) for (const bd of t.birthDate) profile.birthDate.add(bd);
        if (t.identification) {
            profile.identification = mergeList( (profile.identification), t.identification,"id" );
        }
        if (t.associates){
            profile.associates=mergeList(profile.associates,t.associates,"value")
            } 
        if (t.contactInformation) {
            profile.contactInformation = ContactInformation.merge(profile.contactInformation,t.contactInformation);
            
        }
        
        if (t.address) for (const ad of t.address) profile.address.add(ad);
        if (t.birthPlace) for (const ad of t.birthPlace) profile.birthPlaceList.add(ad);
        }
    
        return Array.from(profileMap.values()).map((p) => {
        const getMostCompleteEntity = (set) => {
            if (!set.size) return null;
            return Array.from(set).reduce((best, current) =>
            current.completeness > (best?.completeness || 0) ? current : best, null
            );
        };
    
        const mostCompleteAddress = getMostCompleteEntity(p.address);
        const mostCompleteBirthPlace=getMostCompleteEntity(p.birthPlaceList)
    
        return {
            id: p.matchProfileId,
            name: p.name,
            otherNames:Array.from(p.otherNames),
            firstName: p.firstName,
            secondName: p.secondName,
            thirdName: p.thirdName,
            gender: p.gender,
            title: Array.from(p.title),
            nationality: Array.from(p.nationality),
            birthYear: Array.from(p.birthYear),
            birthDate: Array.from(p.birthDate),
            function: Array.from(p.function),
            identification: Array.from(p.identification),
            sourcesType: Array.from(p.sourcesType),
            sourcesRegion: Array.from(p.sourcesRegion),
            contactInformation: p.contactInformation,
            associates: Array.from(p.associates),
            address: mostCompleteAddress ? [mostCompleteAddress] : null,
            birthPlace: mostCompleteBirthPlace ? [mostCompleteBirthPlace] : null,
            groupType: p.groupType,
            remarks:p.remarks,
        
        };
        });} catch (error) {
            console.log("Error in check generateMatchProfiles", error);
          }
    } 
  
  export function mapResultsAndProfiles(results) {
    const resultsMapped = results.map((r) => new Result(r));
    return { results: { resultsSources: resultsMapped, matchProfiles: generateMatchProfiles(resultsMapped) } };
  }
  

  function mergeList(existingList = [], newList = [], keyName = "id") {
    const mergedMap = new Map();
  
    // Add existing Identifications to the map
    for (const ident of existingList) {
      if (ident[keyName]) { // Use bracket notation for dynamic property access
        mergedMap.set(ident[keyName], ident);
      }
    }
  
    for (const newIdent of newList) {
      if (newIdent[keyName]) { // Use bracket notation for dynamic property access
        if (mergedMap.has(newIdent[keyName])) {
          const existingIdent = mergedMap.get(newIdent[keyName]);
          // Compare completeness
          if (newIdent.completeness > existingIdent.completeness) {
            mergedMap.set(newIdent[keyName], newIdent);
          }
          // If completeness is equal or existing is more complete, do nothing
        } else {
          // If ID doesn't exist, add the new Identification
          mergedMap.set(newIdent[keyName], newIdent);
        }
      }
    }
  
    // Convert the map back to an array
    return Array.from(mergedMap.values());
  }
  
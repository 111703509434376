import React, { useState, useEffect } from "react";
import { Button, Checkbox, Input, Tooltip, Select } from "antd";
import CountrySelector from "./CountrySelector";
import BirthYearSelector from "./BirthYearSelector";
import { customerRiskOptions } from "./Tags";
import { Customer } from '../models/Customer'; // Import the Customer model


export const FormTargetSelector = ({
  mode,
  isMobile,
  customer,
  showSourceType,
  showName,
  ContainerClassName,
  onDataChanged, // Callback to notify parent of customer changes
}) => {
  console.log("customer", customer)
  console.log("mode", mode)
  const [customerObj, setCustomerObj] = useState(() => {
    if (mode === "add") {
      const cus = new Customer({}); // Assuming you have a Customer class
      cus.groupType = "individual"; // Default to 'individual' for new customers
      return cus;
    }
    if (mode === "edit") {
      return customer || {}; // Use existing customer for editing
    }
    return {}; // Fallback in case mode is neither 'add' nor 'edit'
  });

  // Handle input changes and update customerObj
  const handleInputChange = (field, value) => {
    
    setCustomerObj((prevCustomer) => {
      const updatedCustomer = { ...prevCustomer, [field]: value };
      if (onDataChanged) {
        onDataChanged(updatedCustomer); // Notify parent if necessary
      }
      return updatedCustomer;
    });
  };
  useEffect(() => {
    if (mode === "edit" && customer) {
      setCustomerObj(customer);
    } else if (mode === "add") {
      const cus = new Customer({});
      cus.groupType = "individual"; // Reset to individual in add mode
      setCustomerObj(cus);
    }
  }, [mode, customer]);

  // Toggle between individual and entity
  const toggleIndividual = () => {
    handleInputChange("groupType", "individual");
  };

  const toggleEntity = () => {
    handleInputChange("groupType", "entity");
  };

  return (
    <div className={ContainerClassName}>
      <div style={{ display: "flex", width: "100%" }}>
        <Button
          type={customerObj.groupType?.toLowerCase() === "individual" ? "primary" : "default"}
          onClick={toggleIndividual}
          disabled={mode === "edit" && customerObj.groupType?.toLowerCase() === "entity"}
          style={{
            width: "50%",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
          }}
        >
          Individual
        </Button>
        <Button
          type={customerObj.groupType?.toLowerCase() === "entity" ? "primary" : "default"}
          onClick={toggleEntity}
          disabled={mode === "edit" && customerObj.groupType?.toLowerCase() === "individual"}
          style={{
            width: "50%",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
          }}
        >
          Entity
        </Button>
      </div>

      {customerObj.groupType?.toLowerCase() === "individual" && (
        <IndividualData
          isMobile={isMobile}
          customer={customerObj}
          handleInputChange={handleInputChange}
          showName={showName}
          showSourceType={showSourceType}
          isEditMode={mode === "edit"} // Disable fields in edit mode
        />
      )}

      {customerObj.groupType?.toLowerCase() === "entity" && (
        <EntityData
          isMobile={isMobile}
          customer={customerObj}
          handleInputChange={handleInputChange}
          showName={showName}
          isEditMode={mode === "edit"} // Disable fields in edit mode
        />
      )}
    </div>
  );
};

const IndividualData = ({
  isMobile,
  customer,
  handleInputChange,
  isEditMode,
}) => {
  const handleGenderChange = (value) => {
   let selectedGender=value[0]
    
      if (value.includes("Male") && customer.gender==="Female") {
        selectedGender = "Male";
      }
      if (value.includes("Female") && customer.gender==="Male") {
        selectedGender = "Female";
      }
      if (value.length===0) {
        selectedGender = "";
      }
  
    handleInputChange("gender", selectedGender);

  };
  return (
    <>
      <div>
        <Tooltip
          title={
            isEditMode
              ? ""
              : "Links the customer id in your system to Checklynx. Leave blank to auto-generate."
          }
        >
          <h5>Customer id</h5>
          <Input
            placeholder="Customer id in your system or leave blank"
            value={customer.id}
            onChange={(e) => handleInputChange("id", e.target.value)}
            disabled={isEditMode}
          />
        </Tooltip>
      </div>
      <div>
       
        <h5>
          <span style={{ color: "red", marginRight: "4px" }}>*</span>
          Individual name
        </h5>

        <Input
          placeholder="Complete name"
          value={customer.name}
          onChange={(e) => handleInputChange("name", e.target.value)}
        />
      </div>
      <div>
        <h5>Risk level</h5>
        <Select
          value={customer.riskLevel}
          onChange={(value) => handleInputChange("riskLevel", value)}
          options={customerRiskOptions}
          style={{ width: "100%" }}
        />
      </div>
      <div>
        <h5>Nationality</h5>
        <CountrySelector
          isMobile={isMobile}
          onChange={(value) => handleInputChange("nationality", value)}
          defaultCountries={customer.nationality}
        />
      </div>
      <div>
        <h5>Birth year</h5>
        <BirthYearSelector
          isMobile={isMobile}
          onChange={(value) => handleInputChange("birthYear", value)}
          defaultYear={customer.birthYear}
        />
      </div>
      <div className="filter-cluster">
        <h5>Gender</h5>
        <Checkbox.Group
  options={[
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ]}
  value={customer.gender ? [customer.gender] : []}  // Ensure only one is selected
  onChange={handleGenderChange}
/>
      </div>
    </>
  );
};

const EntityData = ({
  isMobile,
  customer,
  handleInputChange,
  isEditMode,
}) => {
  return (
    <>
      <div>
        <Tooltip
          title={
            isEditMode
              ? ""
              : "Links the customer id in your system to Checklynx. Leave blank to auto-generate."
          }
        >
          <h5>Customer id</h5>
          <Input
            placeholder="Customer id in your system or leave blank"
            value={customer.id}
            onChange={(e) => handleInputChange("id", e.target.value)}
            disabled={isEditMode}
          />
        </Tooltip>
      </div>
      <div>
        <h5>Entity name</h5>
        <Input
          placeholder="Entity name"
          value={customer.name}
          onChange={(e) => handleInputChange("name", e.target.value)}
        />
      </div>
      <div>
        <h5>Risk level</h5>
        <Select
          value={customer.riskLevel}
          onChange={(value) => handleInputChange("riskLevel", value)}
          options={customerRiskOptions}
          style={{ width: "100%" }}
        />
      </div>
      <div>
        <h5>Jurisdiction</h5>
        <CountrySelector
          isMobile={isMobile}
          onChange={(value) => handleInputChange("jurisdiction", value)}
          defaultCountries={customer.jurisdiction}
        />
      </div>
    </>
  );
};

import React  from 'react';
import { BrowserRouter as Router, Routes, Route ,Navigate} from 'react-router-dom';

import {Spin } from 'antd';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

import './App.css';
import  {ThemeProvider}  from './contexts/ThemeContext';

import UsersPage from './pages/UsersPage';
import KeysPage from './pages/KeysPage'
import SearchPage from './pages/SearchPage';
import AboutPage from './pages/AboutPage';
import CustomersPage from './pages/CustomersPage';
import CustomerDetailPage from './pages/CustomerDetailPage';
import CasesPage from './pages/CasesPage';

import SignUpPage from './pages/SignUpPage';
import LogInPage from './pages/LogInPage';
import FogotPasswordPage  from './pages/FogotPasswordPage';
import UserResetPasswordPage from './pages/UserResetPasswordPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import UserNewPasswordPage from './pages/UserNewPasswordPage';
import BillingMethodPage from './pages/BillingMethodPage';
import CompanySettingsPage from './pages/CompanySettingsPage';
import OnboardingSuccessPage from './pages/OnboardingSuccessPage';
import SelectIngegrationMethodPage from './pages/SelectIngegrationMethodPage';
import SettingsPage from "./pages/SettingsPage"

import { ListProvider } from './contexts/ListProviderContext';
import { UserProvider } from './contexts/UserContext';
import {CustomerProvider} from "./contexts/CustomerContext";
import {TenantProvider} from "./contexts/TenantContext";



import { useContext } from 'react';


import { AuthProvider,AuthContext } from './contexts/authContext';
import '@aws-amplify/ui-react/styles.css';

// Initialize Amplify
Amplify.configure(awsExports);

const AuthenticatedRoute = ({ children }) => {
  const { user, isLoading,idToken} = useContext(AuthContext);

  
  if (isLoading) {
    return <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column'
    }}>
      
      <Spin size="large" />

      
    </div>; 
  }
  if (!user) {
    
  
    return <Navigate to="/login" replace />;
  }
  return <>{children}</>;
};
// 
function App() {
  return (
    <AuthProvider>
       <ThemeProvider>
       <TenantProvider>
      <ListProvider>
      <UserProvider> 
        <CustomerProvider>
      <Router>
        <Routes>
          <Route path="/*" element={<AuthenticatedRoute><SearchPage /></AuthenticatedRoute>} />
          <Route path="/onboardingsuccess" element={<AuthenticatedRoute><OnboardingSuccessPage /></AuthenticatedRoute>} />

          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signup/integrationmethod" element={<SelectIngegrationMethodPage />} />
          <Route path='/login' element={<LogInPage />} />
          <Route path='/forgotpassword' element={<FogotPasswordPage />} />
          <Route path='/enforcenewpassword' element={<UserNewPasswordPage />} />
          <Route path='/passwordreset' element={<UserResetPasswordPage />} />
          <Route path='/verifyemail' element={<VerifyEmailPage />} />
          <Route path='/billingmethod' element={<BillingMethodPage />} />
          <Route path="/search/*" element={<AuthenticatedRoute><SearchPage /></AuthenticatedRoute>} />
          <Route path="/customers/*" element={<AuthenticatedRoute><CustomersPage /></AuthenticatedRoute>} />
          <Route path="/customers/:customerId/cases/" element={<AuthenticatedRoute><CustomerDetailPage /></AuthenticatedRoute>} />
          <Route path="/customers/:customerId/cases/:caseId/" element={<AuthenticatedRoute><CustomerDetailPage /></AuthenticatedRoute>} />
          <Route path="/customers/:customerId/cases/:caseId/results/:resultId" element={<AuthenticatedRoute><CustomerDetailPage /></AuthenticatedRoute>} />
          <Route path="/cases/*" element={<AuthenticatedRoute><CasesPage /></AuthenticatedRoute>} />
          <Route path="/users/*" element={<AuthenticatedRoute><UsersPage /></AuthenticatedRoute>} />
          <Route path="/developers/*" element={<AuthenticatedRoute><KeysPage /></AuthenticatedRoute>} />
          <Route path="/settings/*" element={<AuthenticatedRoute><SettingsPage /></AuthenticatedRoute>} />
          <Route path="/about/*" element={<AuthenticatedRoute><AboutPage /></AuthenticatedRoute>} />
          <Route path="/companysettings/*" element={<AuthenticatedRoute><CompanySettingsPage /></AuthenticatedRoute>} />
          
         
        </Routes>
      </Router>
      </CustomerProvider>
      </UserProvider> 
      </ListProvider>
      </TenantProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;

import { api } from "../API/API";




export class User {
    constructor(userData = {}) {
        this.id = userData.id || null;
        this.name = userData.name || "";
        this.surname = userData.surname  || "";
        this.email = userData.email || "";
        this.updatedAt = userData.updatedAt? new Date(userData.updatedAt) : new Date(userData.updated_at) || "";
        this.createdAt =userData.createdAt?  new Date(userData.createdAt) : new Date(userData.created_at) || "";

    }

    

    

}

